import React from "react";
import styled from "styled-components";
import Modal from "../../../components/Modal";
import en from "../../../translations/en.json";
import mk from "../../../translations/mk.json";
import { getApplicationLocale } from "../../../services/localizationService";

// Lang implementation
const lang = {
    en: en,
    mk: mk
};

const resolvePath = (object, path, defaultValue) =>
    path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = (key, variables = {}) => {
    let translation = resolvePath(lang[getApplicationLocale()], key, key);

    Object.keys(variables).forEach(variable => {
        translation = translation.replace(new RegExp(`{{${variable}}}`, "g"), variables[variable]);
    });

    return translation;
};

const StyledText = styled.p`
  font-size: 16px;
  color: #333;
  line-height: 1.5;
`;

const DomainRequirementsModal = ({ isOpen, onClose, domain }) => {
    const getDomainRequirements = (domain) => {
        if (!domain) {
            return {
                title: translate("cart.wizard.domain-requirements-modal.missing-domain"),
                description: translate("cart.wizard.domain-requirements-modal.domain-not-selected"),
            };
        }

        console.log("Selected domain:", domain);

        if (domain.endsWith(".com.mk")) {
            return {
                title: translate("cart.wizard.domain-requirements-modal.registration-requirements"),
                description: (
                    <div>
                        <p>
                            {translate("cart.wizard.domain-requirements-modal.com-mk.right-to-register-1")} <strong>.com.mk</strong> {translate("cart.wizard.domain-requirements-modal.right-to-register-2")}:
                        </p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.com-mk.right-to-register-3")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.com-mk.right-to-register-4")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.com-mk.right-to-register-5")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.com-mk.right-to-register-6")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.com-mk.right-to-register-7")}</li>
                        </ul>

                        <p><strong>{translate("cart.wizard.domain-requirements-modal.com-mk.right-to-register-8")}</strong></p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.com-mk.right-to-register-9")}</li>
                        </ul>
                    </div>
                ),
            };
        }
        if (domain.endsWith(".org.mk")) {
            return {
                title: translate("cart.wizard.domain-requirements-modal.org-mk.registration-requirements"),
                description: (
                    <div>
                        <p>{translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-1")} <strong>.org.mk</strong> {translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-2")}</p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-3")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-4")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-5")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-6")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-7")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-8")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.org-mk.right-to-register-9")}</li>
                        </ul>
                    </div>
                ),
            };
        }
        if (domain.endsWith(".edu.mk")) {
            return {
                title: translate("cart.wizard.domain-requirements-modal.edu-mk.registration-requirements"),
                description: (
                    <div>
                        <p>
                            {translate("cart.wizard.domain-requirements-modal.edu-mk.right-to-register-1")} <strong>.edu.mk</strong> {translate("cart.wizard.domain-requirements-modal.edu-mk.right-to-register-2")}
                        </p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.edu-mk.right-to-register-3")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.edu-mk.right-to-register-4")}</li>
                        </ul>
                    </div>
                ),
            };
        }
        if (domain.endsWith(".inf.mk")) {
            return {
                title: translate("cart.wizard.domain-requirements-modal.inf-mk.registration-requirements"),
                description: (
                    <div>
                        <p>
                            {translate("cart.wizard.domain-requirements-modal.inf-mk.right-to-register-1")} <strong>.inf.mk</strong> {translate("cart.wizard.domain-requirements-modal.inf-mk.right-to-register-2")}
                        </p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.inf-mk.right-to-register-3")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.inf-mk.right-to-register-4")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.inf-mk.right-to-register-5")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.inf-mk.right-to-register-6")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.inf-mk.right-to-register-7")}</li>
                        </ul>
                    </div>
                ),
            };
        }
        if (domain.endsWith(".net.mk")) {
            return {
                title: translate("cart.wizard.domain-requirements-modal.net-mk.registration-requirements"),
                description: (
                    <div>
                        <p>
                            {translate("cart.wizard.domain-requirements-modal.net-mk.right-to-register-1")} <strong>.net.mk</strong> {translate("cart.wizard.domain-requirements-modal.net-mk.right-to-register-2")}
                        </p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.net-mk.right-to-register-3")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.net-mk.right-to-register-4")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.net-mk.right-to-register-5")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.net-mk.right-to-register-6")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.net-mk.right-to-register-7")}</li>
                        </ul>
                    </div>
                ),
            };
        }
        if (domain.endsWith(".мкд")) {
            return {
                title: translate("cart.wizard.domain-requirements-modal.mkd.registration-requirements"),
                description: (
                    <div>
                        <p>
                            {translate("cart.wizard.domain-requirements-modal.mkd.right-to-register-1")} <strong>.мкд</strong> {translate("cart.wizard.domain-requirements-modal.mkd.right-to-register-2")}
                        </p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.mkd.right-to-register-3")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.mkd.right-to-register-4")}</li>
                        </ul>
                    </div>
                ),
            };
        }
        if (domain.endsWith(".mk")) {
            return {
                title: translate("cart.wizard.domain-requirements-modal.mk.registration-requirements"),
                description: (
                    <div>
                        <p>
                            {translate("cart.wizard.domain-requirements-modal.mk.right-to-register-1")} <strong>.mk</strong> {translate("cart.wizard.domain-requirements-modal.mk.right-to-register-2")}
                        </p>

                        <p><strong>{translate("cart.wizard.domain-requirements-modal.mk.right-to-register-3")}</strong></p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.mk.right-to-register-4")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.mk.right-to-register-5")}</li>
                        </ul>

                        <p><strong>{translate("cart.wizard.domain-requirements-modal.mk.right-to-register-6")}</strong></p>
                        <ul>
                            <li>{translate("cart.wizard.domain-requirements-modal.mk.right-to-register-7")}</li>
                            <li>{translate("cart.wizard.domain-requirements-modal.mk.right-to-register-8")}</li>
                        </ul>
                    </div>
                )
            };
        }
        return {
            title: translate("cart.wizard.domain-requirements-modal.default.right-to-register-1", { domain }),
            description: translate("cart.wizard.domain-requirements-modal.default.right-to-register-2", { domain })
        };

    };

    const { title, description } = getDomainRequirements(domain);

    return (
        <Modal isOpen={isOpen} onCloseModal={onClose} title={title} size="l" body={() => (
            <StyledText>{description}</StyledText>
        )}
        />
    );
};

export default DomainRequirementsModal;
