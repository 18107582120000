import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styled from "styled-components";
import { theme } from "../../../design/Themes";

import { getItems } from "../reducers/cartReducer";

import Container from "../../../components/Containers/Container";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

import CompactCartProductsList from "./CompactCartProducstList";
import CartTotal from "../CartTotal";
import CartCoupon from "../CartCoupon";
import CartCouponDropdown from "../CartCouponDropdown";
import { Translate } from "react-localize-redux";
import PromotionInfo from "../../../components/PromotionInfo";
import ReferralCoupon from "../ReferralCoupon";
import ReferalCouponDropdown from "../ReferalCouponDropdown/ReferalCouponDropdown";

const StyledCompactCartSummary = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  & .compact-cart-summary-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }

  & .compact-cart-summary-header h4 {
    color: ${theme.neutral5};
    font-size: 14px;
    line-height: 1.43;
    text-transform: uppercase;
  }

  & .compact-cart-summary-header span.compact-cart-summary-count {
    font-size: 12px;
    line-height: 1.33;
    background-color: ${theme.blueBase};
    color: ${theme.white};
    text-align: center;
    padding: 1px 6px;
    border-radius: 3px;
    margin-left: 5px;
  }

  & .compact-cart-summary-header span.compact-cart-summary-clear {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blueBase};
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: pointer;
  }
`;

const StyledCompactCartSummaryContainer = styled.div``;

const StyledCheckoutWrapper = styled.div`
  padding: 30px;
  padding-bottom: 0;
  // border-bottom: 1px solid ${theme.neutral3};
`;

const CompactCartSummary = ({
  title,
  children,
  onContinueCheckout,
  disableContinueCheckout,
  onClearCart,
  ...rest
}) => (
  <Translate>
    {({ translate }) => (
      <Container>
        <StyledCompactCartSummary {...rest}>
          <div className="compact-cart-summary-header">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-6">
                <h4>{translate("cart.cart-summary")}</h4>
              </div>
              <div className="col-6 col-sm-6 col-md-6 text-right">
                <span
                  className="compact-cart-summary-clear"
                  onClick={e => onClearCart()}
                >
                  {translate("cart.empty-cart")}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledCompactCartSummaryContainer>
                <CompactCartProductsList products={rest.products} />
                <CartTotal
                  customerCurrencyCode={rest.customerCurrencyCode}
                  products={rest.products}
                  totalDiscount={rest.totalDiscount}
                  total={rest.total}
                />
                <PromotionInfo></PromotionInfo>
                {/* Test add CardCouponDropdown */}
                {/* <CartCoupon
                  promotionCode={(rest.promotionCode === null || rest.promotionCode === "") ? rest.referral_code : rest.promotionCode}
                  isApplyingPromotion={rest.isApplyingPromotion}
                  applyPromotionCode={rest.applyPromotionCode}
                  clearPromotionCode={rest.clearPromotionCode}
                  isCleaningPromotion={rest.isCleaningPromotion}
                /> */}
                <CartCouponDropdown
                  promotionCode={(rest.promotionCode === null || rest.promotionCode === "") ? rest.referral_code : rest.promotionCode}
                  isApplyingPromotion={rest.isApplyingPromotion}
                  applyPromotionCode={rest.applyPromotionCode}
                  clearPromotionCode={rest.clearPromotionCode}
                  isCleaningPromotion={rest.isCleaningPromotion}
                />
                <ReferalCouponDropdown
                  referral_code={rest.referral_code}
                  isApplyingReferrer={rest.isApplyingReferrer}
                  applyReferrerCode={rest.applyReferrerCode}
                  clearReferrerCode={rest.clearReferrerCode}
                  isCleaningReferrer={rest.isCleaningReferrer}
                />
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledCheckoutWrapper>
                      <PrimaryButton
                        disabled={
                          !onContinueCheckout || disableContinueCheckout
                        }
                        onClick={onContinueCheckout}
                        className="w-100"
                      >
                        {translate("cart.continue-checkout")}
                      </PrimaryButton>
                    </StyledCheckoutWrapper>
                  </div>
                </div>
                {/* <ReferralCoupon
                    referral_code={rest.referral_code}
                    isApplyingReferrer={rest.isApplyingReferrer}
                    applyReferrerCode={rest.applyReferrerCode}
                    clearReferrerCode={rest.clearReferrerCode}
                    isCleaningReferrer={rest.isCleaningReferrer}
                /> */}
              </StyledCompactCartSummaryContainer>
            </div>
          </div>
        </StyledCompactCartSummary>
      </Container>
    )}
  </Translate>
);

CompactCartSummary.defaultProps = {
  disableContinueCheckout: false
};

const mapStateToProps = state => {
  console.log("Products in Redux:", getItems(state)); // Debugging line
  return {
    products: getItems(state)
  };
};


const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompactCartSummary);
