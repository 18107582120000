import React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Translate } from "react-localize-redux";

import InputField from "../../../../../components/InputField";
import SelectBox from "../../../../../components/SelectBox";
import BlockRadiobox from "../../../../../components/BlockRadiobox";
import NoticeInfo from "../../../../../components/Notices/NoticeInfo";

import graphicIndividualDefault from "../../../../../assets/images/graphic_individual.svg";
import graphicIndividualActive from "../../../../../assets/images/graphic_individual_active.svg";
import graphicCompanyDefault from "../../../../../assets/images/graphic_company.svg";
import graphicCompanyActive from "../../../../../assets/images/graphic_company_active.svg";

import { PAYER_DETAILS_TYPE_COMPANY } from "../PayerDetailsForm/PayerDetailsTypes.js";
import PhoneNumberInput from "../../../../../components/PhoneNumberInput/PhoneNumberInput.js";

const PayerDetailsForm = ({
  onSubmit,
  onCancel,
  validate,
  contact,
  onSubmitting,
  disabledFields,
  showPayerNotice,
  countries
}) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={contact || {}}
        render={({ handleSubmit, values }) => {
          return (
            <form id="proinvoice-payer-details-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <Field
                        component={BlockRadiobox}
                        activeIcon={graphicIndividualActive}
                        defaultIcon={graphicIndividualDefault}
                        name="contact_type"
                        value="individual"
                        type="radio"
                      >
                        {translate("pro-invoices.view.individual")}
                      </Field>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <Field
                        component={BlockRadiobox}
                        activeIcon={graphicCompanyActive}
                        defaultIcon={graphicCompanyDefault}
                        name="contact_type"
                        value="company"
                        type="radio"
                      >
                        {translate("pro-invoices.view.company")}
                      </Field>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label={translate("pro-invoices.view.first-name")}
                        name="first_name"
                        readOnly={disabledFields && !!disabledFields.first_name}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label={translate("pro-invoices.view.last-name")}
                        name="last_name"
                        readOnly={disabledFields && !!disabledFields.last_name}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      {" "}
                      <Field
                        component={InputField}
                        label={translate("pro-invoices.view.email")}
                        name="email"
                        readOnly={disabledFields && !!disabledFields.email}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      {" "}
                      <PhoneNumberInput
                        name="phone"
                        label={translate("domain.domain-contacts.phone-number")}
                        defaultCountry={"MK"}
                        translate={translate}
                      />
                    </div>
                  </div>
                  {((contact &&
                    contact.contact_type === PAYER_DETAILS_TYPE_COMPANY) ||
                    values.contact_type === PAYER_DETAILS_TYPE_COMPANY) && (
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={InputField}
                            label={translate("pro-invoices.view.company")}
                            name="company"
                          />
                        </div>

                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={InputField}
                            label={translate("pro-invoices.view.tax-number")}
                            name="tax_number"
                          />
                        </div>
                      </div>
                    )}
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {" "}
                      <Field
                        component={InputField}
                        label={translate("pro-invoices.view.address")}
                        name="address1"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      {" "}
                      <Field
                        component={InputField}
                        label={translate("pro-invoices.view.city")}
                        name="city"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label={translate("pro-invoices.view.zip-code")}
                        name="zip"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label={translate("pro-invoices.view.state-region")}
                        name="state_region"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <Field
                        component={SelectBox}
                        options={countries}
                        isSearchable={true}
                        label={translate("pro-invoices.view.country")}
                        name="country"
                      />
                    </div>
                  </div>
                </div>
                {showPayerNotice && (
                  <div className="col-12 col-sm-12 col-md-12">
                    <NoticeInfo>
                      {translate("pro-invoices.view.change-payer-notice-info")}.
                    </NoticeInfo>
                  </div>
                )}
              </div>
              <FormSpy
                subscription={{ submitting: true, invalid: true }}
                onChange={formstate => onSubmitting({ ...formstate })}
              />
            </form>
          );
        }}
      />
    )}
  </Translate>
);

PayerDetailsForm.defaultProps = {
  showPayerNotice: false
};

export default PayerDetailsForm;
