import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";
import SecondaryButton from "../Buttons/SecondaryButton";
import Icon from "../Icon";
import iconUpload from "../../assets/images/icon-upload.svg";

const FileUploadContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const FileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

const StyledFileInfo = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: ${theme.neutral5};
`;

const FileUploadButton = ({ onFileSelect, acceptFileExtensions, label, disabled }) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            onFileSelect(file);
        }
    };

    return (
        <Translate>
            {({ translate }) => (
                <FileUploadContainer >
                    <SecondaryButton disabled={disabled}>
                        <img src={iconUpload} alt="" className="mr-2" />
                        {translate(label)}
                        <FileInput type="file" accept={acceptFileExtensions} onChange={handleFileChange} disabled={disabled} />
                    </SecondaryButton>
                </FileUploadContainer>
            )}
        </Translate>
    );
};

export default FileUploadButton;