import React from "react";

import { Redirect } from "react-router-dom";
import { compile } from "path-to-regexp";

import { WEBSITE_ENDPOINT } from "../services/endpoints";

import Home from "../features/Home";
import Hosting from "../features/Hosting";
import ViewHosting from "../features/Hosting/ViewHosting";
import Servers from "../features/Servers";
import ViewServer from "../features/Servers/ViewServer";
import Billing from "../features/Billing";
import Domains from "../features/Domains";
import DomainTemplates from "../features/Domains/DomainTemplates";
//import Contacts from "../features/Contacts";
//import ViewContact from "../features/Contacts/ViewContact";
import HelpDesk from "../features/HelpDesk";
import KnowledgeBase from "../features/HelpDesk/KnowledgeBase";
import Tickets from "../features/Tickets";
import ViewKnowledgeBaseArticle from "../features/HelpDesk/ViewKnowledgeBaseArticle";
import ViewKnowledgeBaseCategory from "../features/HelpDesk/ViewKnowledgeBaseCategory";
import MyAccount from "../features/MyAccount";
import ViewEmail from "../features/MyAccount/EmailsAndNotifications/ViewEmail";
import Login from "../features/Authentication/Login";
import TwoFactorAuthentication from "../features/Authentication/TwoFactorAuthentication";
import ForgotPassword from "../features/Authentication/ForgotPassword";
import ResetPassword from "../features/Authentication/ResetPassword";
import Guideline from "../design/Guideline";
import ShopDomainsContainer from "../features/Shop/ShopDomainsContainer";
import OpenNewTicket from "../features/HelpDesk/OpenNewTicket";
import OpenTicket from "../features/HelpDesk/OpenTicket";
import Cart from "../features/Cart";
import Checkout from "../features/Checkout";
import { default as ShopHosting } from "../features/Shop/Hosting";
import { default as ShopServers } from "../features/Shop/Servers";
import ViewProInvoice from "../features/Billing/ProInvoices/ViewProInvoice";
import ViewInvoice from "../features/Billing/Invoices/ViewInvoice";
import ViewDomain from "../features/Domains/ViewDomain";
import Invitation from "../features/Invitation";
import CompletePersonalInformation from "../features/CompletePersonalInformation";
import TicketFeedback from "../features/HelpDesk/TicketFeedback";
import Referral from "../features/ReferalProgram";
import ReferralApplyCode from "../features/RefferalApplyCode";
import UpgradeHosting from "../features/Hosting/ViewHosting/UpgradeHosting";
import EmailVerification from "../features/EmailVerification";
import UpgradeHostingFromEmail from "../features/Hosting/ViewHosting/UpgradeHosting";

export const ROUTE_INDEX = "/";
// export const ROUTE_APPLY_REFERRAL = '/apply-code'
export const ROUTE_HOSTING = "/hosting";
export const ROUTE_VIEW_HOSTING = `${ROUTE_HOSTING}/:domain/:id`;
export const ROUTE_UPGRADE_HOSTING_EMAIL = `${ROUTE_HOSTING}/:domain/:id/upgrade`;
export const ROUTE_VIEW_HOSTING_GENERAL_INFO = `${ROUTE_VIEW_HOSTING}/general-info`;
export const ROUTE_VIEW_HOSTING_ADDONS = `${ROUTE_VIEW_HOSTING}/addons`;
export const ROUTE_VIEW_BACKUP_ADDONS = `${ROUTE_VIEW_HOSTING}/backup`;
// export const ROUTE_VIEW_HOSTING_CONTACTS = `${ROUTE_VIEW_HOSTING}/contacts`;
export const ROUTE_VIEW_HOSTING_UPGRADE = `${ROUTE_VIEW_HOSTING}/upgrade/:hosting_id`;
export const ROUTE_SERVERS = "/servers";
export const ROUTE_VIEW_SERVER = `${ROUTE_SERVERS}/:id`;
export const ROUTE_VIEW_SERVER_GENERAL_INFO = `${ROUTE_VIEW_SERVER}/general-info`;
export const ROUTE_VIEW_SERVER_ADDONS = `${ROUTE_VIEW_SERVER}/addons`;
// export const ROUTE_VIEW_SERVER_CONTACTS = `${ROUTE_VIEW_SERVER}/contacts`;
export const ROUTE_BILLING = "/billing";
export const ROUTE_BILLING_PROINVOICES = `${ROUTE_BILLING}/proinvoices`;
export const ROUTE_BILLING_VIEW_PROINVOICE = `${ROUTE_BILLING_PROINVOICES}/:id`;
export const ROUTE_BILLING_INVOICES = `${ROUTE_BILLING}/invoices`;
export const ROUTE_BILLING_VIEW_INVOICE = `${ROUTE_BILLING_INVOICES}/:id`;
export const ROUTE_DOMAINS = "/domains";
export const ROUTE_VIEW_DOMAIN = `${ROUTE_DOMAINS}/:name/:id`;
export const ROUTE_DOMAINS_VIEW_TEMPLATE = `${ROUTE_DOMAINS}/templates`;
export const ROUTE_VIEW_DOMAIN_GENERAL_INFO = `${ROUTE_VIEW_DOMAIN}/general-info`;
export const ROUTE_VIEW_DOMAIN_SETTINGS = `${ROUTE_VIEW_DOMAIN}/settings`;
export const ROUTE_VIEW_DOMAIN_DOMAIN_CONTACTS = `${ROUTE_VIEW_DOMAIN}/domain-contacts`;
export const ROUTE_VIEW_DOMAIN_ZONE_FILE = `${ROUTE_VIEW_DOMAIN}/zone-file`;
export const ROUTE_VIEW_DOMAIN_ADDONS = `${ROUTE_VIEW_DOMAIN}/addons`;
export const ROUTE_VIEW_DOMAIN_CONTACTS = `${ROUTE_VIEW_DOMAIN}/contacts`;
export const ROUTE_CONTACTS = "/contacts";
export const ROUTE_CONTACTS_VIEW_CONTACT = `${ROUTE_CONTACTS}/:id`;
export const ROUTE_HELPDESK = "/help-desk";
export const ROUTE_HELPDESK_KNOWLEDGE_BASE = `${ROUTE_HELPDESK}/knowledge-base`;
export const ROUTE_HELPDESK_KNOWLEDGE_BASE_CATEGORY = `${ROUTE_HELPDESK}/knowledge-base/category/:id`;
export const ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE = `${ROUTE_HELPDESK}/knowledge-base/:id`;
export const ROUTE_HELPDESK_TICKETS = `${ROUTE_HELPDESK}/tickets`;
export const ROUTE_HELPDESK_TICKET = `${ROUTE_HELPDESK}/ticket`;
export const ROUTE_HELPDESK_TICKET_VIEW = `${ROUTE_HELPDESK_TICKET}/:id`;
export const ROUTE_HELPDESK_TICKET_FEEDBACK = `${ROUTE_HELPDESK_TICKET}/:id/feedback`;
export const ROUTE_MY_ACCOUNT = "/my-account";
export const ROUTE_REFERRAL_PROGRAM = "/referral-program";
export const ROUTE_REFERRAL_PROGRAM_CODE = "/referral-program/code";
export const ROUTE_REFERRAL_PROGRAM_INVOICE = "/referral-program/invoice";
export const ROUTE_REFERRAL_PROGRAM_APPLY = "/referral-program/apply-code";
// export const ROUTE_REFERRAL_CODE_APPLY = "/referral/program";
export const ROUTE_MY_ACCOUNT_SECURITY = `${ROUTE_MY_ACCOUNT}/security`;
// export const ROUTE_MY_ACCOUNT_PRODUCTS = `${ROUTE_MY_ACCOUNT}/products`;
export const ROUTE_MY_ACCOUNT_GENERAL_INFO = `${ROUTE_MY_ACCOUNT}/general-info`;
export const ROUTE_MY_ACCOUNT_EMAILS = `${ROUTE_MY_ACCOUNT}/emails`;
export const ROUTE_MY_ACCOUNT_EMAIL_VIEW = `${ROUTE_MY_ACCOUNT}/email/:id`;
export const ROUTE_MY_ACCOUNT_APPS = `${ROUTE_MY_ACCOUNT}/apps`;
export const ROUTE_LOGIN = "/login";
export const ROUTE_TWO_FACTOR_AUTHENTICATION = "/two-factor-authentication";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_GUIDELINES = "/guidelines";
export const ROUTE_SHOP_DOMAINS = "/shop/domains";
export const ROUTE_SHOP_DOMAINS_WITH_DOMAIN = "/shop/domains/:domain";
export const ROUTE_CREATE_TICKET = "/help-desk/open-ticket";
export const ROUTE_CREATE_TICKET_WITH_PARAMS = "/help-desk/open-ticket/:id";
export const ROUTE_CART = "/cart";
export const ROUTE_CART_CHECKOUT = "/cart/checkout";
export const ROUTE_SHOP_HOSTING = "/shop/hosting";
export const ROUTE_SHOP_HOSTING_PACKAGE = "/shop/hosting/:name";


export const ROUTE_SHOP_SERVERS = "/shop/servers";
export const ROUTE_SHOP_SERVERS_PACKAGE = "/shop/servers/:name";

export const ROUTE_INVITATION = "/invitation";
export const ROUTE_UPDATE_CUSTOMER_INFORMATION = "/";
export const ROUTE_UPDATE_CUSTOMER_INFORMATION_CART_REDIRECT = "/cart";
export const ROUTE_EMAIL_VERIFY = "/verifyEmail";

export const ROUTE_WEBSITE_WHOIS = `${WEBSITE_ENDPOINT}/:language/domains/whois/:domain`;
export const ROUTE_WEBSITE = WEBSITE_ENDPOINT;

// export const ROUTE_UNAUTHENTICATED_CATCH_ALL_ROUTE = "";

export const authenticatedRoutes = [
  {
    path: ROUTE_INDEX,
    exact: true,
    component: Home
  },
  {
    path: ROUTE_HOSTING,
    exact: true,
    component: Hosting
  },
  {
    path: ROUTE_VIEW_HOSTING,
    exact: true,
    component: () => <Redirect to={ROUTE_VIEW_HOSTING_GENERAL_INFO} />
  },
  {
    path: ROUTE_VIEW_HOSTING_GENERAL_INFO,
    exact: true,
    component: ViewHosting
  },
  {
    path: ROUTE_UPGRADE_HOSTING_EMAIL,
    exact: true,
    component: UpgradeHostingFromEmail
  },
  {
    path: ROUTE_VIEW_HOSTING_ADDONS,
    exact: true,
    component: ViewHosting
  },
  {
    path: ROUTE_VIEW_BACKUP_ADDONS,
    exact: true,
    component: ViewHosting
  },
  {
    path: ROUTE_VIEW_HOSTING_UPGRADE,
    exact: true,
    component: UpgradeHosting
  },
  // {
  //   path: ROUTE_VIEW_HOSTING_CONTACTS,
  //   exact: true,
  //   component: ViewHosting
  // },
  {
    path: ROUTE_SERVERS,
    exact: true,
    component: Servers
  },
  {
    path: ROUTE_VIEW_SERVER,
    exact: true,
    component: () => <Redirect to={ROUTE_VIEW_SERVER_GENERAL_INFO} />
  },
  {
    path: ROUTE_VIEW_SERVER_GENERAL_INFO,
    exact: true,
    component: ViewServer
  },
  {
    path: ROUTE_VIEW_SERVER_ADDONS,
    exact: true,
    component: ViewServer
  },
  // {
  //   path: ROUTE_VIEW_SERVER_CONTACTS,
  //   exact: true,
  //   component: ViewServer
  // },
  {
    path: ROUTE_BILLING,
    exact: true,
    component: () => <Redirect to={ROUTE_BILLING_PROINVOICES} />
  },
  {
    path: ROUTE_BILLING_PROINVOICES,
    exact: true,
    component: Billing
  },
  {
    path: ROUTE_BILLING_VIEW_PROINVOICE,
    exact: true,
    component: ViewProInvoice
  },
  {
    path: ROUTE_BILLING_INVOICES,
    exact: true,
    component: Billing
  },
  {
    path: ROUTE_BILLING_VIEW_INVOICE,
    exact: true,
    component: ViewInvoice
  },
  {
    path: ROUTE_DOMAINS,
    exact: true,
    component: Domains
  },
  {
    path: ROUTE_DOMAINS_VIEW_TEMPLATE,
    exact: true,
    component: DomainTemplates
  },
  {
    path: ROUTE_VIEW_DOMAIN,
    exact: true,
    component: () => <Redirect to={ROUTE_VIEW_DOMAIN_GENERAL_INFO} />
  },
  {
    path: ROUTE_VIEW_DOMAIN_GENERAL_INFO,
    exact: true,
    component: ViewDomain
  },
  {
    path: ROUTE_VIEW_DOMAIN_SETTINGS,
    exact: true,
    component: ViewDomain
  },
  {
    path: ROUTE_VIEW_DOMAIN_DOMAIN_CONTACTS,
    exact: true,
    component: ViewDomain
  },
  {
    path: ROUTE_VIEW_DOMAIN_ZONE_FILE,
    exact: true,
    component: ViewDomain
  },
  {
    path: ROUTE_VIEW_DOMAIN_ADDONS,
    exact: true,
    component: ViewDomain
  },
  // {
  //   path: ROUTE_VIEW_DOMAIN_CONTACTS,
  //   exact: true,
  //   component: ViewDomain
  // },
  //{
  //  path: ROUTE_CONTACTS_VIEW_CONTACT,
  //  exact: true,
  //  component: ViewContact
  //},
  //{
  //  path: ROUTE_CONTACTS,
  //  exact: true,
  //  component: Contacts
  //},

  {
    path: ROUTE_HELPDESK,
    exact: true,
    component: HelpDesk
  },
  {
    path: ROUTE_HELPDESK_KNOWLEDGE_BASE,
    exact: true,
    component: KnowledgeBase
  },
  {
    path: ROUTE_HELPDESK_TICKETS,
    exact: true,
    component: Tickets
  },
  {
    path: ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE,
    exact: true,
    component: ViewKnowledgeBaseArticle
  },
  {
    path: ROUTE_HELPDESK_KNOWLEDGE_BASE_CATEGORY,
    exact: true,
    component: ViewKnowledgeBaseCategory
  },
  {
    path: ROUTE_MY_ACCOUNT,
    exact: true,
    component: () => <Redirect to={ROUTE_MY_ACCOUNT_GENERAL_INFO} />
  },
  {
    path: ROUTE_MY_ACCOUNT_GENERAL_INFO,
    exact: true,
    component: MyAccount
  },
  {
    path: ROUTE_MY_ACCOUNT_SECURITY,
    exact: true,
    component: MyAccount
  },
  //{
  //  path: ROUTE_MY_ACCOUNT_PRODUCTS,
  //  exact: true,
  //  component: MyAccount
  //},
  {
    path: ROUTE_MY_ACCOUNT_EMAILS,
    exact: true,
    component: MyAccount
  },
  {
    path: ROUTE_MY_ACCOUNT_EMAIL_VIEW,
    exact: true,
    component: ViewEmail
  },
  {
    path: ROUTE_MY_ACCOUNT_APPS,
    exact: true,
    component: MyAccount
  },
  {
    path: ROUTE_REFERRAL_PROGRAM,
    exact: true,
    isNew: true,
    component: () => <Redirect to={ROUTE_REFERRAL_PROGRAM_CODE} />
  },
  {
    path: ROUTE_REFERRAL_PROGRAM_CODE,
    exact: true,
    component: Referral
  },
  {
    path: ROUTE_REFERRAL_PROGRAM_INVOICE,
    exact: true,
    component: Referral
  },
  {
    path: ROUTE_REFERRAL_PROGRAM_APPLY,
    exact: true,
    component: Referral
  },
  /*

   */
  {
    path: ROUTE_GUIDELINES,
    exact: true,
    component: Guideline
  },
  {
    path: ROUTE_SHOP_DOMAINS_WITH_DOMAIN,
    exact: true,
    component: ShopDomainsContainer
  },
  {
    path: ROUTE_SHOP_DOMAINS,
    exact: true,
    component: ShopDomainsContainer
  },
  {
    path: ROUTE_CREATE_TICKET,
    exact: true,
    component: OpenNewTicket
  },
  {
    path: ROUTE_CREATE_TICKET_WITH_PARAMS,
    exact: true,
    component: OpenNewTicket
  },
  {
    path: ROUTE_HELPDESK_TICKET_VIEW,
    exact: true,
    component: OpenTicket
  },
  {
    path: ROUTE_HELPDESK_TICKET_FEEDBACK,
    exact: true,
    component: TicketFeedback
  },
  {
    path: ROUTE_CART,
    exact: true,
    component: Cart
  },
  {
    path: ROUTE_CART_CHECKOUT,
    exact: true,
    component: Checkout
  },
  {
    path: ROUTE_SHOP_HOSTING,
    exact: true,
    component: ShopHosting
  },
  {
    path: ROUTE_SHOP_HOSTING_PACKAGE,
    exact: true,
    component: ShopHosting
  },
  {
    path: ROUTE_SHOP_SERVERS,
    exact: true,
    component: ShopServers
  },
  {
    path: ROUTE_SHOP_SERVERS_PACKAGE,
    exact: true,
    component: ShopServers
  },
  {
    path: ROUTE_INVITATION,
    exact: true,
    component: Invitation
  },
  {
    path: ROUTE_EMAIL_VERIFY,
    exact: true,
    component: EmailVerification
  },
  {
    component: () => <Redirect to={ROUTE_INDEX} />
  }
];

export const guestRoutes = [
  {
    path: ROUTE_LOGIN,
    exact: true,
    component: Login
  },
  {
    path: ROUTE_FORGOT_PASSWORD,
    exact: true,
    component: ForgotPassword
  },
  {
    path: ROUTE_RESET_PASSWORD,
    exact: true,
    component: ResetPassword
  },
  {
    path: ROUTE_CART,
    exact: true,
    component: Cart
  },
  {
    path: ROUTE_CART_CHECKOUT,
    exact: true,
    component: Checkout
  },
  {
    path: ROUTE_SHOP_HOSTING,
    exact: true,
    component: ShopHosting
  },
  {
    path: ROUTE_SHOP_HOSTING_PACKAGE,
    exact: true,
    component: ShopHosting
  },
  {
    path: ROUTE_SHOP_DOMAINS,
    exact: true,
    component: ShopDomainsContainer
  },
  {
    path: ROUTE_INVITATION,
    exact: true,
    component: Invitation
  },
  {
    path: ROUTE_EMAIL_VERIFY,
    exact: true,
    component: EmailVerification
  },
  {
    path: ROUTE_REFERRAL_PROGRAM_APPLY,
    exact: true,
    component: ReferralApplyCode
  },

  {
    component: () => <Redirect to={ROUTE_LOGIN} />
  }
];

export const updatePersonalInformationRoutes = [
  {
    path: ROUTE_UPDATE_CUSTOMER_INFORMATION,
    exact: true,
    component: CompletePersonalInformation
  },
  {
    path: ROUTE_UPDATE_CUSTOMER_INFORMATION_CART_REDIRECT,
    exact: true,
    component: CompletePersonalInformation
  },
  {
    path: ROUTE_LOGIN,
    exact: true,
    component: Login
  }
];

export function compileRoute(route) {
  return compile(route);
}
