import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_RECEIVE,
  AUTH_LOGIN_FAILURE,
  AUTH_SET_USER,
  AUTH_LOGOUT,
  AUTH_FETCH_FINISH,
  AUTH_REQUEST_PHONE_NUMBER,
  AUTH_TWOFACTOR_ENABLED,
  AUTH_TWOFACTOR_SETUP_LATER
} from "../actions/authActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

import { getToken } from "../../../services/authService";

const initialState = {
  isAuthenticated: false,
  isFetching: false,
  requestPhoneNumber: false, // <- used by LoginRegister
  isPhoneNumberRequired: false, // <- used by Login
  twoFactorEnabled: false,
  isTwoFactorEnabled: false, // <- used by Login
  twoFactorPhoneNumber: null,
  token: null,
  user: {},
  error: null,
};


export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case AUTH_REQUEST_PHONE_NUMBER:
      return {
        ...state,
        isPhoneNumberRequired: true,
        requestPhoneNumber: true, // Support both components
        token: action.payload.token,
        isFetching: false,
      };

    case AUTH_TWOFACTOR_ENABLED:
      return {
        ...state,
        isTwoFactorEnabled: action.payload.enabled,
        twoFactorEnabled: action.payload.enabled, // Unified
        twoFactorPhoneNumber: action.payload.twoFactorPhoneNumber,
        isFetching: false,
      };

    case AUTH_TWOFACTOR_SETUP_LATER:
      return {
        ...state,
        isAuthenticated: true,
        requestPhoneNumber: false
      };

    case AUTH_LOGIN_RECEIVE:
      return {
        ...state,
        isAuthenticated: true,
        isFetching: false,
        token: action.payload,
        isPhoneNumberRequired: false,
        requestPhoneNumber: false,
        twoFactorEnabled: false,
        isTwoFactorEnabled: false,
      };

    case AUTH_LOGIN_FAILURE:
      return {
        isAuthenticated: false,
        isFetching: false,
        token: "",
        user: {},
        authErrors: action.payload
      };

    case AUTH_SET_USER:
      return {
        ...state,
        user: {
          ...action.payload
        }
      };

    case AUTH_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isFetching: false,
        user: {},
        token: ""
      };

    case AUTH_FETCH_FINISH:
      return {
        ...state,
        isFetching: false
      };

    case APP_STATE_COMPLETE_RESET:
      return {
        ...initialState,
        isFetching: false
      };

    default:
      return state;
  }
}

export function getErrors(state) {
  return state.auth.authErrors;
}

export function getUser(state) {
  return state.auth.user;
}

export function isAuthenticated(state) {
  if (!state.auth) {
    return false;
  }
  return state.auth.isAuthenticated && !state.auth.isFetching && !!getToken();
}

export function getAuthToken(state) {
  return state.auth.token || getToken();
}

export function getTwoFactorPhoneNumber(state) {
  return state.auth.twoFactorPhoneNumber;
}

export function isFetching(state) {
  return state.auth.isFetching;
}

export function isPhoneNumberRequired(state) {
  return state.auth.requestPhoneNumber;
}

export function isTwoFactorEnabled(state) {
  return state.auth.twoFactorEnabled;
}

