import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { BrowserRouter } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import * as Sentry from "@sentry/browser";
import Cookies from "universal-cookie";

import rootReducer from "./core/rootReducer";
import { setAuthorizationToken, getToken } from "./services/authService";
import { setApplicationLocale, getApplicationLocale, getLocaleKey } from "./services/localizationService";
import bootApplication from "./core/bootApplication";
import redirectIfSessionExpired from "./core/redirectIfSessionExpired";
import App from "./App";

import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "custom-event-polyfill";
import "./index.css";
import "./assets/customcss/ReactToastify.css";
import "rc-tooltip/assets/bootstrap.css";
import "./assets/customcss/rc-tooltip.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({ dsn: process.env.REACT_APP_MKHOST_SENTRY_DSN });
}

const siteKey = process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY || "your-fallback-site-key";

const cookies = new Cookies();
setAuthorizationToken(getToken());
const locale = cookies.get(getLocaleKey()) || getApplicationLocale();
setApplicationLocale(locale);

const composeEnhancers =
  process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

redirectIfSessionExpired(store.dispatch);
bootApplication(store.dispatch);

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider>
      <BrowserRouter>
        <GoogleReCaptchaProvider
          reCaptchaKey={siteKey}
          scriptProps={{
            async: true,
            defer: true,
            appendTo: "body",
            onloadCallback: () => console.log("reCAPTCHA script loaded"),
          }}
        >
          <App />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </LocalizeProvider>
  </Provider>,
  document.getElementById("root")
);