import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";

import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";
import { Translate } from "react-localize-redux";

const StyledCompactCartService = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding: 10px 0;
`;

const StyledProductType = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutralBase};
  margin-right: 20px;
`;

const StyledProductTitle = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  word-break: break-word;
`;

const CompactCartServiceWithoutAddons = ({ product }) => (
    <Translate>
        {({ translate }) => (
            <StyledCompactCartService>
                <div className="row">
                    <div className="col-8 col-sm-8 col-md-8">
                        <div className="row">
                            <div className="col-12">
                                <StyledProductType>{product.item.name}</StyledProductType>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 col-sm-4 col-md-4 text-right">
                        <DiscountedAmountBadge
                            amount={{
                                currency: product.item.pricing.buy.billing_cycle.derivations[0].price.currency.code,
                                amount: product.total,
                                discountedAmount: product.total < product.price ? product.price : null
                            }}
                        />
                    </div>
                </div>
            </StyledCompactCartService>
        )}
    </Translate>
);

export default CompactCartServiceWithoutAddons;
