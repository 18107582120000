import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

const StyledDropdownMenu = styled.div`
  border: 1px solid ${theme.neutral3};
  box-shadow: 0 3px 5px 0 ${theme.neutral3};
  background: ${theme.white};
  position: absolute;
  z-index: 1000;
  min-width: 200px;
  display: ${({ opened }) => (opened ? "block" : "none")}; /* Controls visibility */
`;

const DropdownMenu = ({ opened, alignMenu, className, children }) => (
  <StyledDropdownMenu
    className={`dropdown-menu dropdown-menu-${alignMenu} ${className}`}
    opened={opened}
  >
    {children}
  </StyledDropdownMenu>
);

DropdownMenu.defaultProps = {
  alignMenu: "left",
  opened: false, // Default to closed
};

export default DropdownMenu;
