import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { ROUTE_FORGOT_PASSWORD } from "../../../routes/routes.js";

import InputField from "../../../components/InputField";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import NoticeDanger from "../../../components/Notices/NoticeDanger";

const StyledInvitationLoginForm = styled.form`
  padding: ${props => (props.isMobile ? "20px" : "50px")};
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-left: ${props =>
    props.isMobile ? `1px solid ${theme.neutral3}` : "0"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: ${props => (props.isMobile ? `5px` : "0")};
  border-bottom-left-radius: ${props => (props.isMobile ? `5px` : "0")};

  & h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    margin-bottom: 30px;
  }

  & .login-button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
  }

  & .forgot-password-link {
    display: block;
    color: ${theme.blueBase};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
  }
`;

const InvitationLoginForm = ({
  title,
  onSubmit,
  validate,
  isMobile,
  submitError // Add submitError prop to display reCAPTCHA errors
}) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit, submitting, pristine, invalid }) => (
      <StyledInvitationLoginForm onSubmit={handleSubmit} isMobile={isMobile}>
        <h3>{title}</h3>
        <Field
          name="email"
          placeholder="Enter your email address"
          label="Email"
          type="text"
          component={InputField}
        />
        <Field
          name="password"
          placeholder="Enter your password"
          label="Password"
          type="password"
          component={InputField}
        />
        {submitError && <NoticeDanger>{submitError}</NoticeDanger>}
        <PrimaryButton
          type="submit"
          className="login-button"
          disabled={submitting}
        >
          Log in
        </PrimaryButton>
        <Link to={ROUTE_FORGOT_PASSWORD} className="forgot-password-link">
          Forgot your password?
        </Link>
      </StyledInvitationLoginForm>
    )}
  />
);

InvitationLoginForm.defaultProps = {
  title: "Login"
};

export default InvitationLoginForm;