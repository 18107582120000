import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../../components/Breadcrumb';
import BreadcrumbItem from '../../../components/Breadcrumb/BreadcrumbItem';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import { Translate, withLocalize } from 'react-localize-redux';
import Table from '../../../components/Table';
import TableHeader from '../../../components/Table/TableHeader';
import TableRow from '../../../components/Table/TableRow';
import TableHeading from '../../../components/Table/TableHeading';
import TableBody from '../../../components/Table/TableBody';
import TableCell from '../../../components/Table/TableCell';
import Pagination from '../../../components/Pagination';
import { getListDomainTemplates } from '../../../services/customerService';
import SecondaryButton from '../../../components/Buttons/SecondaryButton';
import { CONTACT_DETAILS_TYPE_COMPANY } from '../ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes';
import BlockRadioboxNew from '../../../components/BlockRadioboxNew';
import iconPersonActive from "../../../assets/images/icon-person-active.svg";
import iconPersonInactive from "../../../assets/images/icon-person-inactive.svg";
import iconBusinessActive from "../../../assets/images/icon-business-active.svg";
import iconBusinessInactive from "../../../assets/images/icon-business-inactive.svg";
import { theme } from '../../../design/Themes';
import SelectBox from '../../../components/SelectBox';
import { withRouter } from 'react-router-dom';
import { ROUTE_DOMAINS } from '../../../routes/routes';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { fetchCart } from '../../Cart/actions/cartActions';
import DomainContactNew from '../../Checkout/Products/Domain/DomainContactNew';


const StyledDomainsWrapper = styled.div`
  margin-bottom: 10px;
`;

const Input = styled.input`
  padding: 8px;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const DropdownMenu = styled.div`
  padding: 15px 20px;
  width: 300px;
  position: absolute;
  right: 50px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: 10;
`;

const CenteredTableCell = styled(TableCell)`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  border: 1px solid ${theme.blueBase};
  border-radius: 3px;
  cursor: pointer;
`;

const StyledMethodTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  color:${theme.blueBase};
  margin-left: 8px;
`;

const FilterContainer = styled.div`
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FilterTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
`;

const StyledLabel = styled.label`
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const FilterButton = styled.div`
  text-align: right;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const StyledSearchInput = styled(Input)`
  max-width: 300px;
`;

const AddTemplateButton = styled(SecondaryButton)`
  padding: 8px 12px;
  font-size: 14px;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  margin-top: -10px;
`;

const TabItem = styled.div`
  padding: 10px 15px;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? theme.blueBase : '#999')};
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    height: 3px;
    width: 100%;
    background: ${theme.blueBase};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const StyledBreadcrumb = styled(Breadcrumb)`
    margin-bottom: 0px;
    padding-bottom: 0px;
`;

const StatusBadge = styled.div`
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${({ bgColor }) => bgColor || "#E5E7EB"};
  color: ${({ textColor }) => textColor || "#374151"};
  text-align: center;
  min-width: 120px;
`;

const STATUS_MAP = {
    active: { label: "Активен", color: "#D4F1DC", textColor: "#15803D" }, // Green
    pending: { label: "Се чека одобрување", color: "#FDF6C2", textColor: "#A16207" }, // Yellow
    grace_period: { label: "Грејс период за обнова", color: "#FDF6C2", textColor: "#A16207" }, // Yellow
    rejected: { label: "Одбиен", color: "#FECACA", textColor: "#B91C1C" }, // Red
    canceled: { label: "Откажан", color: "#FFE4B5", textColor: "#B45309" }, // Orange
};

const DomainTemplates = (props) => {
    const [domainTemplates, setDomainTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [menuOpen, setMenuOpen] = useState(null);
    const [activeTab, setActiveTab] = useState('templates');
    const [showAddInfoModal, setShowAddInfoModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [products, setProducts] = useState([]);

    // Filter states
    const [typeFilter, setTypeFilter] = useState(null);
    const [countryFilter, setCountryFilter] = useState(null);

    console.log(domainTemplates)
    useEffect(() => {
        fetchDomainTemplates();
    }, [currentPage]);

    const fetchDomainTemplates = async () => {
        setIsLoading(true);
        try {
            const response = await getListDomainTemplates({ page: currentPage, limit: 10 });
            console.log(response)
            if (response.data && response.data.data) {
                const { templates, paging } = response.data.data;
                setDomainTemplates(templates);
                setTotalPages(paging.total_pages);
            } else {
                setDomainTemplates([]);
            }
        } catch (error) {
            console.error("Error fetching domain templates:", error);
            setDomainTemplates([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const toggleMenu = (id) => {
        setMenuOpen(menuOpen === id ? null : id);
    };

    const clearFilters = () => {
        setTypeFilter(null);
        setCountryFilter(null);
        setCurrentPage(1);
    };

    // **Filtering Logic: Apply all active filters**
    const filteredTemplates = domainTemplates.filter(template => {
        const templateName = template.template ? template.template.toLowerCase() : "";
        const registrantCountry = template.registrant && template.registrant.country ? template.registrant.country.toLowerCase() : "";

        return (
            (!searchQuery || templateName.includes(searchQuery.toLowerCase()) || registrantCountry.includes(searchQuery.toLowerCase())) &&
            (!typeFilter || template.type === typeFilter.value) &&
            (!countryFilter || registrantCountry === countryFilter.value)
        );
    });

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    return (
        <Translate>
            {({ translate }) => (
                <div className='col-12 col-sm-12 col-md-12'>
                    <StyledDomainsWrapper isMobile={isMobileOnly} className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <StyledBreadcrumb>
                                <BreadcrumbItem to="/" title={translate("breadcrumbs.home")} />
                                <BreadcrumbItem to="/domains" title={translate("breadcrumbs.domains")} />
                                <BreadcrumbItem title={translate("breadcrumbs.domains-templates")} />
                            </StyledBreadcrumb>
                            <TabsContainer>
                                <TabItem active={activeTab === 'domains'} onClick={() => { setActiveTab('domains'); props.history.push(ROUTE_DOMAINS); }}>
                                    ДОМЕНИ
                                </TabItem>
                                <TabItem active={activeTab === 'templates'} onClick={() => { setActiveTab('templates') }}>
                                    ТВОИ ТЕМПЛЕЈТИ ЗА РЕГИСТРАЦИЈА
                                </TabItem>
                            </TabsContainer>
                        </div>
                        <div className="col-xl-12 col-sm-12 col-md-12">
                            <div className='row'>
                                <div className='col-xl-3 col-sm-12 col-md-12'>
                                    <FilterContainer>
                                        <FilterTitle>ФИЛТРИ</FilterTitle>
                                        <FilterGroup>
                                            <StyledLabel>Регистрант</StyledLabel>
                                            {/* <SelectBox
                                                name="registrant"
                                                placeholder="Избери субјект"
                                                async
                                                loadOptions={() =>
                                                    Promise.resolve([
                                                        { value: "company", label: "Company" },
                                                        { value: "individual", label: "Individual" },
                                                    ])
                                                }
                                                defaultOptions
                                                isSearchable={false}
                                                input={{ onChange: setTypeFilter }}
                                            /> */}
                                        </FilterGroup>

                                        <FilterGroup>
                                            <StyledLabel>"Статус"</StyledLabel>
                                            {/* <SelectBox
                                                name="status"
                                                placeholder="Избери статус"
                                                async
                                                loadOptions={() =>
                                                    Promise.resolve([
                                                        { value: "active", label: "Active" },
                                                        { value: "inactive", label: "Inactive" },
                                                    ])
                                                }
                                                defaultOptions
                                                isSearchable={false}
                                                input={{ onChange: setCountryFilter }}
                                            /> */}
                                        </FilterGroup>

                                        <FilterButton>
                                            <SecondaryButton onClick={clearFilters}>
                                                Избриши филтри
                                            </SecondaryButton>
                                        </FilterButton>
                                    </FilterContainer>
                                </div>
                                <div className="col-xl-9 col-sm-12 col-md-12">
                                    <TableContainer>
                                        <TableHeaderContainer>
                                            <StyledSearchInput
                                                type='text'
                                                placeholder='Search by Name, Type, or Country'
                                                value={searchQuery}
                                                onChange={handleSearchChange}
                                            />
                                            <AddTemplateButton onClick={() => console.log('Add new template')}>Додади нов шаблон</AddTemplateButton>
                                        </TableHeaderContainer>
                                        <Table>
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHeading>Name</TableHeading>
                                                    <TableHeading>Субјект за регистрација</TableHeading>
                                                    <TableHeading>Валидност</TableHeading>
                                                    <TableHeading>Статус</TableHeading>
                                                    <TableHeading>Actions</TableHeading>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                {isLoading ? (
                                                    <TableRow>
                                                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                                            Loading...
                                                        </TableCell>
                                                    </TableRow>
                                                ) : filteredTemplates.length > 0 ? (
                                                    filteredTemplates.map(template => (
                                                        <TableRow key={template.id}>
                                                            <TableCell>{template.template}</TableCell>
                                                            <CenteredTableCell>
                                                                <StyledIconWrapper selected={template.type === CONTACT_DETAILS_TYPE_COMPANY}>
                                                                    <img
                                                                        src={template.type === CONTACT_DETAILS_TYPE_COMPANY ? iconBusinessActive : iconPersonActive}
                                                                        alt={template.type === CONTACT_DETAILS_TYPE_COMPANY ? "Business" : "Person"}
                                                                    />
                                                                    <StyledMethodTitle selected={template.type === CONTACT_DETAILS_TYPE_COMPANY}>
                                                                        {template.type === CONTACT_DETAILS_TYPE_COMPANY ? "Правно лице" : "Физичко лице"}
                                                                    </StyledMethodTitle>
                                                                </StyledIconWrapper>
                                                            </CenteredTableCell>
                                                            <TableCell>
                                                                {template.valid_until && template.valid_until.date ? formatDate(template.valid_until.date) : 'N/A'}
                                                            </TableCell>
                                                            <TableCell>
                                                                <StatusBadge
                                                                    bgColor={(STATUS_MAP[template.status] && STATUS_MAP[template.status].color) || "#E5E7EB"}
                                                                    textColor={(STATUS_MAP[template.status] && STATUS_MAP[template.status].textColor) || "#374151"}
                                                                >
                                                                    {(STATUS_MAP[template.status] && STATUS_MAP[template.status].label) || "Неизвестен"}
                                                                </StatusBadge>
                                                            </TableCell>
                                                            <TableCell>
                                                                <SecondaryButton onClick={() => toggleMenu(template.id)}>...</SecondaryButton>
                                                                <DropdownMenu open={menuOpen === template.id}>
                                                                    <div>Погледни темплејт</div>
                                                                    <div>Промени и креирај нов темплејт</div>
                                                                    <div>Откажи темплејт</div>
                                                                </DropdownMenu>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                                                            No templates found.
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={setCurrentPage}
                                        />
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                    </StyledDomainsWrapper>
                    {/* <DomainContactNew showAddInfoModal={showAddInfoModal} setShowAddInfoModal={setShowAddInfoModal} selectedProduct={selectedProduct} products={products} /> */}
                </div >
            )}
        </Translate >
    );
};

export default withRouter(withLocalize(DomainTemplates));