import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate, withLocalize } from "react-localize-redux";
import styled from "styled-components";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import { theme } from "../../design/Themes";
import axios from "axios";

import { CUSTOMER_PERSONAL_INFORMATION_SIX_MONTH_CHECKUP_API_ENDPOINT } from "../../services/endpoints";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import Modal from "../Modal";

import DetailsPanel from "../DetailsPanel";
import DetailsPanelItem from "../DetailsPanel/DetailsPanelItem";
import Icon from "../Icon";

import EmailVerificationLink from "../../features/EmailVerification/EmailVerificationLink";
import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import {
    getCustomerPersonalInformation,
    isUpdatingCustomerPersonalInformation,
} from "../../features/Customer/reducers/customerReducer";

import {
    updateCustomerPersonalInformation,
} from "../../features/Customer/actions/customerActions";

import {
    CONTACT_DETAILS_TYPE_COMPANY,
    CONTACT_DETAILS_TYPE_INDIVIDUAL
} from "../../features/Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";

import UpdateGeneralInfo from "../../features/MyAccount/UpdateGeneralInfo";

import {
    validateAddress,
    validateMkPhoneNumber,
    validateCity,
    validateZipCode,
    validateFirstName,
    validateLastName,
    validateCompany,
    validateTaxNumber,
    validateCountry,
    normalizeMacedonianPhoneNumber
} from "../../common/validationRules";
import { findLastIndex } from "lodash";
import { getCountriesFormatted } from "../../features/Addressing/reducers/addressingReducer";


const UnverifiedText = styled.span`
  color: #fe9b34;
`;

const ButtonsGroup = styled.div`
display:flex;
gap:5px;
justify-content:flex-end;
padding:20px`

const MediumText = styled.div`
  font-size: 14px;

  & .click-here-to-verify {
    font-weight: 600;
    color: ${theme.blueBase};
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const VerifiedEmailSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  width: 100%; 
`;

class SixMonthsPersonalInfoVerification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showUpdateGeneralInfoModal: false,
            submittingUpdateGeneralInfo: false,
            invalidUpdateGeneralInfo: false
        };
    }

    requestEmailVerification = () => {
        this.setState({
            emailVerificationReducer: true
        });
    };

    onConfirmGeneralInfo = () => {
        axios.post(CUSTOMER_PERSONAL_INFORMATION_SIX_MONTH_CHECKUP_API_ENDPOINT, {})
            .then(response => {

            })
            .catch(error => {
                displayToastMessageForResponse(
                    this.props.translate("my-account.customer"),
                    { success: false, messages: [error.message] }
                );
            });
        this.props.onClose();
    };

    onShowUpdateGeneralInfoModal = () => {
        this.setState({
            showUpdateGeneralInfoModal: true
        });
    };

    onHideUpdateGeneralInfoModal = () => {
        this.setState({
            showUpdateGeneralInfoModal: false
        });
    };

    validateUpdateGeneralInfoSubmit = values => {
        const validationResults = {
            first_name: validateFirstName(values.first_name),
            last_name: validateLastName(values.last_name),
            address1: validateAddress(values.address1, true),
            phone: validateMkPhoneNumber(values.phone, true),
            city: validateCity(values.city, true),
            zip: validateZipCode(values.zip, true),
            country: validateCountry(values.country.value)
        };

        if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
            validationResults.company = validateCompany(values.company);
            validationResults.tax_number = validateTaxNumber(values.tax_number);
        }

        return validationResults;
    };

    onSubmittingUpdateGeneralInfo = state => {
        this.setState({
            submittingUpdateGeneralInfo: state.submitting,
            invalidUpdateGeneralInfo: state.invalid
        });
    };

    onUpdateGeneralInfo = values => {
        return this.props
            .updateCustomerPersonalInformation({
                ...values,
                phone: normalizeMacedonianPhoneNumber(values.phone),
                country_id: values.country.value,
                company:
                    values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                        ? ""
                        : values.company,
                tax_number:
                    values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                        ? ""
                        : values.tax_number
            })
            .then(response => {
                displayToastMessageForResponse(
                    this.props.translate("my-account.customer"),
                    response
                );
                this.setState({
                    showUpdateGeneralInfoModal: false
                });
            });

    };

    onTriggerUpdateGeneralInfoFormSubmit = () => {
        document
            .getElementById("update-general-info-form")
            .dispatchEvent(new Event("submit", { cancelable: true }));
    };

    render() {
        const { customerPersonalInformation } = this.props;

        return (
            <Translate>
                {({ translate }) => (
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            {customerPersonalInformation && (
                                <Modal
                                    size="medium"
                                    title={translate("verify-info-title")}
                                    onCloseModal={this.props.onClose}
                                    subTitle={translate("verify-info-description")}
                                    body={() => (
                                        <DetailsPanel
                                            title={
                                                customerPersonalInformation.first_name +
                                                " " +
                                                customerPersonalInformation.last_name
                                            }
                                        >
                                            <DetailsPanelItem
                                                label={translate("my-account.general-info.details.email")}
                                            >
                                                <VerifiedEmailSection>
                                                    <div>
                                                        {!customerPersonalInformation.email_verified ? (
                                                            <Icon icon="warning" color="#fe9b34" />
                                                        ) : (
                                                            <Icon icon="success" color="#3ab27f" />
                                                        )}
                                                        {customerPersonalInformation.email}
                                                    </div>

                                                </VerifiedEmailSection>

                                                {!customerPersonalInformation.email_verified ? (
                                                    <MediumText>
                                                        <UnverifiedText>
                                                            {translate("my-account.general-info.details.unverified")}
                                                        </UnverifiedText>
                                                        <EmailVerificationLink />
                                                        {translate("my-account.general-info.details.to-verify")}
                                                    </MediumText>
                                                ) : null}
                                            </DetailsPanelItem>
                                            {customerPersonalInformation.company &&
                                                customerPersonalInformation.company.length > 0 && (
                                                    <DetailsPanelItem
                                                        label={translate("my-account.general-info.details.company")}
                                                    >
                                                        {customerPersonalInformation.company}
                                                    </DetailsPanelItem>
                                                )}
                                            {customerPersonalInformation.company &&
                                                customerPersonalInformation.company.length > 0 && (
                                                    <DetailsPanelItem
                                                        label={translate("my-account.general-info.details.tax-number")}
                                                    >
                                                        #{customerPersonalInformation.tax_number}
                                                    </DetailsPanelItem>
                                                )}
                                            <DetailsPanelItem
                                                label={translate("my-account.general-info.details.phone")}
                                            >
                                                {customerPersonalInformation.phone}
                                            </DetailsPanelItem>
                                            <DetailsPanelItem
                                                label={translate("my-account.general-info.details.address")}
                                            >
                                                <p>{customerPersonalInformation.address1}</p>
                                                <p>
                                                    {customerPersonalInformation.zip},
                                                    {customerPersonalInformation.city}
                                                </p>
                                                {customerPersonalInformation.country && (
                                                    <p>{customerPersonalInformation.country.name}</p>
                                                )}
                                            </DetailsPanelItem>
                                        </DetailsPanel>
                                    )}
                                    footer={() => [
                                        <>
                                            <SecondaryButton onClick={this.onConfirmGeneralInfo}>
                                                {translate("my-account.general-info.update-info.confirm")}
                                            </SecondaryButton>
                                            <PrimaryButton onClick={this.onShowUpdateGeneralInfoModal}>
                                                {translate("my-account.general-info.update-info.edit-profile")}
                                            </PrimaryButton>
                                        </>
                                    ]}
                                />
                            )}




                            {this.state.showUpdateGeneralInfoModal && (
                                <Modal
                                    title={translate("my-account.general-info.update-info.title")}
                                    onCloseModal={this.onHideUpdateGeneralInfoModal}
                                    body={() => (
                                        <div>
                                            <UpdateGeneralInfo
                                                contact={{
                                                    ...this.props.customerPersonalInformation,
                                                    contact_type:
                                                        this.props.customerPersonalInformation.company !== ""
                                                            ? CONTACT_DETAILS_TYPE_COMPANY
                                                            : CONTACT_DETAILS_TYPE_INDIVIDUAL,
                                                    country: this.props.customerPersonalInformation.country.id
                                                        ? {
                                                            label: this.props.customerPersonalInformation.country.name,
                                                            value: this.props.customerPersonalInformation.country.id
                                                        }
                                                        : this.props.customerPersonalInformation.country
                                                }}
                                                validate={this.validateUpdateGeneralInfoSubmit}
                                                onSubmitting={this.onSubmittingUpdateGeneralInfo}
                                                onSubmit={this.onUpdateGeneralInfo}
                                                countries={this.props.countries}
                                            />
                                        </div>
                                    )}
                                    footer={() => [
                                        <PrimaryButton
                                            key="update-general-info"
                                            disabled={
                                                this.state.submittingUpdateGeneralInfo ||
                                                this.state.invalidUpdateGeneralInfo ||
                                                this.props.isUpdatingCustomerPersonalInformation
                                            }
                                            submitting={
                                                this.state.submittingUpdateGeneralInfo ||
                                                this.props.isUpdatingCustomerPersonalInformation
                                            }
                                            onClick={this.onTriggerUpdateGeneralInfoFormSubmit}
                                        >
                                            {translate("my-account.general-info.update-info.save-changes")}
                                        </PrimaryButton>,
                                        <SecondaryButton
                                            key="cancel-update-general-info"
                                            onClick={this.onHideUpdateGeneralInfoModal}
                                        >
                                            {translate("my-account.general-info.update-info.cancel")}
                                        </SecondaryButton>
                                    ]}
                                />
                            )}
                        </div>
                    </div>
                )}
            </Translate>
        );
    }
}

const mapStateToProps = state => ({
    customerPersonalInformation: getCustomerPersonalInformation(state),
    isUpdatingCustomerPersonalInformation: isUpdatingCustomerPersonalInformation(
        state
    ),
    countries: getCountriesFormatted(state),
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateCustomerPersonalInformation,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(SixMonthsPersonalInfoVerification));
