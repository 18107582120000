export const PRODUCT_DOMAIN = "domain";
export const PRODUCT_PRODUCT = "hosting";
export const HOSTING_SERVICE = "hosting_service";
export const PRODUCT_DEDICATED_SERVER = "dedicated_server";
export const PRODUCT_CLOUD_SERVER = "cloud_server";
export const SERVER_SERVICE = "server_service";
export const DOMAIN_SERVICE = "domain_service";
export const CLOUD_SERVER_SERVICE = "cloud_server_service";
export const DEDICATED_SERVER_SERVICE = "dedicated_server_service";
export const PRODUCT_DOMAIN_SERVICE = "service";
