import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import AccountDetails from "../../components/AccountDetails";

import ContentLoader from "../../components/Loaders/ContentLoader";
import withLoading from "../../components/Loaders/WithLoading";

import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateMkPhoneNumber,
  validateTermsAndConditions,
  validateCountry,
  validateCity,
  validateAddress,
  validateZipCode,
  validateTaxNumber,
  validateCompany
} from "../../common/validationRules";

import {
  CONTACT_DETAILS_TYPE_COMPANY,
  CONTACT_DETAILS_TYPE_INDIVIDUAL
} from "../../features/Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";

import { fetchCustomerPersonalInformation } from "../Customer/actions/customerActions";

import {
  getCustomerPersonalInformation,
  isFetchingCustomerPersonalInformation
} from "../Customer/reducers/customerReducer";

import { getCountriesFormatted } from "../Addressing/reducers/addressingReducer";

import { updateRegisterDetails } from "../Authentication/actions/authActions";

import { ROUTE_CART } from "../../routes/routes";

const AccountDetailsWithLoading = withLoading(AccountDetails, ContentLoader);
const StyledAccountDetailsWrapper = styled.div`
  margin-top: 50px;
`;

const validateAccountDetails = values => {
  if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
    return {
      email: validateEmail(values.email),
      first_name: validateFirstName(values.first_name),
      last_name: validateLastName(values.last_name),
      // phone: validateMkPhoneNumber(values.phone),
      terms: validateTermsAndConditions(values.terms),
      country: validateCountry(values.country.label),
      address: validateAddress(values.address),
      city: validateCity(values.city),
      zip: validateZipCode(values.zip),
      tax_number: validateTaxNumber(values.tax_number),
      company: validateCompany(values.company)
    };
  }
  return {
    email: validateEmail(values.email),
    first_name: validateFirstName(values.first_name),
    last_name: validateLastName(values.last_name),
    // phone: validateMkPhoneNumber(values.phone),
    terms: validateTermsAndConditions(values.terms),
    country: validateCountry(values.country.label),
    address: validateAddress(values.address),
    city: validateCity(values.city),
    zip: validateZipCode(values.zip)
  };
};

class CompletePersonalInformation extends React.Component {
  onSubmit = values => {
    return this.props
      .updateRegisterDetails({
        ...values,
        country_id: values.country.value
      })
      .then(() => {
        this.props.fetchCustomerPersonalInformation();
        this.props.history.push(ROUTE_CART);
      });
  };

  render() {
    let contact = {
      ...this.props.customerPersonalInformation
    };
    let country = contact.country || false;
    if (
      country &&
      !Array.isArray(country) &&
      (typeof country === "function" || typeof country === "object")
    ) {
      country = {
        label: country.name,
        key: country.id
      };
    } else {
      country = false;
    }

    contact = {
      ...this.props.customerPersonalInformation,
      contact_type: this.props.customerPersonalInformation
        ? this.props.customerPersonalInformation.company !== ""
          ? CONTACT_DETAILS_TYPE_COMPANY
          : CONTACT_DETAILS_TYPE_INDIVIDUAL
        : "",
      country
    };

    return (
      <StyledAccountDetailsWrapper className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="container">
            <AccountDetailsWithLoading
              isLoading={this.props.isFetchingCustomerPersonalInformation}
              onSubmit={this.onSubmit}
              validate={validateAccountDetails}
              contact={contact}
              countries={this.props.countries}
            />
          </div>
        </div>
      </StyledAccountDetailsWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    customerPersonalInformation: getCustomerPersonalInformation(state),
    isFetchingCustomerPersonalInformation: isFetchingCustomerPersonalInformation(
      state
    ),
    countries: getCountriesFormatted(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { updateRegisterDetails, fetchCustomerPersonalInformation },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompletePersonalInformation));
