import React from "react";
import styled from "styled-components";

import InputField from "../../../components/InputField";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import SecondaryWarningButton from "../../../components/Buttons/SecondaryWarningButton";



import { isAuthenticated } from "../../Authentication/reducers/authReducer";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";

const StyledCartCoupon = styled.div`
  padding: 30px;
  padding-bottom: 0;
`;

class ReferralCoupon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      referral_code: props.referral_code
    };
  }

  changeReferrerCode = value => {
    this.setState({
      referral_code: value
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledCartCoupon>
            {this.props.isAuthenticated ? (
              <div className="row no-gutters">
                <div className="col-12 col-sm-12 col-md-12">
                  <InputField
                    label={translate("cart.referral-code.label")}
                    disabled={
                      this.props.referral_code !== "" ||
                      this.props.isApplyingReferrer
                    }
                    name="coupon"
                    type="text"
                    value={
                      this.props.referral_code !== ""
                        ? this.props.referral_code
                        : this.state.refererCodeInput
                    }
                    placeholder={translate("cart.referral-code.placeholder")}
                    onChange={e => this.changeReferrerCode(e.target.value)}
                  />

                </div>
                <div className="col-12 col-sm-12 col-md-12 text-right">
                  {this.props.referral_code !== "" &&
                    !this.props.isApplyingReferrer ? (
                    <SecondaryWarningButton
                      disabled={this.props.isCleaningReferrer}
                      submitting={this.props.isCleaningReferrer}
                      size="m"
                      onClick={e => this.props.clearReferrerCode()}
                      className="btn"
                    >
                      {translate("cart.referral-code.remove")}
                    </SecondaryWarningButton>
                  ) : (
                    <SecondaryButton
                      disabled={
                        this.props.referral_code !== "" ||
                        this.props.isApplyingReferrer
                      }
                      submitting={this.props.isApplyingReferrer}
                      size="m"
                      onClick={e =>
                        this.props.applyReferrerCode(this.state.referral_code)
                      }
                    >
                      {translate("cart.referral-code.apply")}
                    </SecondaryButton>
                  )}
                </div>
              </div>
            ) : (
              <div className="row no-gutters">
                <div className="col-12 col-sm-12 col-md-12">
                  {translate("cart.referral-code.promo-info")}
                </div>
              </div>
            )}
          </StyledCartCoupon>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state)
  };
};

export default connect(
  mapStateToProps
)((withLocalize(ReferralCoupon)));
