import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import {
  isMobileOnly,
} from "react-device-detect";
import { Translate } from "react-localize-redux";
import isLaptop from "../../../../utils/isLaptopScreen";

const WizardBreadcrumbsContainer = styled.div`
  display: flex;
  align-items: ${isMobileOnly ? 'center' : 'left'};
  justify-content: space-between;
  margin-bottom: ${isMobileOnly ? '10px' : '30px'};
  ${isMobileOnly ? 'flex-wrap: wrap; ' : ``}
  width: 100%; 
  gap: ${isMobileOnly ? "10px" : "20px"}
`;

const WizardStep = styled.div`
  ${isMobileOnly ? 'display: flex;' : ``}
  ${isMobileOnly ? 'align-items: center;' : ``}
  ${isMobileOnly ? 'flex-direction: column;' : ``}
  text-align: ${isMobileOnly ? 'center' : 'left'}
  flex: 1;
  gap: ${isMobileOnly ? "10px" : "20px"}
  ${isMobileOnly ? 'min-width: 100px;' : ``}

`;

const WizardStepBar = styled.div`
  height: 15px;
  width: 100%;
  background-color: ${props => (props.active ? theme.blueBase : theme.neutral2)};
  margin: 5px auto 10px auto;
  ${isMobileOnly ? 'margin-top: 5px;' : ``}
`;

const WizardStepTitle = styled.span`
  font-size: ${isMobileOnly ? "11px" : "12px"};
  font-weight: 600;
  color: ${props => (props.active ? theme.blueBase : theme.neutral5)};
  ${isMobileOnly ? 'text-align: center;' : ``}
  ${isMobileOnly ? 'min-height: 20px;' : ``}
  ${isMobileOnly ? 'align-items: center;' : ``}
  ${isMobileOnly ? 'white-space: nowrap;' : ``}
  ${isMobileOnly ? 'display: flex;' : ``}
  ${isMobileOnly ? 'justify-content: center;' : ``}
`;

const WizardBreadcrumbs = ({ steps, active }) => (
  <Translate>
    {({ translate }) => (
      <WizardBreadcrumbsContainer>
        {steps.filter(step => step.visible).map((step, index) => (
          <WizardStep key={`cart-wizard-step-${index}`}>
            <WizardStepTitle active={active === index}>
              {translate(step.title)}
            </WizardStepTitle>
            <WizardStepBar active={active === index} />
          </WizardStep>
        ))}
      </WizardBreadcrumbsContainer>
    )}
  </Translate>
);

export default WizardBreadcrumbs;
