import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import isLaptop from "../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";

import { ROUTE_FORGOT_PASSWORD } from "../../../../routes/routes.js";

import InputField from "../../../../components/InputField";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import NoticeDanger from "../../../../components/Notices/NoticeDanger";

import { LOCALE_EN, LOCALE_MK } from "../../../../types/LocaleTypes";

const StyledLoginForm = styled.form`
  padding: ${props => {
    if (props.isMobile) return "20px";
    if (props.isLaptop) return "30px";
    return "50px";
  }};
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-left: ${props =>
    props.isMobile ? `1px solid ${theme.neutral3}` : "0"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: ${props => (props.isMobile ? `5px` : "0")};
  border-bottom-left-radius: ${props => (props.isMobile ? `5px` : "0")};

  & h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    margin-bottom: 30px;
  }

  & .login-button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
  }

  & .forgot-password-link {
    display: block;
    color: ${theme.blueBase};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
  }
`;

const StyledLanguageIndicator = styled.span`
  width: 22px;
  height: 20px;
  font-size: 14px;
  color: ${theme.neutral5};
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const LoginForm = ({
  title,
  onSubmit,
  validate,
  isMobile,
  changeLocale,
  currentLocale,
  isLaptop,
}) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        validateOnBlur={true}
        render={({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          submitError,
          form,
        }) => (
          <StyledLoginForm
            onSubmit={e => {
              form.blur("email");
              form.blur("password");
              handleSubmit(e);
            }}
            isMobile={isMobile}
            isLaptop={isLaptop}
          >
            <div className="row">
              <div className="col-6 col-sm-6 col-md-6">
                <h3>{translate("security.authentication.title")}</h3>
              </div>
              <div className="col-6 col-sm-6 col-md-6 text-right">
                <StyledLanguageIndicator
                  onClick={e =>
                    changeLocale(
                      currentLocale === LOCALE_EN ? LOCALE_MK : LOCALE_EN
                    )
                  }
                >
                  {currentLocale === LOCALE_EN ? LOCALE_MK : LOCALE_EN}
                </StyledLanguageIndicator>
              </div>
            </div>
            <Field
              name="email"
              placeholder={translate(
                "security.authentication.enter-your-email-address"
              )}
              label={translate("security.authentication.email")}
              type="text"
              component={InputField}
            />
            <Field
              name="password"
              placeholder={translate(
                "security.authentication.enter-your-password"
              )}
              label={translate("security.authentication.password")}
              type="password"
              component={InputField}
            />
            {submitError && (
              <NoticeDanger className="w-100">{submitError}</NoticeDanger>
            )}
            <PrimaryButton
              type="submit"
              className="login-button"
              disabled={submitting}
              submitting={submitting}
            >
              {translate("security.authentication.login")}
            </PrimaryButton>
            <Link to={ROUTE_FORGOT_PASSWORD} className="forgot-password-link">
              {translate("security.authentication.forgot-password")}
            </Link>
          </StyledLoginForm>
        )}
      />
    )}
  </Translate>
);

export default withSizes(isLaptop)(LoginForm);