import React from "react";
import styled from "styled-components";

import {
  PRODUCT_DOMAIN,
  PRODUCT_PRODUCT,
  PRODUCT_DEDICATED_SERVER,
  PRODUCT_CLOUD_SERVER,
  SERVER_SERVICE,
  DOMAIN_SERVICE,
  HOSTING_SERVICE
} from "../../Products/ProductTypes";

import CompactCartDomainProduct from "./CompactCartDomainProduct";
import CompactCartProduct from "./CompactCartProduct";
import CompactCartProductAddon from "./CompactCartProductAddon";
import CompactCartService from "./CompactCartService";
import CompactCartServiceWithoutAddons from "./CompactCartServiceWithoutAddons";
import { PRODUCT_DOMAIN_SERVICE } from "../../../Cart/Products/ProductTypes";

const StyledCompactCartProductsList = styled.div``;

const CompactCartProductsList = ({ products }) => (
  <StyledCompactCartProductsList>
    {products
      .filter(product => product.type === PRODUCT_DOMAIN)
      .map(product => (
        <CompactCartDomainProduct
          key={`compact-domain-${product.id}`}
          product={product}
        />
      ))}
    {products
      .filter(product => product.type === PRODUCT_PRODUCT)
      .map(product => (
        <CompactCartProduct
          key={`compact-product-${product.id}`}
          product={product}
        />
      ))}

    {products
      .filter(
        product =>
          product.type === PRODUCT_DEDICATED_SERVER ||
          product.type === PRODUCT_CLOUD_SERVER
      )
      .map(product => (
        <CompactCartProduct
          key={`compact-product-${product.id}`}
          product={product}
        />
      ))}

    {products
      .filter(
        product =>
          product.type === DOMAIN_SERVICE ||
          product.type === HOSTING_SERVICE ||
          product.type === SERVER_SERVICE
      )
      .map(product => (
        <CompactCartService
          key={`compact-addon-${product.id}`}
          product={product}
        />
      ))}
    {products
      .filter(
        product =>
          product.type === PRODUCT_DOMAIN_SERVICE
      )
      .map(product => (
        <CompactCartServiceWithoutAddons
          key={`compact-addon-${product.id}`}
          product={product}
        />
      ))}
  </StyledCompactCartProductsList>
);

export default CompactCartProductsList;
