import React from "react";
import styled from "styled-components";

import InputField from "../../../components/InputField";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import SecondaryWarningButton from "../../../components/Buttons/SecondaryWarningButton";

import { isAuthenticated } from "../../Authentication/reducers/authReducer";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";
import Label from "../../../components/Label";

// Styled component for the dropdown container
const StyledCartCouponDropdown = styled.div`
  padding: 10px 30px;
  cursor: pointer;
  padding-bottom: 0;
  .dropdown-header {
    padding: 10px 0px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .dropdown-content {
    display: ${props => (props.isOpen ? "block" : "none")};
    transition: all 0.3s ease;
    cursor: pointer;
  }
`;

class CartCouponDropdown extends React.Component {
    constructor(props) {
        super(props);

        // Initialize state with promotionCode from props and isOpen as false
        this.state = {
            promotionCode: props.promotionCode,
            isOpen: false
        };
    }

    // Toggles the visibility of the dropdown content
    toggleDropdown = () => {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    };

    // Updates the promotionCode state when the input value changes
    changePromotionCode = value => {
        this.setState({
            promotionCode: value
        });
    };

    // Prevent dropdown from closing when the apply button is clicked
    handleApplyClick = event => {
        // Prevent the click event from propagating to parent elements
        event.stopPropagation();
        this.props.applyPromotionCode(this.state.promotionCode);
    };

    render() {
        return (
            <Translate>
                {({ translate }) => (
                    <StyledCartCouponDropdown isOpen={this.state.isOpen}>
                        {/* Dropdown header with toggle functionality */}
                        <div
                            className="dropdown-header"
                            onClick={this.toggleDropdown}
                        >
                            <div className="">
                                <Label>
                                    {/* Display label text and toggle indicator */}
                                    {translate("cart.discount-coupon.label-new")}
                                    {this.state.isOpen ? "▲" : "▼"}
                                </Label>
                            </div>
                        </div>

                        {/* Dropdown content, conditionally visible based on isOpen */}
                        <div className="dropdown-content">
                            {this.props.isAuthenticated ? (
                                <div className="row no-gutters">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        {/* Input field for entering the promotion code */}
                                        <InputField
                                            disabled={
                                                this.props.promotionCode !== "" ||
                                                this.props.isApplyingPromotion
                                            }
                                            name="coupon"
                                            type="text"
                                            value={
                                                this.props.promotionCode !== ""
                                                    ? this.props.promotionCode
                                                    : this.state.promotionCodeInput
                                            }
                                            placeholder={translate(
                                                "cart.discount-coupon.placeholder"
                                            )}
                                            onChange={e =>
                                                this.changePromotionCode(e.target.value)
                                            }
                                        />
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 text-right">
                                        {/* Button to either apply or remove promotion code */}
                                        {this.props.promotionCode !== "" &&
                                            !this.props.isApplyingPromotion ? (
                                            <SecondaryWarningButton
                                                disabled={this.props.isCleaningPromotion}
                                                submitting={this.props.isCleaningPromotion}
                                                size="m"
                                                onClick={e => this.props.clearPromotionCode()}
                                                className="btn"
                                            >
                                                {translate("cart.discount-coupon.remove")}
                                            </SecondaryWarningButton>
                                        ) : (
                                            <SecondaryButton
                                                disabled={
                                                    this.props.promotionCode !== "" ||
                                                    this.props.isApplyingPromotion
                                                }
                                                submitting={this.props.isApplyingPromotion}
                                                size="m"
                                                onClick={this.handleApplyClick}
                                            >
                                                {translate("cart.discount-coupon.apply")}
                                            </SecondaryButton>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="row no-gutters">
                                    {/* Message shown to unauthenticated users */}
                                    <div className="col-12 col-sm-12 col-md-12">
                                        {translate("cart.discount-coupon.promo-info")}
                                    </div>
                                </div>
                            )}
                        </div>
                    </StyledCartCouponDropdown>
                )}
            </Translate>
        );
    }
}

// Map the isAuthenticated state to component props
const mapStateToProps = state => {
    return {
        isAuthenticated: isAuthenticated(state)
    };
};

// Connect the component to the Redux store and localization system
export default connect(mapStateToProps)(withLocalize(CartCouponDropdown));