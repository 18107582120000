import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import Button from "../Buttons/Button";
import DropdownMenu from "../DropdowMenu";

const StyledButtonGroupDropdown = styled.div`
  background-color: ${theme.white};
  border-color: ${theme.neutral3};
  position: relative; /* Ensures dropdown stays inside */
  
  & > button {
    margin-right: 0;
  }
  
  & > button:disabled {
    background-color: ${theme.white};
    border-color: ${theme.neutral3};
    color: ${theme.neutral4};
  }
  
  & > .btn.dropdown-toggle {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  & > .btn.dropdown-toggle:after {
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-bottom: 0;
    border-left: 6px solid transparent;
    vertical-align: middle;
  }
`;

const ButtonGroupDropdown = ({ text, children, alignMenu, className, size, disabled }) => {
  const [opened, setOpened] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent event bubbling issues
    setOpened((prevOpened) => !prevOpened);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOpened(false);
      }
    };

    if (opened) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [opened]);

  return (
    <StyledButtonGroupDropdown ref={dropdownRef} className={`btn-group ${className}`}>
      <Button className="btn dropdown-toggle" size={size} onClick={toggleDropdown} disabled={disabled}>
        {text}
      </Button>
      <DropdownMenu opened={opened} alignMenu={alignMenu}>
        {children}
      </DropdownMenu>
    </StyledButtonGroupDropdown>
  );
};

export default ButtonGroupDropdown;
