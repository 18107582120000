import React from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import Cookies from "universal-cookie";
import moment from "moment";

import DomainSearchWidget from "../../widgets/DomainSearchWidget";
import HostingPlansInfoWidget from "../../widgets/HostingPlansInfoWidget";
import ProInvoicesWidget from "../../widgets/ProInvoicesWidget";
import DomainsWidget from "../../widgets/DomainsWidget";
import TicketsWidget from "../../widgets/TicketsWidget";
import FeedbackWidget from "../../widgets/FeedbackWidget";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";
import { getCustomerPersonalInformation } from "../Customer/reducers/customerReducer";

import {
  TICKET_STATUS_OPEN,
  TICKET_STATUS_ANSWERED,
  TICKET_STATUS_CUSTOMER_REPLY,
  TICKET_STATUS_IN_PROGRESS,
  TICKET_STATUS_ON_HOLD
} from "../HelpDesk/TicketStatuses";

import {
  compileRoute,
  ROUTE_SHOP_DOMAINS_WITH_DOMAIN,
  ROUTE_SHOP_HOSTING,
  ROUTE_BILLING_PROINVOICES,
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_DOMAINS,
  ROUTE_VIEW_DOMAIN_GENERAL_INFO,
  ROUTE_HELPDESK
} from "../../routes/routes";

import { fetchProInvoicesForWidget } from "../Billing/ProInvoices/actions/proInvoicesActions";
import {
  isFetchingProInvoicesForWidget,
  getProInvoicesForWidget
} from "../Billing/ProInvoices/reducers/proInvoicesReducer";

import { fetchDomainsForWidget } from "../Domains/actions/domainsActions";
import {
  getDomainsForWidget,
  isFetchingDomainsForWidget
} from "../Domains/reducers/domainsReducer";

import {
  DOMAIN_STATUS_ACTIVE,
  DOMAIN_STATUS_GRACE_PERIOD,
  DOMAIN_STATUS_REDEMPTION
} from "../Domains/DomainStatuses/DomainStatuses";

import { fetchTickets, closeTicket } from "../HelpDesk/actions/helpdeskActions";
import {
  getTickets,
  isFetchingTickets
} from "../HelpDesk/reducers/helpdeskReducer";

import { isAuthenticated } from "../Authentication/reducers/authReducer";

import {
  SORT_ORDER_DESCENDING,
  SORT_ORDER_ASCENDING
} from "../../types/SortTypes";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";
import SixMonthsPersonalInfoVerification from "../../components/SixMonthsPersonalInfoVerification";


const FEEDBACK_WIDGET_COOKIE = "mkhost-feedback-widget";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domainSearched: "",
      showFeedbackWidget: false,
      closingTicket: false,
      closingTicketId: 0,
      showPersonalInfoPopup: false
    };

    this.domainSearchRedirectRoute = compileRoute(
      ROUTE_SHOP_DOMAINS_WITH_DOMAIN
    );

    this.shopHostingRoute = compileRoute(ROUTE_SHOP_HOSTING);
    this.proInvoicesRoute = compileRoute(ROUTE_BILLING_PROINVOICES);
    this.viewProinvoiceRoute = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE);

    this.domainsRoute = compileRoute(ROUTE_DOMAINS);
    this.viewDomainRoute = compileRoute(ROUTE_VIEW_DOMAIN_GENERAL_INFO);

    this.ticketsRoute = compileRoute(ROUTE_HELPDESK);
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      if (this.props.proInvoices.length === 0)
        this.props.fetchProInvoicesForWidget(
          5,
          {
            sort_by: "due_date",
            order_by: SORT_ORDER_ASCENDING
          },
          {
            status: "Unpaid"
          }
        );

      if (this.props.domains.length === 0)
        this.props.fetchDomainsForWidget(
          5,
          {},
          {
            expire_on_range:
              "1900-01-01." +
              moment()
                .add(60, "days")
                .format("YYYY-MM-DD"),
            status: [
              DOMAIN_STATUS_ACTIVE,
              DOMAIN_STATUS_GRACE_PERIOD,
              DOMAIN_STATUS_REDEMPTION
            ]
          }
        );

      this.props.fetchTickets(
        5,
        1,
        {
          sort_by: "last_reply",
          order_by: SORT_ORDER_DESCENDING
        },

        {
          status: [
            TICKET_STATUS_OPEN,
            TICKET_STATUS_ANSWERED,
            TICKET_STATUS_CUSTOMER_REPLY,
            TICKET_STATUS_IN_PROGRESS,
            TICKET_STATUS_ON_HOLD
          ]
        }
      );

      const verifiedAt = this.props.customerPersonalInformation.verified_at
        ? moment(this.props.customerPersonalInformation.verified_at.date)
        : null;

      const oneHourAgo = moment().subtract(6, 'months');

      if (!verifiedAt || verifiedAt.isBefore(oneHourAgo)) {
        this.setState({
          showPersonalInfoPopup: true
        });
      }


      const cookies = new Cookies();

      const showFeebackWidget = cookies.get(FEEDBACK_WIDGET_COOKIE);
      if (!showFeebackWidget) {
        cookies.set(FEEDBACK_WIDGET_COOKIE, "show");
      }
      if (showFeebackWidget === "show") {
        this.setState({ showFeedbackWidget: true });
      }
    }
  }

  onHideUpdateGeneralInfoModal = () => {
    this.setState({
      showUpdateGeneralInfoModal: false
    });
  };

  closePersonalInfoPopup = () => {
    this.setState({
      showPersonalInfoPopup: false
    });
  };

  redirectDomainSearchOnChange = value => {
    this.setState({
      domainSearched: value
    });
  };

  redirectDomainSearch = values => {
    if (values.domainSearched !== "")
      this.props.history.push(
        this.domainSearchRedirectRoute({ domain: values.domainSearched })
      );
  };

  onRemoveFeedbackWidget = () => {
    const cookies = new Cookies();
    cookies.set(FEEDBACK_WIDGET_COOKIE, "hide");
    this.setState({ showFeedbackWidget: false });
  };

  onCloseTicket = ticket => {
    this.setState({
      closingTicket: true,
      closingTicketId: ticket.id
    });
    this.props.closeTicket(ticket.id).then(({ error, messages, ticket }) => {
      displayToastMessageForResponse("Ticket", { error, messages });
      this.setState({
        closingTicket: false,
        closingTicketId: 0
      });
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {this.state.showPersonalInfoPopup && (

                <SixMonthsPersonalInfoVerification onClose={this.closePersonalInfoPopup} />

              )}
              <Breadcrumb separator={true}>
                <BreadcrumbItem title={translate("breadcrumbs.home")} />
              </Breadcrumb>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-7">
                  <DomainSearchWidget
                    proinvoices={[1]}
                    onDomainSearchInputChange={
                      this.redirectDomainSearchOnChange
                    }
                    onDomainSearched={this.redirectDomainSearch}
                    domain={this.state.domainSearched}
                  />
                  <ProInvoicesWidget
                    proinvoices={this.props.proInvoices}
                    proInvoicesRoute={this.proInvoicesRoute}
                    isFetchingProInvoices={this.props.isFetchingProInvoices}
                    viewProinvoiceRoute={this.viewProinvoiceRoute}
                  />
                  <DomainsWidget
                    domains={this.props.domains}
                    domainsRoute={this.domainsRoute}
                    isFetchingDomains={this.props.isFetchingDomains}
                    viewDomainRoute={this.viewDomainRoute}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-5">
                  {this.state.showFeedbackWidget && (
                    <FeedbackWidget onRemove={this.onRemoveFeedbackWidget} />
                  )}
                  <HostingPlansInfoWidget
                    shopHostingRoute={this.shopHostingRoute}
                  />
                  <TicketsWidget
                    tickets={this.props.tickets}
                    ticketsRoute={this.ticketsRoute}
                    isFetchingTickets={this.props.isFetchingTickets}
                    onCloseTicket={this.onCloseTicket}
                    closingTicket={this.state.closingTicket}
                    closingTicketId={this.state.closingTicketId}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    proInvoices: getProInvoicesForWidget(state),
    isFetchingProInvoices: isFetchingProInvoicesForWidget(state),
    domains: getDomainsForWidget(state),
    isFetchingDomains: isFetchingDomainsForWidget(state),
    tickets: getTickets(state),
    isFetchingTickets: isFetchingTickets(state),
    isAuthenticated: isAuthenticated(state),
    customerPersonalInformation: getCustomerPersonalInformation(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProInvoicesForWidget,
      fetchDomainsForWidget,
      fetchTickets,
      closeTicket
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(Home));
