import React, { useEffect } from "react";
import styled from "styled-components";
import Modal from "../../../components/Modal";
import InputField from "../../../components/InputField";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Checkbox from "../../../components/Checkbox";
import { CONTACT_DETAILS_TYPE_COMPANY, CONTACT_DETAILS_TYPE_INDIVIDUAL } from "../../Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";
import { withRouter } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import en from "../../../translations/en.json";
import mk from "../../../translations/mk.json";
import { getApplicationLocale } from "../../../services/localizationService";

// Lang implementation
const lang = {
  en: en,
  mk: mk
};

const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = key =>
  resolvePath(lang[getApplicationLocale()], key, undefined);

const StyledPreviewContainer = styled.div`
  background: #fff;
  border-radius: 8px;
`;

const Section = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 0px;
`;

const SectionBox = styled.div`
  border: 1px solid #ccc;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
  gap: 10px;
`;

const FieldLabel = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #555;
  display: inline-block;
`;

const FieldValue = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #333;
`;

const StyledFooterPreview = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end; 
  gap: 15px;
`;


const CheckboxInlineBox = styled.div`
  margin-top: 30px;
  padding: 10px;
  cursor: pointer;
  .custom-checkbox {
    width: 100% !important;
  }
  .input-help-block {
    position: relative !important;
    top: 0;
  }
`;

const DataPreviewModal = ({
  isOpen,
  onClose,
  formData,
  templateName,
  setTemplateName,
  isSaveButtonEnabled,
  handleCheckboxChangeAcceptTerms,
  isCheckboxChecked,
  handleSaveTemplate,
  isSaving,
  domain
}) => {
  // console.log(formData)
  useEffect(() => {
    if (isOpen) {
      setTemplateName(formData.option1.company || formData.option1.first_name + " " + formData.option1.last_name);
    }
  }, [isOpen, formData]);

  const getContactTypeLabel = (contactType) => {
    if (contactType === CONTACT_DETAILS_TYPE_COMPANY) return translate("cart.wizard.data-preview-modal.company-label");
    if (contactType === CONTACT_DETAILS_TYPE_INDIVIDUAL) return translate("cart.wizard.data-preview-modal.individual-label");
    return "";
  };
  return (
    <Translate>
      {({ translate }) => (
        <Modal
          isOpen={isOpen}
          onCloseModal={onClose}
          title={translate("cart.wizard.data-preview-modal.data-for-domain-register") + `: ${domain}`}
          size="xl"
          body={() => (
            <StyledPreviewContainer>
              <Section>
                <InputField
                  label={translate("cart.wizard.data-preview-modal.template-name")}
                  name="template_name"
                  required
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </Section>

              <Section>
                <FieldRow>
                  <FieldLabel>
                    {translate("cart.wizard.data-preview-modal.entity-type")}:
                  </FieldLabel>
                  <FieldValue>
                    {getContactTypeLabel(formData.option1.contact_type)}
                  </FieldValue>
                </FieldRow>
                <FieldRow>
                  <FieldLabel>
                    {translate("cart.wizard.data-preview-modal.entity-name")}:
                  </FieldLabel>
                  <FieldValue>
                    {formData.option1.company || formData.option1.first_name + " " + formData.option1.last_name}
                  </FieldValue>
                </FieldRow>
                {formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                  <FieldRow>
                    <FieldLabel>{translate("cart.wizard.data-preview-modal.sender-name")}:</FieldLabel>
                    <FieldValue>{formData.option1.first_name + " " + formData.option1.last_name}</FieldValue>
                  </FieldRow>
                )}
                <FieldRow>
                  <FieldLabel>
                    {translate("cart.wizard.data-preview-modal.address")}:
                  </FieldLabel>
                  <FieldValue>
                    {formData.option1.address}, {formData.option1.city}
                  </FieldValue>
                </FieldRow>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.postal-code")}:</FieldLabel> <FieldValue>{formData.option1.zip}</FieldValue></FieldRow>
                {formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                  <FieldRow>
                    <FieldLabel>{translate("cart.wizard.data-preview-modal.tax-number")}:</FieldLabel>
                    <FieldValue>{formData.option1.tax_number}</FieldValue>
                  </FieldRow>
                )}
                <FieldRow>
                  <FieldLabel>
                    {translate("cart.wizard.data-preview-modal.contact-phone")}:
                  </FieldLabel>
                  <FieldValue>
                    {formData.option1.phone}
                  </FieldValue>
                </FieldRow>
                <FieldRow>
                  <FieldLabel>
                    {translate("cart.wizard.data-preview-modal.country")}:
                  </FieldLabel>
                  <FieldValue>
                    {formData.option1.country.label}
                  </FieldValue>
                </FieldRow>
              </Section>

              <Section>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.administrative-contact")}:</FieldLabel> <FieldValue>{formData.option2.administrative_contact.name} {formData.option2.administrative_contact.surname}</FieldValue></FieldRow>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.phone-number")}:</FieldLabel> <FieldValue>{formData.option2.administrative_contact.phone}</FieldValue></FieldRow>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.email")}:</FieldLabel> <FieldValue>{formData.option2.administrative_contact.email}</FieldValue></FieldRow>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.country")}:</FieldLabel> <FieldValue>{formData.option2.administrative_contact.country.label}</FieldValue></FieldRow>
              </Section>

              <Section>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.technical-contact")}:</FieldLabel> <FieldValue>{formData.option2.technical_contact.name} {formData.option2.technical_contact.surname}</FieldValue></FieldRow>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.phone-number")}:</FieldLabel> <FieldValue>{formData.option2.technical_contact.phone}</FieldValue></FieldRow>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.email")}:</FieldLabel> <FieldValue>{formData.option2.technical_contact.email}</FieldValue></FieldRow>
                <FieldRow><FieldLabel>{translate("cart.wizard.data-preview-modal.country")}:</FieldLabel> <FieldValue>{formData.option2.technical_contact.country.label}</FieldValue></FieldRow>
              </Section>

              <Section>
                <FieldRow>
                  <FieldLabel>{translate("cart.wizard.data-preview-modal.dns-servers")}:</FieldLabel>
                </FieldRow>
                {formData.option3.dns_servers.map((server, index) => (
                  <React.Fragment key={index}>
                    {server.name && (
                      <FieldRow>
                        <FieldLabel>{index + 1}. {translate("cart.wizard.data-preview-modal.ns-name-of-host")} {index + 1}:</FieldLabel>
                        <FieldValue>{server.name}</FieldValue>
                        {server.address && (
                          <FieldRow>
                            <FieldLabel> {translate("cart.wizard.data-preview-modal.address-label")} {index + 1}:</FieldLabel>
                            <FieldValue>{server.address}</FieldValue>
                          </FieldRow>
                        )}
                      </FieldRow>
                    )}

                  </React.Fragment>
                ))}
              </Section>


              <SectionBox>
                <CheckboxInlineBox onClick={handleCheckboxChangeAcceptTerms}>
                  <Checkbox
                    checked={isCheckboxChecked}
                    input={{ onChange: handleCheckboxChangeAcceptTerms, value: isCheckboxChecked }}
                    label={translate("cart.wizard.data-preview-modal.accept-data-true-valid")}
                  />
                </CheckboxInlineBox>

              </SectionBox>

              <StyledFooterPreview>
                <SecondaryButton onClick={onClose}>{translate("cart.wizard.data-preview-modal.change")}</SecondaryButton>
                <PrimaryButton disabled={!isSaveButtonEnabled || isSaving} onClick={handleSaveTemplate} submitting={isSaving}>
                  {isSaving ? translate("cart.wizard.data-preview-modal.saving") : translate("cart.wizard.data-preview-modal.save-template")}
                </PrimaryButton>
              </StyledFooterPreview>
            </StyledPreviewContainer>
          )}

        />
      )
      }
    </Translate >

  );
};

export default withRouter(withLocalize(DataPreviewModal));
