import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Form, Field } from "react-final-form";
import { isMobileOnly } from "react-device-detect";
import { Translate } from 'react-localize-redux';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"; // Add this import
import axios from "axios";
import {
  UPDATE_PHONE_2FA_ENDPOINT,
  VERIFY_PHONE_2FA_ENPOINT,
  AUTHORIZE_2FA_ENDPOINT,
  RESEND_CODE_2FA_ENPOINT,
  RESEND_CODE_2FA_ENPOINT_LOGIN
} from "../../../../services/endpoints";
import { toastDanger, toastSuccess } from "../../../ToastMessage";
import InputField from "../../../../components/InputField";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import NoticeDanger from "../../../../components/Notices/NoticeDanger";
import { validateMkPhoneNumber, validatePhoneNumber } from "../../../../common/validationRules";
import { getToken } from "../../../../services/authService";
import { getAuthToken } from "../../reducers/authReducer";

const StyledTwoFactorAuthenticationForm = styled.form`
  padding: 50px;
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-left: ${props => !props.isMobile && "0"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  & h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    margin-bottom: 30px;
  }

  & .action-button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
  }

  & .alternative-link {
    display: block;
    color: ${theme.blueBase};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
    margin-top: 20px;
    // border: 0;
    // background-color: transparent;
    // text-decoration: underline;
  }
`;

const TwoFactorAuthenticationForm = ({
  authToken,
  isTwoFAEnabled,
  onAuthSuccess,
  onSetupLater,
  twoFactorPhoneNumber
}) => {
  // const { executeRecaptcha } = useGoogleReCaptcha(); // Add the hook
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const sendCode = async values => {
    // if (!executeRecaptcha) {
    //   setSubmitError("reCAPTCHA is unavailable. Please enable third-party cookies or try again later.");
    //   return;
    // }

    // // Generate reCAPTCHA token
    // let recaptchaToken;
    // try {
    //   recaptchaToken = await executeRecaptcha("update_phone_2fa");
    // } catch (error) {
    //   console.error("Error generating reCAPTCHA token:", error);
    //   setSubmitError("reCAPTCHA verification failed. Please try again.");
    //   return;
    // }

    try {
      const response = await axios.post(UPDATE_PHONE_2FA_ENDPOINT, {
        phone: values.phoneNumber,
      });
      if (!response.data.error) {
        setIsCodeSent(true);
      }
    } catch (error) {
      console.error("Error updating phone number:", error);
      setSubmitError(
        error.message || "An error occurred while sending the code."
      );
    }
  };

  const combinedPhoneNumberValidation = (phoneNumber) => {
    const mkPhoneNumberPattern = /^(070|078|072|076|071|077|079|075|\+389|389)/;
    if (mkPhoneNumberPattern.test(phoneNumber)) {
      return validateMkPhoneNumber(phoneNumber);
    } else {
      return validatePhoneNumber(phoneNumber);
    }
  };

  const handleResendCode = async (event) => {
    event.preventDefault();

    if (authToken !== null) {
      const result = await resendCode(authToken);
    } else {
      const response = getToken();
      const result = await resendCode(response);
    }
  };

  const resendCode = async (token) => {
    if (!isTwoFAEnabled) {
      try {
        const response = await axios.get(RESEND_CODE_2FA_ENPOINT, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data.error === true) {
          toastDanger(<Translate id="resend-code-error" />);
        } else {
          toastSuccess(<Translate id="resend-code-success" />);
        }

        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      try {
        const response = await axios.get(RESEND_CODE_2FA_ENPOINT_LOGIN, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (response.data.error === true) {
          toastDanger(<Translate id="resend-code-error" />);
        } else {
          toastSuccess(<Translate id="resend-code-success" />);
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  };

  const verifyCode = async values => {
    try {
      const response = await axios.post(VERIFY_PHONE_2FA_ENPOINT, {
        code: values.verificationCode
      });

      if (!response.data.error) {
        window.location.href = "/";
      } else {
        toastDanger(<Translate id="error-two-factor-code" />);
      }
    } catch (error) {
      console.error("Error verifying phone number:", error);
      setSubmitError(error.message || "An error occurred during verification.");
    }
  };

  const authoriseCode = async (verificationCode, authToken) => {
    return await axios
      .post(
        AUTHORIZE_2FA_ENDPOINT,
        {
          code: verificationCode
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      )
      .then(result => {
        if (!result.data.error && !!result.data.data.token) {
          typeof onAuthSuccess === "function" &&
            onAuthSuccess(result.data.data.token);
        } else {
          toastDanger(<Translate id="error-two-factor-code" />);
          setSubmitError("Invalid code");
        }
      });
  };

  const getSubmitHandler = values => {
    if (isTwoFAEnabled) {
      return authoriseCode(values.verificationCode, authToken);
    } else if (isCodeSent) {
      return verifyCode(values);
    } else {
      return sendCode(values);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    window.location.href = 'mailto:support@mkhost.com.mk';
  };

  const maskPhoneNumber = (phoneNumber) => {
    if (phoneNumber && phoneNumber.length > 6) {
      return `${phoneNumber.slice(0, 4)}${'*'.repeat(phoneNumber.length - 6)}${phoneNumber.slice(-2)}`;
    }
    return phoneNumber;
  };

  return (
    <Translate>
      {({ translate }) => (
        <Form
          onSubmit={getSubmitHandler}
          render={({ handleSubmit, submitting, values }) => (
            <StyledTwoFactorAuthenticationForm
              onSubmit={handleSubmit}
              isMobile={isMobileOnly}
            >
              <h3>
                {isCodeSent || isTwoFAEnabled
                  ? translate("twoFactorAuthentication.verifyTitle")
                  : translate("twoFactorAuthentication.title")}
              </h3>
              <p>
                {translate(
                  isCodeSent || isTwoFAEnabled
                    ? "twoFactorAuthentication.descriptionPhone"
                    : "twoFactorAuthentication.description",
                  twoFactorPhoneNumber ? { phone: twoFactorPhoneNumber } : { phone: maskPhoneNumber(values.phoneNumber) }
                )}
              </p>
              {!isCodeSent && !isTwoFAEnabled ? (
                <>
                  <Field
                    name="phoneNumber"
                    component={InputField}
                    placeholder={translate(
                      "twoFactorAuthentication.phoneNumberPlaceholder"
                    )}
                    required
                    autoComplete="tel"
                    type="tel"
                    validate={(value) => combinedPhoneNumberValidation(value)}
                    label={translate("twoFactorAuthentication.phoneNumber")}
                  />
                  {/* Removed GoogleReCaptcha component */}

                  {submitError && <NoticeDanger>{submitError}</NoticeDanger>}

                  <PrimaryButton
                    type="submit"
                    className="action-button"
                    disabled={submitting}
                    submitting={submitting}
                  >
                    {translate("twoFactorAuthentication.sendCode")}
                  </PrimaryButton>
                  <a
                    href="/"
                    onClick={() =>
                      typeof onSetupLater === "function" && onSetupLater()
                    }
                    className="alternative-link"
                  >
                    {translate("twoFactorAuthentication.setupLater")}
                  </a>
                </>
              ) : (
                <>
                  <Field
                    name="verificationCode"
                    component={InputField}
                    placeholder={translate(
                      "twoFactorAuthentication.verificationCodePlaceholder"
                    )}
                    label={translate(
                      "twoFactorAuthentication.verificationCode"
                    )}
                  />
                  {submitError && <NoticeDanger>{submitError}</NoticeDanger>}

                  <PrimaryButton
                    type="submit"
                    className="action-button"
                    disabled={submitting}
                    submitting={submitting}
                  >
                    {translate("twoFactorAuthentication.verifyButton")}
                  </PrimaryButton>
                  <a href="#" onClick={handleResendCode} className="alternative-link">
                    {translate('twoFactorAuthentication.resendCode')}
                  </a>

                  <a href="#" onClick={handleClick} className="alternative-link">
                    {translate("twoFactorAuthentication.contactSupport")}
                  </a>
                </>
              )}
            </StyledTwoFactorAuthenticationForm>
          )}
        />
      )}
    </Translate>
  );
};

export default TwoFactorAuthenticationForm;