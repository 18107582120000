import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { withLocalize, Translate } from "react-localize-redux";
import { isMobileOnly, isTablet } from "react-device-detect";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DropdownMenu from "../../DropdowMenu";
import DropdownItem from "../../DropdownItem";

import Icon from "../../../components/Icon";

import NoNotifications from "../../Notifications/NoNotifications";

import Notification from "../../../components/Notifications/Notification";
import NotificationSuccess from "../../../components/Notifications/NotificationSuccess";
import NotificationWarning from "../../../components/Notifications/NotificationWarning";
import NotificationDanger from "../../../components/Notifications/NotificationDanger";

import {
  getNotifications,
  isFetchingNotifications
} from "../../../features/Notifications/reducers/notificationsReducer";

import { markNotificationsAsRead } from "../../../features/Notifications/actions/notificationsActions";

import {
  fetchTicket,
  viewTicket
} from "../../../features/HelpDesk/actions/helpdeskActions";

import {
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_BILLING_VIEW_INVOICE,
  ROUTE_VIEW_DOMAIN_GENERAL_INFO,
  ROUTE_VIEW_HOSTING_GENERAL_INFO,
  ROUTE_VIEW_SERVER_GENERAL_INFO,
  ROUTE_HELPDESK_TICKET_VIEW,
  compileRoute
} from "../../../routes/routes";

const StyledNotificationsNavItem = styled.li`
  position: relative;
  display: ${props => props.isMobile && "inline-block"};

  border-left: ${props =>
    props.isMobile ? "none" : `1px solid ${props.theme.neutral3}`};
  max-height: 74px;

  & > a {
    color: ${theme.neutral4};
    padding-top: ${props => (props.isTablet ? "23px" : "23px")};
    padding-bottom: 13px;
  }

  & > a:hover {
    color: ${theme.neutral5};
  }

  & .dropdown-toggle:after {
    display: none;
  }

  & .dropdown-menu {
    position: ${props => {
    if (props.isMobile || props.isTablet) {
      return "absolute !important";
    }

    return "static";
  }};
  }

  & > a > .icon {
    color: ${props => props.isMobile && `${props.theme.white}`};
  }
`;

const StyledNumber = styled.div`
  position: absolute;
  right: 13px;
  top: 12px;
  background-color: ${theme.redBase};
  color: ${theme.white};
  width: 5px;
  height: 5px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  width: 18px;
  height: 18px;
  border-radius: 12px;
`;

const CustomDropdownMenu = styled(DropdownMenu)`
  padding: 0;
  width :400px;
  max-width ${props => (props.isMobile ? "350px" : "400px")};
  min-width: ${props => (props.isMobile ? "350px" : "400px")};
  width: ${props => (props.isMobile ? "350px" : "400px")};
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  left: ${props => props.isMobile && "auto"};
  right: ${props => props.isMobile && "-50px"};
`;

const StyledHeader = styled.div`
  position: sticky;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  min-height: 30px;
  border-bottom: 1px solid ${theme.neutral3};
  background-color: ${theme.white};
`;

const StyledHeaderTitle = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  text-transform: uppercase;
  color: ${theme.neutral5};
  position: relative;
`;

const StyledMarkAllAsRead = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.blueBase};
  float: right;
  letter-spacing: 0.2px;
  position: relative;
  top: 2px;
  cursor: pointer;
`;

const notificationsMap = {
  active: NotificationSuccess,
  warning: NotificationWarning,
  danger: NotificationDanger
};

const getNotificationForStatus = status => {
  return notificationsMap[status] ? notificationsMap[status] : Notification;
};

const getParamsForRouteByType = (type, item_id, item_value) => {
  switch (type) {
    case "domain":
      return {
        name: item_value,
        id: item_id
      };

    case "ticket":
      return {
        id: item_id
      };

    default:
      return null;
  }
};

const translationsMap = {
  proinvoice: "notifications.view-proinvoice",
  invoice: "notifications.view-invoice",
  domain: "notifications.view-domain",
  hosting: "notifications.view-hosting",
  server: "notifications.view-server",
  ticket: "notifications.view-ticket"
};

class NotificationsNavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };

    this.routesMap = {
      proinvoice: compileRoute(ROUTE_BILLING_VIEW_PROINVOICE),
      invoice: compileRoute(ROUTE_BILLING_VIEW_INVOICE),
      domain: compileRoute(ROUTE_VIEW_DOMAIN_GENERAL_INFO),
      hosting: compileRoute(ROUTE_VIEW_HOSTING_GENERAL_INFO),
      server: compileRoute(ROUTE_VIEW_SERVER_GENERAL_INFO),
      ticket: compileRoute(ROUTE_HELPDESK_TICKET_VIEW)
    };
  }

  getRouteForType = type => {
    return this.routesMap[type] ? this.routesMap[type] : null;
  };
  onShowMenu = (e) => {
    e.preventDefault();
    this.setState(
      (prevState) => ({ opened: !prevState.opened }), // Toggle state
      () => {
        if (this.state.opened) {
          document.addEventListener("click", this.onCloseMenu);
        } else {
          document.removeEventListener("click", this.onCloseMenu);
        }
      }
    );
  };


  onCloseMenu = (e) => {
    if (this.dropdownRef && !this.dropdownRef.contains(e.target)) {
      this.setState({ opened: false }, () => {
        document.removeEventListener("click", this.onCloseMenu);
      });
    }
  };

  onMarkAllAsRead = () => {
    return this.props.markNotificationsAsRead(this.props.notifications);
  };

  onMarkAsReadAndRedirect = (notification, url) => {
    this.props.markNotificationsAsRead([notification]).then(() => {
      switch (notification.type) {
        case "ticket":
          this.props.fetchTicket(notification.item_id).then(ticket => {
            this.props.viewTicket(ticket);
            this.props.history.push(url);
          });
          break;

        default:
          this.props.history.push(url);
      }
    });
  };
  componentWillUnmount() {
    document.removeEventListener("click", this.onCloseMenu);
  }

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledNotificationsNavItem
            isMobile={isMobileOnly}
            isTablet={isTablet}
            className={`nav-item dropdown ${this.props.active ? "active" : ""
              } ${this.props.disabled ? "disabled" : ""} ${isMobileOnly &&
              "mr-3"}`}
            ref={(node) => (this.dropdownRef = node)}
          >
            <a
              className={`nav-link dropdown-toggle px-4 ${this.state.opened ? "show" : ""
                }`}
              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={!this.props.isFetchingNotifications && this.onShowMenu}
            >
              <Icon size="l" icon="notification" />
              {this.props.notifications.length > 0 && (
                <StyledNumber>{this.props.notifications.length}</StyledNumber>
              )}
            </a>

            <CustomDropdownMenu
              isMobile={isMobileOnly}
              alignMenu="right"
              className={`dropdown-menu ${this.state.opened ? "show" : ""}`}
              aria-labelledby="navbarDropdown"
            >
              {this.props.notifications.length === 0 && <NoNotifications />}
              {this.props.notifications.length > 0 && (
                <StyledHeader>
                  <StyledHeaderTitle>
                    {this.props.translate("notifications.title")}
                  </StyledHeaderTitle>
                  <StyledMarkAllAsRead onClick={() => this.onMarkAllAsRead()}>
                    {this.props.translate("notifications.mark-all-as-read")}
                  </StyledMarkAllAsRead>
                </StyledHeader>
              )}

              {this.props.notifications.length > 0 &&
                this.props.notifications.map(notification => {
                  const NotificationComponent = getNotificationForStatus(
                    notification.status ? notification.status.state : ""
                  );
                  const route = this.getRouteForType(notification.type);

                  if (
                    notification.type === "domain" &&
                    notification.item_value === ""
                  )
                    return null;

                  const params = getParamsForRouteByType(
                    notification.type,
                    notification.item_id,
                    notification.item_value
                  );
                  const url = route(params);
                  return (
                    <NotificationComponent
                      key={notification.id}
                      notification={notification}
                      title={notification.title}
                      link={url}
                      linkTitle={this.props.translate(
                        translationsMap[notification.type]
                      )}
                      timestamp={notification.created_at}
                      onMarkAsRead={this.onMarkAsReadAndRedirect}
                    >
                      {notification.message}
                    </NotificationComponent>
                  );
                })}
            </CustomDropdownMenu>
          </StyledNotificationsNavItem>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: getNotifications(state),
    isFetchingNotification: isFetchingNotifications(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { markNotificationsAsRead, fetchTicket, viewTicket },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(NotificationsNavItem)));
