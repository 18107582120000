import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";
import Icon from "../../components/Icon";

const StyledBlockRadiobox = styled.div`
  border: 1px solid;
  border-color: ${props => (props.selected ? theme.blueBase : theme.neutral3)};
  background-color: ${props => (props.selected ? theme.blue1 : `inherit`)};
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  margin: 0 auto; /* Centers the element itself within its parent container */
`;


const StyledSelectedIcon = styled.span`
  
`;

const StyledCreditCardIcon = styled.img`
    padding-right: 10px;
`;

const StyledMethodDetails = styled.div`
  
`;

const StyledMethodTitle = styled.span`
justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
  color: ${props => (props.selected ? theme.blueBase : theme.neutralBase)};
`;
const StyledMethodDescription = styled.span`
  
`;

const StyledHiddenRadiobox = styled.input`
  display: none;
`;

const BlockRadioboxNew = props => {
    return (
        <Translate>
            {({ translate }) => (
                <StyledBlockRadiobox
                    selected={props.input.checked}
                    onClick={e =>
                        !props.readOnly &&
                        !props.disabled &&
                        props.input.onChange(props.input.value)
                    }
                >
                    <StyledMethodDetails>
                        <StyledCreditCardIcon
                            className="img-fluid"
                            src={props.input.checked ? props.activeIcon : props.defaultIcon}
                        />
                        <StyledMethodTitle>{props.title}</StyledMethodTitle>
                        {props.children &&
                            props.children.length > 0 && (
                                <StyledMethodDescription>
                                    {props.children}
                                </StyledMethodDescription>
                            )}
                    </StyledMethodDetails>
                    <StyledHiddenRadiobox
                        onChange={props.input.onChange}
                        checked={props.input.checked}
                        type="radio"
                        name={props.input.name}
                        value={props.input.value}
                    />
                </StyledBlockRadiobox>
            )}
        </Translate>
    );
};

BlockRadioboxNew.defaultProps = {
    selected: false
};

export default BlockRadioboxNew;
