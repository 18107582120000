import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import Breadcrumb from "../../../../components/Breadcrumb";
import BreadcrumbItem from "../../../../components/Breadcrumb/BreadcrumbItem";

import ContentLoader from "../../../../components/Loaders/ContentLoader";

import {
  fetchEmail,
  viewEmail
} from "../../../Customer/actions/customerActions";

import { getEmailToView } from "../../../Customer/reducers/customerReducer";

import {
  ROUTE_INDEX,
  ROUTE_MY_ACCOUNT_EMAILS
} from "../../../../routes/routes";

const StyledEmailSubject = styled.h4`
  font-size: 20px;
  padding: 20px 20px 10px 20px;
`;

class ViewEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEmail: this.props.currentEmail
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentEmail !== nextProps.currentEmail) {
      this.setState(
        {
          ...this.state,
          currentEmail: nextProps.currentEmail
        },
        () => {
          this.updateIframe();
        }
      );
    }
  }

  componentDidMount() {
    if (!this.props.currentEmail) {
      this.props.fetchEmail(this.props.match.params.id).then(email => {
        this.props.viewEmail({ id: email.id });
        this.setState({ currentEmail: email }, () => {
          this.updateIframe();
        });
      });
    }
  }

  updateIframe() {
    const iframe = this.refs.iframe;
    iframe.srcdoc = this.state.currentEmail.message;
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          {!this.state.currentEmail && <ContentLoader />}
          {this.state.currentEmail && (
            <Breadcrumb>
              <BreadcrumbItem to={ROUTE_INDEX} title="Home" />
              <BreadcrumbItem to={ROUTE_MY_ACCOUNT_EMAILS} title="Emails" />
              <BreadcrumbItem title={this.state.currentEmail.subject} />
            </Breadcrumb>
          )}

          {this.state.currentEmail && (
            <div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledEmailSubject>
                    {this.state.currentEmail.subject}
                  </StyledEmailSubject>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <iframe
                    title={this.state.currentEmail.subject}
                    style={{
                      width: "100%",
                      height: "800px",
                      overflow: "visible"
                    }}
                    width="100%"
                    height="650px"
                    ref="iframe"
                    scrolling="yes"
                    frameBorder="0"
                    sandbox=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const currentEmail = getEmailToView(state);
  return {
    currentEmail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchEmail,
      viewEmail
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewEmail);
