import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Field, Form, FormSpy } from "react-final-form";
import { Translate } from "react-localize-redux";
import { useState } from "react";
import isEmpty from "lodash/isEmpty";
import { transliterateToCyrilic } from "../../../../../services/transliterateService";

import InputField from "../../../../../components/InputField";
import BlockRadiobox from "../../../../../components/BlockRadiobox";
import NoticeInfo from "../../../../../components/Notices/NoticeInfo";
import SelectBox from "../../../../../components/SelectBox";

import graphicIndividualDefault from "../../../../../assets/images/graphic_individual.svg";
import graphicIndividualActive from "../../../../../assets/images/graphic_individual_active.svg";
import graphicCompanyDefault from "../../../../../assets/images/graphic_company.svg";
import graphicCompanyActive from "../../../../../assets/images/graphic_company_active.svg";

import { CONTACT_DETAILS_TYPE_COMPANY } from "./ContactDetailsTypes";
import Checkbox from "../../../../../components/Checkbox";
import PhoneNumberInput from "../../../../../components/PhoneNumberInput/PhoneNumberInput";


const InputFieldInput = styled.input`
  &:disabled {
   color: #7B8BA7;
  }
`;

const StyledLabel = styled.label`
margin-right:2px;`

const StyledTerms = styled.div`
  margin-top: 30px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  font-size: 14px;

  & .custom-checkbox {
    width: auto;
  }

  & .input-help-block {
    top: 20px;
  }
`;


const StyledCyrillic = styled.div`
    min-width: 250px;
    font-size: 12px;
    line-height: 1.5;
    font-weight:bold;
    margin-bottom: 0;`


const ContactDetailsForm = ({
  onSubmit,
  onCancel,
  validate,
  contact,
  onSubmitting,
  disabledFields,
  showContactNotice,
  countries
}) => {

  const [showIdNumberField, setShowIdNumberField] = useState(false);
  const [isMacedoniaSelected, setIsMacedoniaSelected] = useState(false);
  const [shouldValidateMacedonian, setShouldValidateMacedonian] = useState(false);

  const handleCountryChange = selectedCountry => {
    setIsMacedoniaSelected(selectedCountry.label === "Macedonia");
    setShouldValidateMacedonian(selectedCountry.label === "Macedonia");
  };



  const handleTaxNumberCheckboxChange = (e) => {
    setShowIdNumberField(e.target.checked);
  };
  const minLength = min => (value, translate) =>
    value && value.length < min
      ? translate("validation.enter-tax-number")
      : undefined;


  const validateTaxNumber = (value, values, translate) => {
    if (values.contact_type === "company") {
      if (!values.tax_number || isEmpty(values.tax_number)) {
        return translate("validation.enter-tax-number");
      }
      if (!/^\d+$/.test(values.tax_number)) {
        return translate("validation.tax-number-numeric");
      }
    }
    return minLength(3)(values.tax_number, translate);;
  };


  return (
    <Translate>
      {({ translate }) => (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={contact || {}}
          render={({ handleSubmit, values, initialValues, form }) => {
            const handleResetField = (fieldName1, fieldName2) => {
              form.change(fieldName1, "");
              form.change(fieldName2, "");
            };
            return (
              <form id="domain-contact-details-form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6" onClick={() => handleResetField("company", "tax_number")}>
                        <Field
                          component={BlockRadiobox}
                          activeIcon={graphicIndividualActive}
                          defaultIcon={graphicIndividualDefault}
                          name="contact_type"
                          value="individual"
                          type="radio"
                          disabled={
                            disabledFields && !!disabledFields.contact_type
                          }

                        >
                          {translate("domain.domain-contacts.individual")}
                        </Field>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6">
                        <Field
                          component={BlockRadiobox}
                          activeIcon={graphicCompanyActive}
                          defaultIcon={graphicCompanyDefault}
                          name="contact_type"
                          value="company"
                          type="radio"
                          disabled={
                            disabledFields && !!disabledFields.contact_type
                          }
                        >
                          {translate("domain.domain-contacts.company")}
                        </Field>
                      </div>
                    </div>
                    <div className="row">
                      <Field component="input" name="full_name" type="hidden" />

                      <div className="col-12 col-sm-12 col-md-12">
                        <Field
                          component={SelectBox}
                          label={translate("domain.domain-contacts.country")}
                          options={countries}
                          isSearchable={true}
                          name="country"
                          required
                          onChange={handleCountryChange}

                        />
                        {isMacedoniaSelected && (
                          <StyledCyrillic>{translate("domain.domain-contacts.must-be-cyrillic")}</StyledCyrillic>
                        )}
                      </div>

                      <div className="col-12 col-sm-6 col-md-6">
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.first-name")}
                          name="first_name"
                          readOnly={disabledFields && !!disabledFields.first_name}
                          required
                          validate={shouldValidateMacedonian ? (value) => {
                            if (!value) {
                              return translate("validation.enter-tax-number");
                            }
                            if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                              return translate("domain.domain-contacts.must-be-cyrillic");
                            }
                          } : undefined}
                          parse={shouldValidateMacedonian && transliterateToCyrilic}
                        />

                      </div>
                      <div className="col-12 col-sm-6 col-md-6">
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.last-name")}
                          name="last_name"
                          required
                          readOnly={disabledFields && !!disabledFields.last_name}
                          validate={shouldValidateMacedonian ? (value) => {
                            if (!value) {
                              return translate("validation.enter-tax-number");
                            }
                            if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                              return translate("domain.domain-contacts.must-be-cyrillic");
                            }
                          } : undefined}
                          parse={shouldValidateMacedonian && transliterateToCyrilic}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6">
                        {" "}
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.email")}
                          name="email"
                          required
                          readOnly={disabledFields && !!disabledFields.email}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-md-6">
                        {" "}
                        <PhoneNumberInput
                          name="phone"
                          label={translate("domain.domain-contacts.phone-number")}
                          defaultCountry={"MK"}
                          translate={translate}
                        />
                      </div>
                    </div>
                    {((contact &&
                      contact.contact_type === CONTACT_DETAILS_TYPE_COMPANY) ||
                      values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) && (
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-6">
                            <Field
                              component={InputField}
                              label={translate("domain.domain-contacts.company")}
                              name="company"
                              required
                              validate={shouldValidateMacedonian ? (value) => {
                                if (!value) {
                                  return translate("validation.enter-tax-number");
                                }
                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                  return translate("domain.domain-contacts.must-be-cyrillic");
                                }
                              } : undefined}
                              parse={shouldValidateMacedonian && transliterateToCyrilic}
                            />
                          </div>
                          <div className="col-12 col-sm-6 col-md-6">
                            <Field
                              component={InputField}
                              label={translate("cart.wizard.tax-number")}
                              name={showIdNumberField ? "no-tax_number" : "tax_number"}
                              disabled={showIdNumberField}
                              readOnly={showIdNumberField}
                              as={InputFieldInput}
                              validate={value => validateTaxNumber(value, values, translate)}
                              required
                            />
                            <StyledLabel>
                              <input
                                type="checkbox"
                                checked={showIdNumberField}
                                onChange={handleTaxNumberCheckboxChange}
                                style={{ cursor: 'pointer', marginRight: '4px' }}
                              />
                              {translate("pro-invoices.view.dont-have-tax-number")}
                            </StyledLabel>
                            {showIdNumberField && (

                              <Field
                                component={InputField}
                                label={translate("pro-invoices.view.id-number")}
                                name="tax_number"
                                required
                                validate={value => validateTaxNumber(value, values, translate)}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        {" "}
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.address")}
                          name="address1"
                          required
                          validate={shouldValidateMacedonian ? (value) => {
                            if (!value) {
                              return translate("validation.enter-tax-number");
                            }
                            if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                              return translate("domain.domain-contacts.must-be-cyrillic");
                            }
                          } : undefined}
                          parse={shouldValidateMacedonian && transliterateToCyrilic}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-sm-6 col-md-6">
                        {" "}
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.city")}
                          name="city"
                          required
                          validate={shouldValidateMacedonian ? (value) => {
                            if (!value) {
                              return translate("validation.enter-tax-number");
                            }
                            if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                              return translate("domain.domain-contacts.must-be-cyrillic");
                            }
                          } : undefined}
                          parse={shouldValidateMacedonian && transliterateToCyrilic}
                        />
                      </div>
                      <div className="col-6 col-sm-6 col-md-6">
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.zip-code")}
                          name="zip"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6">
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.state-region")}
                          name="state_region"
                        />
                      </div>

                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledTerms className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        <Field
                          component={Checkbox}
                          label={translate("cart.wizard.valid-info")}
                          name="valid_personal_data"
                        />
                      </div>
                    </StyledTerms>
                  </div>
                  {showContactNotice && (
                    <div className="col-12 col-sm-12 col-md-12">
                      <NoticeInfo />
                    </div>
                  )}
                </div>
                <FormSpy
                  subscription={{ submitting: true, invalid: true }}
                  onChange={formstate => onSubmitting({ ...formstate })}
                />
              </form>
            );
          }}
        />
      )}
    </Translate>
  );
}
ContactDetailsForm.defaultProps = {
  showContactNotice: false
};

export default ContactDetailsForm;
