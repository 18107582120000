import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FORM_ERROR } from "final-form";
import qs from "query-string";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"; // Add this import
import styled from "styled-components";
import { theme } from "../../design/Themes";

import { login } from "../Authentication/actions/authActions";
import {
  isAuthenticated,
  getAuthToken
} from "../Authentication/reducers/authReducer";

import {
  acceptInvitation,
  registerAssistant
} from "../Customer/actions/customerActions";

import InvitationLoginForm from "./InvitationLoginForm";
import InvitationRegisterForm from "./InvitationRegisterForm";

import {
  validateEmail,
  validatePassword,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateMkPhoneNumber,
  validateTermsAndConditions,
  normalizeMacedonianPhoneNumber
} from "../../common/validationRules";

import whiteLogo from "../../assets/images/white-logo.svg";

import { ROUTE_INDEX, ROUTE_LOGIN } from "../../routes/routes";

const StyledLogoContainer = styled.div`
  background-color: ${theme.neutralBase};
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledInvitationFormContainer = styled.div`
  margin-top: 100px;
`;

const StyledInvitationFormWrapper = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledLogo = styled.img`
  position: relative;
  top: 45%;
`;

const INVITATION_FORM_MODE_LOGIN = "login";
const INVITATION_FORM_MODE_REGISTER = "register";

const Invitation = ({
  location,
  history,
  isAuthenticated,
  authToken,
  login,
  acceptInvitation,
  registerAssistant
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha(); // Add the hook
  const [mode, setMode] = useState(null);
  const [token, setToken] = useState(null);
  const [loginError, setLoginError] = useState(""); // Add state for login errors
  const [registerError, setRegisterError] = useState(""); // Add state for register errors

  useEffect(() => {
    const params = qs.parse(location.search);
    if (!params.token || params.token.length === 0) {
      history.push(ROUTE_LOGIN);
    }

    if (
      !params.action ||
      (params.action !== INVITATION_FORM_MODE_LOGIN &&
        params.action !== INVITATION_FORM_MODE_REGISTER)
    ) {
      history.push(ROUTE_LOGIN);
    }

    setMode(params.action);
    setToken(params.token);
  }, [location, history]);

  useEffect(() => {
    if (isAuthenticated && token && authToken) {
      acceptInvitation({ token }).then(() => {
        history.push(ROUTE_INDEX);
      });
    }
  }, [isAuthenticated, token, authToken, acceptInvitation, history]);

  const validateLogin = values => {
    return {
      email: validateEmail(values.email),
      password: validatePassword(values.password)
    };
  };

  const validateRegister = values => {
    return {
      first_name: validateFirstName(values.first_name),
      last_name: validateLastName(values.last_name),
      // phone: validateMkPhoneNumber(values.phone),
      password: validatePassword(values.password),
      terms: validateTermsAndConditions(values.terms)
    };
  };

  const onLogin = async values => {
    if (!executeRecaptcha) {
      setLoginError("reCAPTCHA is unavailable. Please enable third-party cookies or try again later.");
      return;
    }

    // Generate reCAPTCHA token
    let recaptchaToken;
    try {
      recaptchaToken = await executeRecaptcha("invitation_login");
    } catch (error) {
      console.error("Error generating reCAPTCHA token:", error);
      setLoginError("reCAPTCHA verification failed. Please try again.");
      return;
    }

    try {
      setLoginError("");
      await login(
        values.email,
        values.password,
        null,
        null,
        recaptchaToken
      );
      await acceptInvitation({ token });
      history.push(ROUTE_INDEX);
    } catch (error) {
      const { messages } = error.data.data;
      setLoginError(messages[0]);
      return {
        [FORM_ERROR]: messages[0]
      };
    }
  };

  const onRegister = async values => {
    if (!executeRecaptcha) {
      setRegisterError("reCAPTCHA is unavailable. Please enable third-party cookies or try again later.");
      return;
    }

    // Generate reCAPTCHA token
    let recaptchaToken;
    try {
      recaptchaToken = await executeRecaptcha("invitation_register");
    } catch (error) {
      console.error("Error generating reCAPTCHA token:", error);
      setRegisterError("reCAPTCHA verification failed. Please try again.");
      return;
    }

    try {
      setRegisterError("");
      const normalizedValues = {
        ...values,
        phone: normalizeMacedonianPhoneNumber(values.phone)
      };

      const customer = await registerAssistant(token, normalizedValues);
      if (customer) {
        await login(
          customer.email,
          values.password,
          null,
          null,
          recaptchaToken
        );
        await acceptInvitation({ token });
        history.push(ROUTE_INDEX);
      }
    } catch (error) {
      setRegisterError(error.message || "An error occurred during registration.");
      throw error;
    }
  };

  return (
    <div className="row">
      <BrowserView viewClassName="col-8 offset-2 col-sm-8 offset-sm-2 col-md-8 offset-md-2">
        <StyledInvitationFormContainer className="row no-gutters">
          <StyledLogoContainer className="col-4 col-sm-4 col-md-4">
            <StyledLogo src={whiteLogo} alt="MKHost" />
          </StyledLogoContainer>
          <StyledInvitationFormWrapper className="col-8 col-sm-8 col-md-8">
            {mode === INVITATION_FORM_MODE_LOGIN ? (
              <InvitationLoginForm
                onSubmit={onLogin}
                validate={validateLogin}
                isMobile={isMobile}
                submitError={loginError} // Pass loginError
              />
            ) : (
              <InvitationRegisterForm
                onSubmit={onRegister}
                validate={validateRegister}
                isMobile={isMobile}
                submitError={registerError} // Pass registerError
              />
            )}
          </StyledInvitationFormWrapper>
        </StyledInvitationFormContainer>
      </BrowserView>
      <MobileView viewClassName="col-12 col-sm-12 col-md-12">
        <StyledInvitationFormContainer className="row">
          <StyledInvitationFormWrapper className="col-10 offset-1 col-sm-12 col-md-12">
            {mode === INVITATION_FORM_MODE_LOGIN ? (
              <InvitationLoginForm
                onSubmit={onLogin}
                validate={validateLogin}
                isMobile={isMobile}
                submitError={loginError} // Pass loginError
              />
            ) : (
              <InvitationRegisterForm
                onSubmit={onRegister}
                validate={validateRegister}
                isMobile={isMobile}
                submitError={registerError} // Pass registerError
              />
            )}
          </StyledInvitationFormWrapper>
        </StyledInvitationFormContainer>
      </MobileView>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state),
    authToken: getAuthToken(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      login,
      acceptInvitation,
      registerAssistant
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invitation);