import React from "react";

import Domain from "../../Checkout/Products/Domain";
import Hosting from "../../Checkout/Products/Hosting";
import DedicatedServer from "../../Checkout/Products/DedicatedServer";
import CloudServer from "../Products/CloudServer";

import {
  PRODUCT_DOMAIN,
  PRODUCT_PRODUCT,
  PRODUCT_DEDICATED_SERVER,
  PRODUCT_CLOUD_SERVER,
  DOMAIN_SERVICE,
  HOSTING_SERVICE,
  SERVER_SERVICE
} from "../Products/ProductTypes";
import Service from "../Products/Service";

const CheckoutCartProductsList = ({
  products,
  onAttachServiceToProduct,
  onRemoveServiceFromProduct,
  onRemoveProductFromCart,
  onChangeContact,
  onUpdateProductPeriod,
  onUpdateHostingPeriod,
  updatingDomain,
  deletingProductId,
  updatingHostingPeriod,
  updatingHostingPeriodId,
  onUpdateServerSettings,
  updatingServerSettings,
  updatingServerSettingsId,
  onSaveSingleDomainNameservers,
  onSubmittingDomainNameservers,
  submittingDomainNameservers,
  invalidDomainNameservers,
  onBlockCheckout
}) => (
  <div className="row">
    <div className="col-12 col-sm-12 col-md-12">
      {/* {products
        .filter(product => product.type === PRODUCT_DOMAIN)
        .map(product => (
          <Domain
            product={product}
            key={`cart-domain-${product.id}`}
            onAttachServiceToProduct={onAttachServiceToProduct}
            onRemoveServiceFromProduct={onRemoveServiceFromProduct}
            onRemoveProductFromCart={onRemoveProductFromCart}
            onChangeContact={onChangeContact}
            onUpdateProductPeriod={onUpdateProductPeriod}
            updatingDomain={updatingDomain}
            deletingProductId={deletingProductId}
            itemsCount={products.length}
            onSaveSingleDomainNameservers={onSaveSingleDomainNameservers}
            onSubmittingDomainNameservers={onSubmittingDomainNameservers}
            submittingDomainNameservers={submittingDomainNameservers}
            invalidDomainNameservers={invalidDomainNameservers}
          />
        ))} */}
      {products
        .filter(product => product.type === PRODUCT_PRODUCT)
        .map(product => (
          <Hosting
            product={product}
            key={`cart-domain-${product.id}`}
            onAttachServiceToProduct={onAttachServiceToProduct}
            onRemoveServiceFromProduct={onRemoveServiceFromProduct}
            onRemoveProductFromCart={onRemoveProductFromCart}
            onUpdateHostingPeriod={onUpdateHostingPeriod}
            deletingProductId={deletingProductId}
            updatingHostingPeriod={updatingHostingPeriod}
            updatingHostingPeriodId={updatingHostingPeriodId}
          />
        ))}

      {products
        .filter(product => product.type === PRODUCT_DEDICATED_SERVER)
        .map(product => (
          <DedicatedServer
            product={product}
            key={`cart-dedicated-server-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            onUpdateHostingPeriod={onUpdateHostingPeriod}
            deletingProductId={deletingProductId}
            updatingHostingPeriod={updatingHostingPeriod}
            updatingHostingPeriodId={updatingHostingPeriodId}
            onUpdateServerSettings={onUpdateServerSettings}
            updatingServerSettings={updatingServerSettings}
            updatingServerSettingsId={updatingServerSettingsId}
            onBlockCheckout={onBlockCheckout}
            onAttachServiceToProduct={onAttachServiceToProduct}
            onRemoveServiceFromProduct={onRemoveServiceFromProduct}
          />
        ))}

      {products
        .filter(product => product.type === PRODUCT_CLOUD_SERVER)
        .map(product => (
          <CloudServer
            product={product}
            key={`cart-dedicated-server-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            onUpdateHostingPeriod={onUpdateHostingPeriod}
            deletingProductId={deletingProductId}
            updatingHostingPeriod={updatingHostingPeriod}
            updatingHostingPeriodId={updatingHostingPeriodId}
            onUpdateServerSettings={onUpdateServerSettings}
            updatingServerSettings={updatingServerSettings}
            updatingServerSettingsId={updatingServerSettingsId}
            onBlockCheckout={onBlockCheckout}
            onAttachServiceToProduct={onAttachServiceToProduct}
            onRemoveServiceFromProduct={onRemoveServiceFromProduct}
          />
        ))}

      {products
        .filter(
          product =>
            product.type === DOMAIN_SERVICE ||
            product.type === HOSTING_SERVICE ||
            product.type === SERVER_SERVICE
        )
        .map(product => (
          <Service
            product={product}
            key={`cart-service-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            deletingProductId={deletingProductId}
          />
        ))}
    </div>
  </div>
);

export default CheckoutCartProductsList;
