import React, { useState } from 'react'
import styled, { ThemeConsumer } from "styled-components";
import { withLocalize, Translate } from "react-localize-redux";
import { isMobile, isMobileOnly } from "react-device-detect";
import { theme } from "../../../../design/Themes";
import Modal from '../../../../components/Modal';
import en from "../../../../translations/en.json";
import mk from "../../../../translations/mk.json";
import { getApplicationLocale } from "../../../../services/localizationService";
import { CONTACT_DETAILS_TYPE_COMPANY, CONTACT_DETAILS_TYPE_INDIVIDUAL } from "../../../Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";
import { validateCountry, validateEmail, validateFirstName, validateLastName, validateMkPhoneNumber } from "../../../../common/validationRules";
import { Field, Form } from 'react-final-form';
import BlockRadioboxNew from '../../../../components/BlockRadioboxNew';
import iconPersonInactive from "../../../../assets/images/icon-person-inactive.svg";
import iconBusinessActive from "../../../../assets/images/icon-business-active.svg";
import iconBusinessInactive from "../../../../assets/images/icon-business-inactive.svg";
import InputField from '../../../../components/InputField';
import isEmpty from "lodash/isEmpty";
import { transliterateToCyrilic } from "../../../../services/transliterateService";
import { fetchCustomerPersonalInformation, fetchDataFromUJP, uploadDocumentSignedV2 } from '../../../../services/customerService';
import SelectBox from '../../../../components/SelectBox';
import Icon from '../../../../components/Icon';
import CustomTooltip from '../../../../components/CustomTooltip';
import SecondaryButton from '../../../../components/Buttons/SecondaryButton';
import Radiobox from '../../../../components/Radiobox';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import iconUpload from "../../../../assets/images/icon-upload-active.svg";
import { withRouter } from 'react-router-dom';
import { getCountriesFormatted } from '../../../Addressing/reducers/addressingReducer';
import { connect } from 'react-redux';
import PhoneNumberInput from '../../../../components/PhoneNumberInput/PhoneNumberInput';

// Lang implementation
const lang = {
    en: en,
    mk: mk
};

const resolvePath = (object, path, defaultValue) =>
    path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = key =>
    resolvePath(lang[getApplicationLocale()], key, undefined);


const StyledDescription = styled.div`
  font-size: ${isMobileOnly ? '12px' : '14px;'}
  color: ${theme.neutral6};
  margin-bottom: ${isMobileOnly ? '10px' : '20px'};
`;



const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledBorder = styled.div`
  border: 1px solid ${theme.neutral3}
`;

const StyledErrorText = styled.div`
  font-size: 14px;
  color: ${theme.red2};
  display: flex;
  align-items: center;
`;

const StyledSmallText = styled.div`
  font-size: 14px;
  color: ${theme.yellowBase};
  line-height: 1.2;
  display: flex;
  font-weight: 400;
  align-items: center;
`;


const StyledFooter = styled.div`
  gap: ${isMobileOnly ? '10px' : '20px'}
  padding-bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;




const TabContainer = styled.div`
  margin-top: 20px;
`;

const TabButtons = styled.div`
  display: flex;
  gap: ${isMobileOnly ? '5px' : '15px'}
`;

const TabButton = styled.button`
  background-color: ${props => (props.active ? theme.blue5 : theme.neutral2)};
  border: none;
  padding: ${isMobileOnly ? '' : '10px 25px;'}
  border-radius: 4px 4px 0 0;
  font-size: 14px;
  font-weight: bold;
  color: ${theme.white};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${props => (props.disabled ? theme.neutral2 : theme.blue5)};
  }
`;

const TabContent = styled.div`
  font-size: 14px;
  color: ${theme.neutral6};
`;

const StyledContactTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.43;
  color: ${theme.neutralBase};
  text-transform: uppercase;
  float: left;
`;

const StyledContactHeader = styled.div`
  margin-bottom: 20px;
`;
const StyledCustomTooltip = styled.div`
  display: inline;
  margin-bottom: 5px
`;

const StyledCyrillic = styled.div`
  min-width: 250px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0;
`;


const StyledTerms = styled.div`
  margin-top: 30px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${theme.neutral3};

  & .custom-checkbox {
    width: auto;
  }

  @media (max-width: 425px) {
    padding-bottom: 80px;
  }
`;

const StyledHelp = styled.div`
  min-width: 250px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 30px;
`;

const StledDnsDescription = styled.div`
  font-size: 14px;
  line-height: 1.3;
  color: ${theme.neutralBase};
  font-weight: 400;
  display: flex;
`;
const StledDnsDescriptionBold = styled.div`
  font-size: 14px;
  line-height: 1.3;
  color: ${theme.neutralBase};
  font-weight: bold;
  padding-left: 5px;
`;


const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.neutralBase};
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledUnderTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.neutralBase};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const DomainContactNew = (showAddInfoModal, setShowAddInfoModal, selectedProduct, setSelectedProduct, products, countries) => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [disabledFields, setDisabledField] = useState('');
    const [restrictionMessageCheck, setRestrictionMessageCheck] = useState(false);
    const [shouldValidateMacedonianAdmin, setShouldValidateMacedonianAdmin] = useState(false);
    const [shouldValidateMacedonianTechnical, setShouldValidateMacedonianTechnical] = useState(false);
    const [shouldValidateMacedonian, setShouldValidateMacedonian] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [maxUnlockedTab, setMaxUnlockedTab] = useState(1);
    const [selectedAdministrativeCountry, setSelectedAdministrativeCountry] = useState('');
    const [selectedTechnicalCountry, setSelectedTechnicalCountry] = useState('');
    const [selectedOption, setSelectedOption] = useState("");
    const [currentStatusOption, setCurrentStatusOption] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const [dateError, setDateError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showDataPreview, setShowDataPreview] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [activeTab, setActiveTab] = useState("option1");

    const [formData, setFormData] = useState({
        option1: {
            contact_type: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            company: "",
            tax_number: "",
            street_number: "",
            address: "",
            city: "",
            zip: "",
            country: "",
        },
        option2: {
            administrative_contact: {
                name: "",
                surname: "",
                email: "",
                phone: "",
                country: ""
            },
            technical_contact: {
                name: "",
                surname: "",
                email: "",
                phone: "",
                country: ""
            },
        },
        option3: {
            dns_server1: {
                name: "",
                value: "",
                address: "",
                fixed: false,
            },
            dns_server2: {
                name: "",
                value: "",
                address: "",
                fixed: false,
            },
            dns_server3: {
                name: "",
                value: "",
                address: "",
                fixed: false,
            },
        },
        option4: {
            registration_certificate: "",
            registration_certificate_date: "",
            order_registration_certificate: false,
        }
    });


    const getPrimaryButtonLabel = () => {
        return activeTab === "option4"
            ? translate("cart.wizard.account-info-modal.view")
            : translate("cart.wizard.account-info-modal.save-and-continue");
    };

    const uploadDocumentSignedRequest = async (domain, file) => {
        setIsSubmitting(true)
        try {
            if (!domain) {
                throw new Error("Не е избран домен.");
            }
            if (!file) {
                throw new Error("Ве молиме прикачете документ.");
            }

            const customerData = await fetchCustomerPersonalInformation();

            if (!customerData || !customerData.data.data.customer.id) {
                throw new Error("Не можам да го најдам клиентскиот ID. Обиди се повторно.");
            }

            const clientId = customerData.data.data.customer.id;
            const deliveryType = "digital-manual";
            const domainAction = "register";

            // Prepare FormData
            const formData = new FormData();
            formData.append("domain", domain);
            formData.append("client_id", clientId);
            formData.append("delivery_type", deliveryType);
            formData.append("signature_status", "signed");
            formData.append("domain_action", domainAction);
            formData.append("document_type", "documentation");
            formData.append("attachment", file);

            console.log("Uploading document with FormData:", Object.fromEntries(formData.entries()));

            // Make API request
            const response = await uploadDocumentSignedV2(formData);
            console.log('Document link: ', response.data.data.document)
            if (!response.data) {
                throw new Error("Неуспешно прикачување на документ.");
            }

            const documentLink = response.data.data.document;
            console.log("✅ Document Uploaded. Link:", documentLink);

            setFormData((prevData) => ({
                ...prevData,
                option4: {
                    ...prevData.option4,
                    registration_certificate: documentLink,
                },
            }));


            setIsSubmitting(false)
            return true; // Indicate success
        } catch (error) {
            console.error(`Грешка при праќање на документ за ${domain}:`, error);
            alert(error.message || translate("cart.wizard.account-info-modal.validation.error-while-uploading-document"));
            setIsSubmitting(false)
            return false; // Indicate failure
        } finally {
            setIsSubmitting(false)
        }
    };

    const handleModalPrimaryClick = async () => {
        if (activeTab === "option1") {
            document.getElementById("domain-contact-details-form").dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }
        else if (activeTab === "option2") {
            document.getElementById("contact-details-form").dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }
        else if (activeTab === "option3") {
            if (!selectedOption) {
                return; // Prevent progress if no DNS option is selected
            }
            if (selectedOption === "customDnsServers") {
                const hasMissingIp = hosts.some(host => doesHostnameMatchDomain(host.value, domainName) && !host.address.trim());

                if (hasMissingIp) {
                    alert(translate("cart.wizard.account-info-modal.validation.you-have-to-input-ip-address"));
                    return; // Prevent navigation
                }
            }

            handleDnsSubmit();
            setActiveTab(formData.option1.contact_type === "company" ? "option4" : setShowDataPreview(true));
            return;
        }
        if (activeTab === "option4") {
            console.log("currentStatusOption:", currentStatusOption)
            if (currentStatusOption === "uploadNow") {
                if (!validateRegistrationDate(formData.option4.registration_certificate_date)) {
                    return;
                }

                setIsSaving(true);
                const domain = selectedProduct ? selectedProduct.domain : null;

                const uploadSuccess = await uploadDocumentSignedRequest(domain, uploadedFile);

                if (uploadSuccess) {
                    setShowDataPreview(true);
                }

                setIsSaving(false);
            } else if (currentStatusOption === "mkHostFetch") {
                formData.option4.order_registration_certificate = true;
                setShowDataPreview(true);
            } else {
                setShowDataPreview(true);
            }
        }
    };

    const handleDnsSubmit = () => {
        let dns_servers = [];

        if (selectedOption === "hostedInMkhost") {
            dns_servers = [
                { name: "ns.mkhost.mk", address: "" },
                { name: "ns.mkhost.org", address: "" }
            ];
        } else if (selectedOption === "customDnsZone") {
            dns_servers = [
                { name: "dns1.mk-host.mk", address: "" },
                { name: "dns2.mk-host.mk", address: "" }
            ];
        } else if (selectedOption === "customDnsServers") {
            const dnsEntries = hosts
                .filter((host) => host.value.trim() !== "") // Remove empty values
                .map((host) => ({
                    name: host.value,
                    address: host.address || "",
                }));

            // Ensure at least two nameservers are entered before proceeding
            if (dnsEntries.length < 2) {
                alert(translate("cart.wizard.account-info-modal.validation.you-have-to-min-two-dns"));
                return;
            }

            dns_servers = dnsEntries;
        }

        setFormData((prevData) => ({
            ...prevData,
            option3: {
                selected_dns_option: selectedOption,
                dns_servers,
            },
        }));

        console.log("Updated formData after DNS Submit:", formData); // Debugging
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        if (file.type !== "application/pdf") {
            setFileError(translate("cart.wizard.account-info-modal.validation.only-pdf-accepted"));
            setUploadedFile(null);
            return;
        }

        setFileError(""); // Clear error
        setUploadedFile(file);

        setFormData((prevData) => ({
            ...prevData,
            option4: {
                ...prevData.option4,
                registration_certificate: file.name,
            },
        }));
    };


    const handleFormSubmit = (values, form) => {
        console.log("Submitting values:", values);

        // **First, clear fields based on `contact_type` immediately**
        if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
            form.change("first_name", "");
            form.change("last_name", "");
        } else {
            form.change("company", "");
            form.change("tax_number", "");
        }

        // **Now, update state**
        setFormData((prevData) => ({
            ...prevData,
            option1: {
                ...prevData.option1,
                contact_type: values.contact_type,
                first_name: values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL ? values.first_name || "" : "", // Reset if Company
                last_name: values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL ? values.last_name || "" : "", // Reset if Company
                email: values.email || "",
                phone: values.phone || "",
                company: values.contact_type === CONTACT_DETAILS_TYPE_COMPANY ? values.company || "" : "", // Reset if Individual
                tax_number: values.contact_type === CONTACT_DETAILS_TYPE_COMPANY ? values.tax_number || "" : "", // Reset if Individual
                address: values.address1 || prevData.option1.address,
                street_number: values.street_number || prevData.option1.street_number,
                city: values.city || prevData.option1.city,
                zip: values.zip || prevData.option1.zip,
                country: values.country ? values.country : prevData.option1.country,  // ✅ FIXED
            },
        }));

        setActiveTab("option2");
    };

    const [hosts, setHosts] = useState([
        { id: 1, name: translate("cart.wizard.account-info-modal.option3.ns-name-of-host") + " 1", value: "", address: "", fixed: true },
        { id: 2, name: translate("cart.wizard.account-info-modal.option3.ns-name-of-host") + " 2", value: "", address: "", fixed: true },
    ]);

    const maxHosts = 5;
    const domainName = products.length > 0 ? products[0].item.name : "";

    // Function to add a new host (up to 5 max)
    const addHost = () => {
        if (hosts.length < maxHosts) {
            setHosts([...hosts, { id: Date.now(), name: "", address: "", fixed: false }]);
        }
    };

    // Function to remove an additional host (only if it's not fixed)
    const removeHost = (id) => {
        setHosts(hosts.filter((host) => host.id !== id));
    };

    const updateHost = (id, field, value) => {
        setHosts(prevHosts =>
            prevHosts.map(host => {
                const isMatchingDomain = doesHostnameMatchDomain(host.value, domainName);
                const isIpRequired = isMatchingDomain && !value.trim(); // IP is required if hostname matches but missing

                return host.id === id
                    ? {
                        ...host,
                        [field]: value,
                        isIpRequired,
                    }
                    : host;
            })
        );
    };

    const doesHostnameMatchDomain = (hostValue, domain) => {
        if (typeof hostValue !== "string") {
            console.warn("Invalid hostValue:", hostValue);
            return false;
        }
        return hostValue.endsWith(`.${domain}`);
    };

    const handleContactSubmit = (values) => {
        console.log("Updating formData with values:", values);
        setFormData((prevData) => ({
            ...prevData,
            option2: {
                administrative_contact: {
                    name: values.administrative_contact && values.administrative_contact.name ? values.administrative_contact.name : "",
                    surname: values.administrative_contact && values.administrative_contact.surname ? values.administrative_contact.surname : "",
                    email: values.administrative_contact && values.administrative_contact.email ? values.administrative_contact.email : "",
                    phone: values.administrative_contact && values.administrative_contact.phone ? values.administrative_contact.phone : "",
                    country: values.administrative_contact && values.administrative_contact.country ? values.administrative_contact.country : "",
                },
                technical_contact: {
                    name: values.technical_contact && values.technical_contact.name ? values.technical_contact.name : "",
                    surname: values.technical_contact && values.technical_contact.surname ? values.technical_contact.surname : "",
                    email: values.technical_contact && values.technical_contact.email ? values.technical_contact.email : "",
                    phone: values.technical_contact && values.technical_contact.phone ? values.technical_contact.phone : "",
                    country: values.technical_contact && values.technical_contact.country ? values.technical_contact.country : "",
                },
            },
        }));
    };

    const handleCountryChange = (selectedOption, form) => {
        console.log(selectedOption)
        form.change("country", selectedOption);
        setFormData((prevData) => ({
            ...prevData,
            option1: {
                ...prevData.option1,
                country: selectedOption,
            },
        }));
        setShouldValidateMacedonian(selectedOption.label === "Macedonia");
    };

    // Check if a domain is **Company-Only**
    const isCompanyOnlyDomain = (domain) => {
        const companyOnlyDomains = ['.com.mk', '.org.mk', '.net.mk', '.edu.mk', '.inf.mk'];
        return companyOnlyDomains.some(ext => domain.endsWith(ext));
    };
    const isMacedoniaOnlyDomain = (domain) => {
        const macedoniaOnlyDomains = ['.mk', '.org.mk', '.net.mk', '.inf.mk', '.edu.mk'];
        return macedoniaOnlyDomains.some(ext => domain.endsWith(ext));
    };

    const getRestrictionMessage = (domain, contactType, country) => {
        if (isCompanyOnlyDomain(domain) && contactType === CONTACT_DETAILS_TYPE_INDIVIDUAL) {
            setRestrictionMessageCheck(true);
            return translate("cart.wizard.account-info-modal.validation.this-domain-only-company-sec");
        }
        if (isMacedoniaOnlyDomain(domain) && contactType === CONTACT_DETAILS_TYPE_INDIVIDUAL && country !== "Macedonia") {
            setRestrictionMessageCheck(true);
            return translate("cart.wizard.account-info-modal.validation.this-domain-only-macedonian-sec");
        }

        setRestrictionMessageCheck(false);
        return "";
    };

    const validateTaxNumber = (value, values = {}) => {
        if (values.contact_type === "company") {
            if (!value || isEmpty(value)) {
                return translate("validation.enter-tax-number");
            }
            if (!/^\d+$/.test(value)) {
                return translate("validation.tax-number-numeric");
            }
        }
        return undefined; // No error if not required
    };

    const validateRegistrationDate = (date) => {
        if (!date) {
            setDateError("Изберете датум на тековна состојба.");
            return false;
        }

        const selectedDate = new Date(date);
        const today = new Date();
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(today.getMonth() - 6);

        if (selectedDate < sixMonthsAgo) {
            setDateError(translate("cart.wizard.account-info-modal.validation.date-cannot-be-in-past"));
            return false;
        }

        if (selectedDate > today) {
            setDateError(translate("cart.wizard.account-info-modal.validation.date-cannot-be-in-future"));
            return false;
        }

        setDateError("");
        return true;
    };

    const validateContactDetails = (values) => {
        const errors = {};

        if (values.administrative_contact) {
            errors.administrative_contact = {
                name: validateFirstName(values.administrative_contact.name),
                surname: validateLastName(values.administrative_contact.surname),
                email: validateEmail(values.administrative_contact.email),
                phone: validateMkPhoneNumber(values.administrative_contact.phone),
                country: values.administrative_contact.country && values.administrative_contact.country.value
                    ? validateCountry(values.administrative_contact.country.value)
                    : translate("cart.wizard.account-info-modal.validation.enter-country")
            };
        }

        if (values.technical_contact) {
            errors.technical_contact = {
                name: validateFirstName(values.technical_contact.name),
                surname: validateLastName(values.technical_contact.surname),
                email: validateEmail(values.technical_contact.email),
                phone: validateMkPhoneNumber(values.technical_contact.phone),
                country: values.technical_contact.country && values.technical_contact.country.value
                    ? validateCountry(values.technical_contact.country.value)
                    : translate("cart.wizard.account-info-modal.validation.enter-country") // This ensures the error appears if country is missing
            };
        }

        return errors;
    };

    const validateAccountDetails = (values = {}) => {
        const errors = {};

        // Validate email, phone, and country (always required)
        errors.email = values.email ? validateEmail(values.email) : translate("validation.enter-email");
        // errors.phone = values.phone ? validateMkPhoneNumber(values.phone) : translate("validation.enter-phone-number");
        errors.country = values.country && values.country.value
            ? validateCountry(values.country.value)
            : translate("cart.wizard.account-info-modal.validation.enter-country");

        // Check if `contact_type` is set
        if (!values.contact_type) {
            errors.contact_type = translate("cart.wizard.account-info-modal.validation.select-entity-type");
        }
        else if (values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL) {
            // Individual must have first_name & last_name
            errors.first_name = values.first_name ? validateFirstName(values.first_name) : translate("validation.enter-first-name");
            errors.last_name = values.last_name ? validateLastName(values.last_name) : translate("validation.enter-last-name");
        }
        else if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
            // Company must have tax_number & company name
            errors.tax_number = values.tax_number ? validateTaxNumber(values.tax_number) : translate("cart.wizard.account-info-modal.validation.enter-tax-number");
            errors.company = values.company ? undefined : translate("validation.enter-company");
        }

        return errors;
    };

    const capitalizeFirstLetter = (text) => {
        if (!text) return "";

        // Split into words and spaces, process words, then rejoin
        return text
            .split(/(\s+)/) // Split into words AND spaces (keeps all whitespace)
            .map((token) => {
                // Only capitalize tokens that are NOT whitespace
                if (token.trim() === "") return token; // Leave spaces unchanged
                return token.charAt(0).toUpperCase() + token.slice(1);
            })
            .join(""); // Rejoin tokens (spaces preserved)
    };

    const handleTaxNumberChange = async (event, form) => {
        const value = event.target.value;
        form.change("tax_number", value);
        if (value.length === 13) {
            setIsFetching(true);
            try {
                console.log(value)
                const response = await fetchDataFromUJP(value);
                if (response.error) {
                    console.log("Error fetching company data: ", response.error);
                } else {
                    console.log(response)
                    const { name, address, city, phone } = response.data.data;
                    form.change("company", name || "");
                    form.change("address1", address || "");
                    form.change("city", city || "");
                    form.change("phone", phone || "");
                }
            } catch (error) {
                console.log("Error fetching company data: ", error);
            } finally {
                setIsFetching(false);
            }
        }
    };

    const handleCountryChangeAdministrative = (selectedOption) => {
        setSelectedAdministrativeCountry(selectedOption);
        setShouldValidateMacedonianAdmin(selectedOption.label === "Macedonia");
    };

    const handleCountryChangeTechnical = (selectedOption) => {
        setSelectedTechnicalCountry(selectedOption);
        setShouldValidateMacedonianTechnical(selectedOption.label === "Macedonia");
    };

    const handleDnsOptionChange = (optionId) => {
        setSelectedOption(optionId);

        // If switching away from custom DNS, clear saved DNS servers
        if (optionId !== "customDnsServers") {
            setFormData((prevData) => ({
                ...prevData,
                option3: {
                    selected_dns_option: optionId,
                    dns_servers: [], // Reset custom DNS data
                },
            }));
        }
    };

    const closeAddInfoModal = () => {
        setShowAddInfoModal(false);
        setActiveTab("option1");
        setFormData(prevData => ({
            ...prevData,
            option1: {
                contact_type: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                company: "",
                tax_number: "",
                street_number: "",
                address: "",
                city: "",
                zip: "",
                country: "",
            },
        }));
        setSelectedProduct(null);
    };


    return (
        <Modal
            isOpen={showAddInfoModal}
            onCloseModal={() => closeAddInfoModal()}
            title={`${translate("cart.wizard.account-info-modal.domain-registration-information")} - ${selectedProduct ? selectedProduct.domain : ''}`}
            size="xxl"
            // Pass in a custom header that renders the tab buttons
            tabButtons={true}
            underHeader={() => (
                <TabContainer>
                    <TabButtons>
                        <TabButton
                            active={activeTab === "option1"}
                            disabled={false}
                            onClick={() => setActiveTab("option1")}
                        >
                            {translate("cart.wizard.account-info-modal.registrant")}
                        </TabButton>
                        <TabButton
                            active={activeTab === "option2"}
                            disabled={maxUnlockedTab < 2}
                            onClick={() => maxUnlockedTab >= 2 && setActiveTab("option2")}
                        >
                            {translate("cart.wizard.account-info-modal.contact-data")}
                        </TabButton>
                        <TabButton
                            active={activeTab === "option3"}
                            disabled={maxUnlockedTab < 3}
                            onClick={() => maxUnlockedTab >= 3 && setActiveTab("option3")}
                        >
                            {translate("cart.wizard.account-info-modal.dns-servers")}
                        </TabButton>
                        {formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                            <TabButton
                                active={activeTab === "option4"}
                                disabled={maxUnlockedTab < 4}
                                onClick={() => maxUnlockedTab >= 4 && setActiveTab("option4")}
                            >
                                {translate("cart.wizard.account-info-modal.documents-for-acceptance")}
                            </TabButton>
                        )}
                    </TabButtons>
                </TabContainer>
            )}
            body={() => (
                <>
                    {activeTab === "option1" && (

                        <TabContainer>
                            <TabContent>

                                <StyledDescription>
                                    {translate("cart.wizard.account-info-modal.entity-type-for-domain-registration")}
                                </StyledDescription>
                                <Form
                                    onSubmit={handleFormSubmit}
                                    validate={validateAccountDetails}
                                    initialValues={{
                                        country: selectedCountry,
                                        contact_type: formData.option1.contact_type || CONTACT_DETAILS_TYPE_INDIVIDUAL,
                                        first_name: formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                                            ? formData.option1.first_name || ""
                                            : "", // Reset if not Individual
                                        last_name: formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
                                            ? formData.option1.last_name || ""
                                            : "", // Reset if not Individual
                                        email: formData.option1.email || "",
                                        phone: formData.option1.phone || "",
                                        company: formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY
                                            ? formData.option1.company || ""
                                            : "", // Reset if not Company
                                        tax_number: formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY
                                            ? formData.option1.tax_number || ""
                                            : "", // Reset if not Company
                                        address1: formData.option1.address || "",
                                        street_number: formData.option1.street_number || "",
                                        city: formData.option1.city || "",
                                        zip: formData.option1.zip || "",
                                    }}
                                    render={({ handleSubmit, values, submitting, invalid, form }) => {

                                        const handleResetField = (fieldName1, fieldName2) => {
                                            form.change(fieldName1, "");
                                            form.change(fieldName2, "");
                                        };
                                        const restrictionMessage = getRestrictionMessage(selectedProduct.domain, values.contact_type, values.country && values.country.label);
                                        return (
                                            <form
                                                id="domain-contact-details-form"
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="row">
                                                    <div className="col-xl-12 col-sm-12 col-md-12">

                                                        <div className="row mb-2">
                                                            <div className="col-xl-3 col-md-6 mb-2" onClick={() => handleResetField(CONTACT_DETAILS_TYPE_COMPANY, "tax_number")}>
                                                                <Field
                                                                    component={BlockRadioboxNew}
                                                                    activeIcon={iconPersonInactive}
                                                                    defaultIcon={iconPersonInactive}
                                                                    name="contact_type"
                                                                    value={CONTACT_DETAILS_TYPE_INDIVIDUAL}
                                                                    type="radio"
                                                                    defaultValue={CONTACT_DETAILS_TYPE_INDIVIDUAL}
                                                                >
                                                                    {translate("cart.wizard.individual")}
                                                                </Field>
                                                            </div>
                                                            <div className="col-xl-3 col-md-6">
                                                                <Field
                                                                    component={BlockRadioboxNew}
                                                                    activeIcon={iconBusinessActive}
                                                                    defaultIcon={iconBusinessInactive}
                                                                    name="contact_type"
                                                                    value={CONTACT_DETAILS_TYPE_COMPANY}
                                                                    type="radio"
                                                                >
                                                                    {translate("cart.wizard.company")}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                        {values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6 col-md-6">
                                                                    <Field
                                                                        component={InputField}
                                                                        label={translate("cart.wizard.first-name")}
                                                                        name="first_name"
                                                                        readOnly={disabledFields && !!disabledFields.first_name}
                                                                        required
                                                                        validate={
                                                                            shouldValidateMacedonian
                                                                                ? (value) => {
                                                                                    if (!value) {
                                                                                        return translate("validation.enter-tax-number");
                                                                                    }
                                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                    }
                                                                                }
                                                                                : undefined
                                                                        }
                                                                        parse={(value) => {
                                                                            if (!value) return "";

                                                                            let processedValue = value;

                                                                            if (shouldValidateMacedonian) {
                                                                                processedValue = transliterateToCyrilic(processedValue);
                                                                            }

                                                                            return capitalizeFirstLetter(processedValue);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-md-6">
                                                                    <Field
                                                                        component={InputField}
                                                                        label={translate("cart.wizard.last-name")}
                                                                        name="last_name"
                                                                        readOnly={disabledFields && !!disabledFields.last_name}
                                                                        required
                                                                        validate={
                                                                            shouldValidateMacedonian
                                                                                ? (value) => {
                                                                                    if (!value) {
                                                                                        return translate("validation.enter-tax-number");
                                                                                    }
                                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                    }
                                                                                }
                                                                                : undefined
                                                                        }
                                                                        parse={(value) => {
                                                                            if (!value) return "";

                                                                            let processedValue = value;

                                                                            if (shouldValidateMacedonian) {
                                                                                processedValue = transliterateToCyrilic(processedValue);
                                                                            }

                                                                            return capitalizeFirstLetter(processedValue);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {values.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <Field
                                                                        component={InputField}
                                                                        label={translate("cart.wizard.tax-number")}
                                                                        name="tax_number"
                                                                        validate={
                                                                            shouldValidateMacedonian
                                                                                ? (value) => {
                                                                                    if (!value) {
                                                                                        return translate("validation.enter-tax-number");
                                                                                    }
                                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                    }
                                                                                }
                                                                                : undefined
                                                                        }
                                                                        onChange={(event) => handleTaxNumberChange(event, form)}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="col-12">
                                                                    <Field
                                                                        component={InputField}
                                                                        label={translate("cart.wizard.company")}
                                                                        name="company"
                                                                        validate={
                                                                            shouldValidateMacedonian
                                                                                ? (value) => {
                                                                                    if (!value) {
                                                                                        return translate("validation.enter-company");
                                                                                    }
                                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                    }
                                                                                }
                                                                                : undefined
                                                                        }
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-md-6">
                                                                <PhoneNumberInput
                                                                    name="phone"
                                                                    label={translate("domain.domain-contacts.phone-number")}
                                                                    defaultCountry={"MK"}
                                                                    translate={translate}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-md-6">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("cart.wizard.email")}
                                                                    name="email"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xl-9 col-md-12">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("cart.wizard.address")}
                                                                    name="address1"
                                                                    required
                                                                    validate={
                                                                        shouldValidateMacedonian
                                                                            ? (value) => {
                                                                                if (!value) {
                                                                                    return translate("validation.enter-tax-number");
                                                                                }
                                                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                }
                                                                            }
                                                                            : undefined
                                                                    }
                                                                    parse={(value) => {
                                                                        if (!value) return "";

                                                                        let processedValue = value;

                                                                        if (shouldValidateMacedonian) {
                                                                            processedValue = transliterateToCyrilic(processedValue);
                                                                        }

                                                                        return capitalizeFirstLetter(processedValue);
                                                                    }} />
                                                            </div>
                                                            <div className="col-xl-3 col-md-12">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("domain.domain-contacts.appartment-number")}
                                                                    name="street_number"
                                                                    required
                                                                    validate={
                                                                        shouldValidateMacedonian
                                                                            ? (value) => {
                                                                                if (!value) {
                                                                                    return translate("validation.enter-tax-number");
                                                                                }
                                                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                }
                                                                            }
                                                                            : undefined
                                                                    }
                                                                    parse={(value) => {
                                                                        if (!value) return "";

                                                                        let processedValue = value;

                                                                        if (shouldValidateMacedonian) {
                                                                            processedValue = transliterateToCyrilic(processedValue);
                                                                        }

                                                                        return capitalizeFirstLetter(processedValue);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-3 col-md-12">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("cart.wizard.city")}
                                                                    name="city"
                                                                    required
                                                                    validate={
                                                                        shouldValidateMacedonian
                                                                            ? (value) => {
                                                                                if (!value) {
                                                                                    return translate("validation.enter-tax-number");
                                                                                }
                                                                                if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                                    return translate("domain.domain-contacts.must-be-cyrillic");
                                                                                }
                                                                            }
                                                                            : undefined
                                                                    }
                                                                    parse={(value) => {
                                                                        if (!value) return "";

                                                                        let processedValue = value;

                                                                        if (shouldValidateMacedonian) {
                                                                            processedValue = transliterateToCyrilic(processedValue);
                                                                        }

                                                                        return capitalizeFirstLetter(processedValue);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-xl-3 col-md-12">
                                                                <Field
                                                                    component={InputField}
                                                                    label={translate("cart.wizard.zip-code")}
                                                                    name="zip"
                                                                    required
                                                                    validate={
                                                                        shouldValidateMacedonian
                                                                            ? (value) => {
                                                                                if (!value) {
                                                                                    return translate("validation.enter-tax-number");
                                                                                }
                                                                            }
                                                                            : undefined
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="col-xl-6 col-md-12">
                                                                <Field
                                                                    component={SelectBox}
                                                                    label={translate("domain.domain-contacts.country")}
                                                                    options={countries}
                                                                    isSearchable={true}
                                                                    name="country"
                                                                    required
                                                                    onChange={(selectedOption) => handleCountryChange(selectedOption, form)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {shouldValidateMacedonian && (
                                                                    <StyledCyrillic>
                                                                        {translate("domain.domain-contacts.must-be-cyrillic")}
                                                                    </StyledCyrillic>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {restrictionMessage && (
                                                            <div className="row">
                                                                <div className="col-12 mt-3">
                                                                    <StyledRow>
                                                                        <Icon size="large" icon="warning" color="#fe9b34" />
                                                                        <StyledErrorText>{restrictionMessage}</StyledErrorText>
                                                                    </StyledRow>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                />
                            </TabContent>
                        </TabContainer>
                    )}
                    {activeTab === "option2" && (
                        <>
                            <StyledRow>
                                <Icon size="large" icon="warning" color="#fe9b34" />
                                <StyledErrorText>{translate("cart.wizard.account-info-modal.info-header")}</StyledErrorText>
                            </StyledRow>
                            <TabContainer>
                                <TabContent>
                                    <Form
                                        onSubmit={(values) => {
                                            console.log("Submitting values:", values); // Debugging line
                                            handleContactSubmit(values);
                                            setMaxUnlockedTab((prev) => Math.max(prev, 3));
                                            setActiveTab("option3");
                                        }}
                                        validate={validateContactDetails}
                                        initialValues={formData.option2}
                                        render={({ handleSubmit, values, form }) => (
                                            <form id="contact-details-form" onSubmit={handleSubmit}>
                                                {/* Administrative Contact */}
                                                <StyledContactHeader>
                                                    <div className="col-12 p-0 m-0 d-flex align-items-center">
                                                        <StyledContactTitle>{translate("cart.wizard.account-info-modal.administrative-contact")}</StyledContactTitle>
                                                        <StyledCustomTooltip>
                                                            <CustomTooltip content={translate("cart.wizard.account-info-modal.administrative-contact-tooltip")}>
                                                                <Icon size="l" icon="info" />
                                                            </CustomTooltip>
                                                        </StyledCustomTooltip>
                                                    </div>
                                                    {formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                                        <div className="ml-auto">
                                                            <SecondaryButton
                                                                style={{ padding: "5px 15px", fontSize: "14px" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // Prevent form submission
                                                                    form.batch(() => {
                                                                        form.change("administrative_contact.name", formData.option1.first_name || "");
                                                                        form.change("administrative_contact.surname", formData.option1.last_name || "");
                                                                        form.change("administrative_contact.email", formData.option1.email || "");
                                                                        form.change("administrative_contact.phone", formData.option1.phone || "");
                                                                        form.change("administrative_contact.country", formData.option1.country || "");
                                                                    });
                                                                }}
                                                            >
                                                                {translate("cart.wizard.account-info-modal.copy-from-registrant")}
                                                            </SecondaryButton>
                                                        </div>
                                                    )}
                                                </StyledContactHeader>

                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field
                                                            component={SelectBox}
                                                            label={translate("domain.domain-contacts.country")}
                                                            options={countries}
                                                            isSearchable={true}
                                                            name="administrative_contact.country"
                                                            required
                                                            onChange={handleCountryChangeAdministrative}
                                                            value={selectedAdministrativeCountry}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field name="administrative_contact.name" component={InputField} label={translate("cart.wizard.first-name")} required validate={
                                                            shouldValidateMacedonianAdmin
                                                                ? (value) => {
                                                                    if (!value) {
                                                                        return translate("validation.enter-first-name");
                                                                    }
                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                    }
                                                                }
                                                                : undefined
                                                        }
                                                            parse={(value) => {
                                                                if (!value) return "";

                                                                let processedValue = value;

                                                                if (shouldValidateMacedonianAdmin) {
                                                                    processedValue = transliterateToCyrilic(processedValue);
                                                                }

                                                                return capitalizeFirstLetter(processedValue);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <Field name="administrative_contact.surname" component={InputField} label={translate("cart.wizard.last-name")} required
                                                            validate={
                                                                shouldValidateMacedonianAdmin
                                                                    ? (value) => {
                                                                        if (!value) {
                                                                            return translate("validation.enter-last-name");
                                                                        }
                                                                        if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                            return translate("domain.domain-contacts.must-be-cyrillic");
                                                                        }
                                                                    }
                                                                    : undefined
                                                            }
                                                            parse={(value) => {
                                                                if (!value) return "";

                                                                let processedValue = value;

                                                                if (shouldValidateMacedonianAdmin) {
                                                                    processedValue = transliterateToCyrilic(processedValue);
                                                                }

                                                                return capitalizeFirstLetter(processedValue);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field name="administrative_contact.phone" component={InputField} label={translate("cart.wizard.phone-number")} required
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <Field
                                                            name="administrative_contact.email"
                                                            component={InputField}
                                                            label={translate("cart.wizard.email")}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                {/* Technical Contact */}
                                                <StyledContactHeader>
                                                    <div className="col-12 p-0 m-0 d-flex align-items-center">
                                                        <StyledContactTitle>
                                                            {translate("cart.wizard.account-info-modal.technical-contact")}
                                                        </StyledContactTitle>
                                                        <StyledCustomTooltip>
                                                            <CustomTooltip content={translate("cart.wizard.account-info-modal.technical-contact-tooltip")}>
                                                                <Icon size="l" icon="info" />
                                                            </CustomTooltip>
                                                        </StyledCustomTooltip>

                                                    </div>
                                                    {formData.option1.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL && (
                                                        <div className="ml-auto">
                                                            <SecondaryButton
                                                                style={{ padding: "5px 15px", fontSize: "14px" }}
                                                                onClick={(e) => {
                                                                    e.preventDefault(); // Prevent form submission
                                                                    form.batch(() => {
                                                                        form.change("technical_contact.name", formData.option1.first_name || "");
                                                                        form.change("technical_contact.surname", formData.option1.last_name || "");
                                                                        form.change("technical_contact.email", formData.option1.email || "");
                                                                        form.change("technical_contact.phone", formData.option1.phone || "");
                                                                        form.change("technical_contact.country", formData.option1.country || "");
                                                                    });
                                                                }}
                                                            >
                                                                {translate("cart.wizard.account-info-modal.copy-from-registrant")}
                                                            </SecondaryButton>
                                                        </div>
                                                    )}
                                                    {formData.option1.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                                                        values.administrative_contact &&
                                                        values.administrative_contact.name &&
                                                        values.administrative_contact.surname &&
                                                        values.administrative_contact.email &&
                                                        values.administrative_contact.phone &&
                                                        values.administrative_contact.country && (
                                                            <div className="ml-auto">
                                                                <SecondaryButton
                                                                    style={{ padding: "5px 15px", fontSize: "14px" }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault(); // Prevent form submission

                                                                        form.batch(() => {
                                                                            form.change("technical_contact.name", values.administrative_contact.name || "");
                                                                            form.change("technical_contact.surname", values.administrative_contact.surname || "");
                                                                            form.change("technical_contact.email", values.administrative_contact.email || "");
                                                                            form.change("technical_contact.phone", values.administrative_contact.phone || "");
                                                                            form.change("technical_contact.country", values.administrative_contact.country || "");
                                                                        });
                                                                    }}
                                                                >
                                                                    {translate("cart.wizard.account-info-modal.copy-from-administrative")}
                                                                </SecondaryButton>
                                                            </div>
                                                        )
                                                    )}
                                                </StyledContactHeader>

                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field
                                                            component={SelectBox}
                                                            label={translate("domain.domain-contacts.country")}
                                                            options={countries}
                                                            isSearchable={true}
                                                            name="technical_contact.country"
                                                            required
                                                            onChange={handleCountryChangeTechnical}
                                                            value={selectedTechnicalCountry}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field name="technical_contact.name" required component={InputField} label={translate("cart.wizard.first-name")} validate={
                                                            shouldValidateMacedonianTechnical
                                                                ? (value) => {
                                                                    if (!value) {
                                                                        return translate("validation.enter-first-name");
                                                                    }
                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                    }
                                                                }
                                                                : undefined
                                                        }
                                                            parse={(value) => {
                                                                if (!value) return "";

                                                                let processedValue = value;

                                                                if (shouldValidateMacedonianTechnical) {
                                                                    processedValue = transliterateToCyrilic(processedValue);
                                                                }

                                                                return capitalizeFirstLetter(processedValue);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-6">
                                                        <Field name="technical_contact.surname" required component={InputField} label={translate("cart.wizard.last-name")} validate={
                                                            shouldValidateMacedonianTechnical
                                                                ? (value) => {
                                                                    if (!value) {
                                                                        return translate("validation.enter-last-name");
                                                                    }
                                                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                                                        return translate("domain.domain-contacts.must-be-cyrillic");
                                                                    }
                                                                }
                                                                : undefined
                                                        }
                                                            parse={(value) => {
                                                                if (!value) return "";

                                                                let processedValue = value;

                                                                if (shouldValidateMacedonianTechnical) {
                                                                    processedValue = transliterateToCyrilic(processedValue);
                                                                }

                                                                return capitalizeFirstLetter(processedValue);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Field name="technical_contact.phone" required component={InputField} label={translate("cart.wizard.phone-number")} />
                                                    </div>
                                                    <div className="col-6">
                                                        <Field name="technical_contact.email" required component={InputField} label={translate("cart.wizard.email")} />
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    />
                                </TabContent>
                            </TabContainer>
                        </>
                    )}
                    {activeTab === "option3" && (
                        <TabContainer>
                            <TabContent>
                                <StyledDescription>{translate("cart.wizard.account-info-modal.choose-one-option")}</StyledDescription>
                                {[
                                    {
                                        id: "hostedInMkhost",
                                        label: translate("cart.wizard.account-info-modal.option3.label-1"),
                                        description: translate("cart.wizard.account-info-modal.option3.description-1"),
                                        tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-1")
                                    },
                                    {
                                        id: "customDnsZone",
                                        label: translate("cart.wizard.account-info-modal.option3.label-2"),
                                        description: translate("cart.wizard.account-info-modal.option3.description-2"),
                                        tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-2"),
                                    },
                                    {
                                        id: "customDnsServers",
                                        label: translate("cart.wizard.account-info-modal.option3.label-3"),
                                        description: translate("cart.wizard.account-info-modal.option3.description-3"),
                                        tooltip: translate("cart.wizard.account-info-modal.option3.tooltip-3"),
                                    },
                                ].map((option) => (
                                    <div key={option.id}>
                                        <Radiobox
                                            name="dnsOptions"
                                            value={option.id}
                                            onChange={() => handleDnsOptionChange(option.id)}
                                            checked={selectedOption === option.id}
                                            className="py-2"
                                        >
                                            {option.label}
                                        </Radiobox>
                                        {selectedOption === option.id && (
                                            <div className="ml-4 mt-0">
                                                {option.id === "hostedInMkhost" && (
                                                    <div>
                                                        <StledDnsDescription>
                                                            {translate("cart.wizard.account-info-modal.option3.ns-label")} 1: <StledDnsDescriptionBold>ns.mkhost.mk</StledDnsDescriptionBold>
                                                        </StledDnsDescription>
                                                        <StledDnsDescription>
                                                            {translate("cart.wizard.account-info-modal.option3.ns-label")} 2: <StledDnsDescriptionBold>ns.mkhost.org</StledDnsDescriptionBold>
                                                        </StledDnsDescription>
                                                    </div>
                                                )}
                                                {option.id === "customDnsZone" && (
                                                    <div>
                                                        <StledDnsDescription>
                                                            {translate("cart.wizard.account-info-modal.option3.ns-label")} 1: <StledDnsDescriptionBold>dns1.mk-host.mk</StledDnsDescriptionBold>
                                                        </StledDnsDescription>
                                                        <StledDnsDescription>
                                                            {translate("cart.wizard.account-info-modal.option3.ns-label")} 2: <StledDnsDescriptionBold>dns2.mk-host.mk</StledDnsDescriptionBold>
                                                        </StledDnsDescription>
                                                    </div>
                                                )}
                                                {option.id === "customDnsServers" && (
                                                    <div>
                                                        {hosts.map((host, index) => (
                                                            <div className="row" key={host.id}>
                                                                <div className="col-xl-5">
                                                                    <InputField
                                                                        label={`${translate("cart.wizard.account-info-modal.option3.ns-name-of-host")} ${index + 1}`}
                                                                        placeholder={translate("cart.wizard.account-info-modal.option3.ns-name-of-host")}
                                                                        name={`dnsServer${index + 1}`}
                                                                        className="w-100"
                                                                        value={host.value}
                                                                        onChange={(e) => updateHost(host.id, "value", e.target.value)}
                                                                    />
                                                                </div>
                                                                {doesHostnameMatchDomain(host.value, domainName) && (
                                                                    <div className="col-xl-5">
                                                                        <InputField
                                                                            label={`${translate("cart.wizard.account-info-modal.option3.address-label")} ${index + 1}`}
                                                                            placeholder={translate("cart.wizard.account-info-modal.option3.address-placeholder")}
                                                                            name={`dnsAddress${index + 1}`}
                                                                            className="w-100"
                                                                            value={host.address}
                                                                            onChange={(e) => updateHost(host.id, "address", e.target.value)}
                                                                            required={host.isIpRequired} // Enforce required field
                                                                        />
                                                                    </div>
                                                                )}
                                                                {!host.fixed && (
                                                                    <div className="col-xl-2 mt-4">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() => removeHost(host.id)}
                                                                            className="btn btn-danger"
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}

                                                        {hosts.length < maxHosts && (
                                                            <div className="text-right mt-3">
                                                                <PrimaryButton onClick={addHost}>+ {translate("cart.wizard.account-info-modal.option3.add-more-ns")}</PrimaryButton>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </TabContent>
                        </TabContainer>
                    )}
                    {activeTab === "option4" && (
                        <TabContainer>
                            <TabContent>
                                <StyledRow>
                                    <Icon size="large" icon="warning" color="#fe9b34" />
                                    <StyledSmallText>
                                        {translate("cart.wizard.account-info-modal.option4.you-must-uplaod-documents")}
                                    </StyledSmallText>
                                </StyledRow>

                                <StyledTitle>{translate("cart.wizard.account-info-modal.option4.upload-documents")}</StyledTitle>
                                <StyledUnderTitle>
                                    {translate("cart.wizard.account-info-modal.option4.documents-description")}
                                </StyledUnderTitle>
                                <StyledBorder className="mt-4 mb-4" />

                                <StyledUnderTitle>{translate("cart.wizard.account-info-modal.option4.choose-document-sending-method")}</StyledUnderTitle>

                                <Radiobox
                                    name="currentStatusOption"
                                    value="uploadNow"
                                    onChange={() => setCurrentStatusOption("uploadNow")}
                                    checked={currentStatusOption === "uploadNow"}
                                >
                                    {translate("cart.wizard.account-info-modal.option4.add-documents-now")}
                                </Radiobox>

                                {currentStatusOption === "uploadNow" && (
                                    <div className="mb-3">
                                        <StyledUnderTitle>{translate("cart.wizard.account-info-modal.option4.upload-document-label")}:</StyledUnderTitle>

                                        <div style={{ width: "0px", height: "0px", overflow: "hidden" }}>
                                            <input
                                                type="file"
                                                id="fileUpload"
                                                accept=".pdf"
                                                onChange={handleFileUpload}
                                            />
                                        </div>
                                        <StyledRow>
                                            <SecondaryButton onClick={() => document.getElementById("fileUpload").click()}>
                                                <img src={iconUpload} alt="" className="mr-2" />
                                                {translate("cart.wizard.account-info-modal.option4.upload-document-button")}
                                            </SecondaryButton>
                                        </StyledRow>
                                        {uploadedFile && (
                                            <StyledUnderTitle>{translate("cart.wizard.account-info-modal.option4.selected-document")}: {uploadedFile.name}</StyledUnderTitle>
                                        )}
                                        {fileError && (
                                            <StyledSmallText style={{ color: "red", marginTop: "5px" }}>
                                                {fileError}
                                            </StyledSmallText>
                                        )}
                                        <div className="mt-3">
                                            <InputField
                                                label={translate("cart.wizard.account-info-modal.option4.date-of-registration-certificate")}
                                                type="date"
                                                name="registration_certificate_date"
                                                value={formData.option4.registration_certificate_date || ""}
                                                onChange={(e) => {
                                                    const selectedDate = e.target.value;
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        option4: {
                                                            ...prevData.option4,
                                                            registration_certificate_date: selectedDate,
                                                        },
                                                    }));
                                                    validateRegistrationDate(selectedDate);
                                                }}
                                                required
                                            />
                                            {dateError && (
                                                <StyledSmallText style={{ color: "red", marginTop: "5px" }}>
                                                    {dateError}
                                                </StyledSmallText>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <Radiobox
                                    name="currentStatusOption"
                                    value="mkHostFetch"
                                    onChange={() => setCurrentStatusOption("mkHostFetch")}
                                    checked={currentStatusOption === "mkHostFetch"}
                                >
                                    {translate("cart.wizard.account-info-modal.option4.let-mkhost-get-it")}
                                </Radiobox>
                            </TabContent>
                        </TabContainer>
                    )}


                    <StyledFooter className="d-flex justify-content-end w-100">
                        <SecondaryButton
                            className={isMobileOnly ? 'w-50 ' : ''}
                            style={{ marginRight: '15px' }}
                            onClick={() => {
                                if (activeTab === "option1") {
                                    setShowAddInfoModal(false);
                                } else {
                                    setActiveTab((prev) => {
                                        if (prev === "option2") return "option1";
                                        if (prev === "option3") return "option2";
                                        if (prev === "option4") return "option3";
                                        return prev;
                                    });
                                }
                            }}
                        >
                            {activeTab === "option1"
                                ? translate("cart.wizard.back-to-cart")
                                : translate("cart.wizard.go-back")}
                        </SecondaryButton>

                        <PrimaryButton
                            className={isMobileOnly ? 'w-50 ' : ''}
                            onClick={handleModalPrimaryClick}
                            disabled={!!restrictionMessageCheck && !isSubmitting}
                            submitting={isSubmitting}
                        >
                            {getPrimaryButtonLabel()}
                        </PrimaryButton>
                    </StyledFooter>
                    <div className="col-12 text-right">
                        <StyledHelp>{translate("cart.wizard.contact-us")}</StyledHelp>
                    </div>

                </>
            )}
        />
    )
}

const mapStateToProps = state => {
    return {
        countries: getCountriesFormatted(state),
    };
};

export default connect(
    mapStateToProps)(withRouter(withLocalize(DomainContactNew)));

