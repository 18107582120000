import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import { debounce } from "throttle-debounce";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";

import Tabs from "../../components/Tabs";
import Tab from "../../components/Tabs/Tab";
import TabContent from "../../components/Tabs/TabContent";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Modal from "../../components/Modal";
import ActionModal from "../../components/ActionModal";

import GeneralInfo from "./GeneralInfo";
import UpdateGeneralInfo from "./UpdateGeneralInfo";
import Security from "./Security";
//import Products from "./Products";
import EmailsAndNotifications from "./EmailsAndNotifications";
import Apps from "./Apps";

import { fetchCredit, purchaseCredit } from "../Credit/actions/creditActions";
import {
  getAvailableCredit,
  isPurchasingCredit
} from "../Credit/reducers/creditReducer";
import {
  getCustomerPersonalInformation,
  isUpdatingCustomerPersonalInformation,
  getCustomerEmails,
  isFetchingEmails
} from "../Customer/reducers/customerReducer";

import {
  updateCustomerPersonalInformation,
  fetchCustomerEmails
} from "../Customer/actions/customerActions";

import {
  fetchSecurityQuestions,
  updateSecurityQuestion,
  changeCustomerPassword
} from "../Security/actions/securityActions";
import {
  getSecurityQuestions,
  isFetchingSecurityQuestions,
  isUpdatingSecurityQuestion,
  isChangingPassword
} from "../Security/reducers/securityReducer";

import {
  emailVerificationReducer
} from "../EmailVerification/reducers/emailVerificationReducer.js";


import {
  validateSecurityQuestion,
  validateSecurityAnswer,
  validateSecurityAnswerConfirm,
  validateAddress,
  validatePhoneNumber,
  validateMkPhoneNumber,
  validateCity,
  validateZipCode,
  validatePasswordWithConfirm,
  validatePassword,
  validatePasswordSimple,
  validateRangeAmount,
  validateFirstName,
  validateLastName,
  validateCompany,
  validateTaxNumber,
  validateCountry,
  normalizeMacedonianPhoneNumber
} from "../../common/validationRules";

import {
  getAddFundsMinimum,
  getAddFundsMaximum
} from "../Preferences/reducers/preferenceReducer";

import { getCountriesFormatted } from "../Addressing/reducers/addressingReducer";

import {
  fetchInvoicesSettings,
  updateInvoicesSettings
} from "../Billing/Invoices/actions/invoicesActions";
import {
  isFetchingInvoicesSettings,
  getInvoicesSettings
} from "../Billing/Invoices/reducers/invoicesReducer";

import { fetchSystemPreferences } from "../Preferences/actions/preferenceActions";

import {
  compileRoute,
  ROUTE_INDEX,
  ROUTE_MY_ACCOUNT_GENERAL_INFO,
  ROUTE_MY_ACCOUNT_SECURITY,
  //ROUTE_MY_ACCOUNT_PRODUCTS,
  ROUTE_MY_ACCOUNT_EMAILS,
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_MY_ACCOUNT_EMAIL_VIEW,
  ROUTE_MY_ACCOUNT_APPS
} from "../../routes/routes";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import {
  SORT_ORDER_DESCENDING,
  SORT_ORDER_ASCENDING
} from "../../types/SortTypes";

import {
  CONTACT_DETAILS_TYPE_COMPANY,
  CONTACT_DETAILS_TYPE_INDIVIDUAL
} from "../Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";

class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showUpdateGeneralInfoModal: false,
      submittingUpdateGeneralInfo: false,
      invalidUpdateGeneralInfo: false,
      purchaseCreditValue: 0,
      showPurchaseCreditConfirmationModal: false,
      searchQuery: props.emails.filters.keyword
    };

    this.searchEmailsByKeywordThrottled = debounce(
      1000,
      this.searchEmailsByKeyword
    );

    this.invoiceRoute = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE);
    this.emailRoute = compileRoute(ROUTE_MY_ACCOUNT_EMAIL_VIEW);
  }

  componentDidMount() {
    this.props.fetchSystemPreferences();
    this.props.fetchCredit();
    this.props.fetchSecurityQuestions();

    this.props.fetchCustomerEmails(
      this.props.emails.paging.limit,
      this.props.emails.paging.current_page,
      this.props.emails.sort,
      this.props.emails.filters
    );
    this.props.fetchInvoicesSettings();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.availableCredit) {
      this.setState({
        purchaseCreditValue:
          nextProps.availableCredit.currency.code === "мкд" ? 1000.0 : 15.0
      });
    }
  }

  requestEmailVerification = () => {
    this.setState({
      emailVerificationReducer: true
    });
  };


  onShowUpdateGeneralInfoModal = () => {
    this.setState({
      showUpdateGeneralInfoModal: true
    });
  };

  onHideUpdateGeneralInfoModal = () => {
    this.setState({
      showUpdateGeneralInfoModal: false
    });
  };

  remapSecurityQuestionsForSelect = (value, callback) => {
    const options = this.props.securityQuestions.map(questions => {
      return { label: questions.question, value: questions.id };
    });

    callback(options);
  };

  validateSecurityQuestionSubmit = values => {
    return {
      question: validateSecurityQuestion(values.question, true),
      answer: validateSecurityAnswer(values.answer, true),
      confirmAnswer: validateSecurityAnswerConfirm(
        values.answer,
        values.confirmAnswer,
        true
      )
    };
  };

  onSaveSecurityQuestion = values => {
    this.props
      .updateSecurityQuestion(values.question.value, values.answer)
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("my-account.security.title"),
          response
        );
      });
  };

  validateUpdateGeneralInfoSubmit = values => {
    if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
      return {
        first_name: validateFirstName(values.first_name),
        last_name: validateLastName(values.last_name),
        company: validateCompany(values.company),
        tax_number: validateTaxNumber(values.tax_number),
        address1: validateAddress(values.address1, true),
        // phone: validateMkPhoneNumber(values.phone, true),
        city: validateCity(values.city, true),
        zip: validateZipCode(values.zip, true),
        country: validateCountry(values.country.value)
      };
    }

    return {
      first_name: validateFirstName(values.first_name),
      last_name: validateFirstName(values.last_name),
      address1: validateAddress(values.address1, true),
      // phone: validateMkPhoneNumber(values.phone, true),
      city: validateCity(values.city, true),
      zip: validateZipCode(values.zip, true),
      country: validateCountry(values.country.value)
    };
  };

  onSubmittingUpdateGeneralInfo = state => {
    this.setState({
      submittingUpdateGeneralInfo: state.submitting,
      invalidUpdateGeneralInfo: state.invalid
    });
  };

  onUpdateGeneralInfo = values => {
    return this.props
      .updateCustomerPersonalInformation({
        ...values,
        phone: normalizeMacedonianPhoneNumber(values.phone),
        country_id: values.country.value,
        company:
          values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
            ? ""
            : values.company,
        tax_number:
          values.contact_type === CONTACT_DETAILS_TYPE_INDIVIDUAL
            ? ""
            : values.tax_number
      })
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("my-account.customer"),
          response
        );

        this.setState({
          showUpdateGeneralInfoModal: false
        });
      });
  };

  onTriggerUpdateGeneralInfoFormSubmit = () => {
    const form = document.getElementById("update-general-info-form");
    if (form) {
      const submitEvent = new Event("submit", { bubbles: true, cancelable: true });
      form.dispatchEvent(submitEvent);
      console.log("Submit event dispatched");
    }
  };
  onShowPurchaseCreditConfirmationModal = () => {
    this.setState({
      showPurchaseCreditConfirmationModal: true
    });
  };

  onHidePurchaseCreditConfirmationModal = () => {
    this.setState({
      showPurchaseCreditConfirmationModal: false
    });
  };

  onPurchaseCredit = values => {
    this.setState({ purchaseCreditValue: values.amount });

    this.onShowPurchaseCreditConfirmationModal();
  };

  requestPurchaseCredit = () => {
    this.props.purchaseCredit(this.state.purchaseCreditValue).then(response => {
      if (response.hasOwnProperty("error") && response.error) {
        displayToastMessageForResponse(
          this.props.translate("my-account.general-info.title"),
          response
        );
      } else {
        this.props.history.push(this.invoiceRoute({ id: response }));
      }
    });
  };

  validatePurchaseCredit = values => {
    return {
      amount: validateRangeAmount(
        values.amount,
        this.props.addFundsMinimum,
        this.props.addFundsMaximum
      )
    };
  };

  validateChangePasswordSubmit = values => {
    return {
      password: validatePasswordSimple(values.password),
      newPassword: validatePassword(values.newPassword),
      confirmNewPassword: validatePasswordWithConfirm(
        values.newPassword,
        values.confirmNewPassword
      )
    };
  };

  onChangePassword = values => {
    this.props
      .changeCustomerPassword(
        values.password,
        values.newPassword,
        values.confirmNewPassword
      )
      .then(response =>
        displayToastMessageForResponse(
          this.props.translate("my-account.security.title"),
          response
        )
      );
  };

  handleEmailsPaginationClick = (event, page) => {
    event.preventDefault();

    if (page <= 0 || page > this.props.emails.paging.total_pages) {
      return;
    }

    this.props.fetchCustomerEmails(
      this.props.emails.paging.limit,
      page,
      this.props.emails.sort,
      this.props.emails.filters
    );
  };

  onEmailsPerPageOptionChanged = value => {
    this.props.fetchCustomerEmails(
      value.value,
      1,
      this.props.emails.sort,
      this.props.emails.filters
    );
  };

  changeEmailsListOrderTrigger = (sortBy, orderBy) => {
    this.props.fetchCustomerEmails(
      this.props.emails.paging.limit,
      this.props.emails.paging.current_page,
      {
        sort_by: sortBy,
        order_by:
          orderBy === SORT_ORDER_DESCENDING
            ? SORT_ORDER_ASCENDING
            : SORT_ORDER_DESCENDING
      },
      this.props.emails.filters
    );
  };

  onEmailsChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchEmailsByKeywordThrottled(this.state.searchQuery);
    });
  };

  searchEmailsByKeyword = value => {
    this.props.fetchCustomerEmails(
      this.props.emails.paging.limit,
      1,
      this.props.emails.sort,
      {
        ...this.props.emails.filters,
        keyword: value
      }
    );
  };

  onToggleDigitalInvoices = () => {
    return this.props
      .updateInvoicesSettings(
        !this.props.invoicesSettings.digitally_signed_invoices
      )
      .then(result => {
        console.log(result);
      });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Breadcrumb>
                <BreadcrumbItem
                  to={ROUTE_INDEX}
                  title={translate("breadcrumbs.home")}
                />
                <BreadcrumbItem title={translate("breadcrumbs.my-account")} />
              </Breadcrumb>

              <Tabs
                defaultTab="general-info"
                renderTabs={(active, onSelect) => [
                  <Tab
                    key="tab-general-info"
                    label="general-info"
                    active={active}
                    onSelect={onSelect}
                    linkTo={ROUTE_MY_ACCOUNT_GENERAL_INFO}
                  >
                    {translate("my-account.general-info.title")}
                  </Tab>,
                  <Tab
                    key="tab-security"
                    label="security"
                    active={active}
                    onSelect={onSelect}
                    linkTo={ROUTE_MY_ACCOUNT_SECURITY}
                  >
                    {translate("my-account.security.title")}
                  </Tab>,
                  <Tab
                    key="tab-email"
                    label="email"
                    active={active}
                    onSelect={onSelect}
                    linkTo={ROUTE_MY_ACCOUNT_EMAILS}
                  >
                    {translate("my-account.emails-and-notifications.title")}
                  </Tab>,
                  <Tab
                    key="tab-apps"
                    label="apps"
                    active={active}
                    onSelect={onSelect}
                    linkTo={ROUTE_MY_ACCOUNT_APPS}
                  >
                    {translate("my-account.apps.title")}
                  </Tab>
                ]}
                renderTabContent={active => (
                  <Switch>
                    <Route
                      path={ROUTE_MY_ACCOUNT_GENERAL_INFO}
                      exact
                      render={() => (
                        <TabContent
                          key="tab-content-general-info"
                          label="general-info"
                          active={true}
                        >
                          <GeneralInfo
                            availableCredit={this.props.availableCredit}
                            customerPersonalInformation={
                              this.props.customerPersonalInformation
                            }
                            onShowUpdateGeneralInfoModal={
                              this.onShowUpdateGeneralInfoModal
                            }
                            onPurchaseCredit={this.onPurchaseCredit}
                            addFundsMinimum={this.props.addFundsMinimum}
                            addFundsMaximum={this.props.addFundsMaximum}
                            validatePurchaseCredit={this.validatePurchaseCredit}
                            isFetchingInvoicesSettings={
                              this.props.isFetchingInvoicesSettings
                            }
                            invoicesSettings={this.props.invoicesSettings}
                            onToggleDigitalInvoices={
                              this.onToggleDigitalInvoices
                            }
                          />
                        </TabContent>
                      )}
                    />

                    <Route
                      path={ROUTE_MY_ACCOUNT_SECURITY}
                      exact
                      render={() => (
                        <TabContent
                          key="tab-security"
                          label="security"
                          active={true}
                        >
                          <Security
                            securityQuestionOptions={
                              this.remapSecurityQuestionsForSelect
                            }
                            isFetchingSecurityQuestions={
                              this.props.isFetchingSecurityQuestions
                            }
                            validateSecurityQuestionSubmit={
                              this.validateSecurityQuestionSubmit
                            }
                            onSaveSecurityQuestion={this.onSaveSecurityQuestion}
                            isUpdatingSecurityQuestion={
                              this.props.isUpdatingSecurityQuestion
                            }
                            validateChangePasswordSubmit={
                              this.validateChangePasswordSubmit
                            }
                            onChangePassword={this.onChangePassword}
                            isChangingPassword={this.props.isChangingPassword}
                          />
                        </TabContent>
                      )}
                    />

                    <Route
                      path={ROUTE_MY_ACCOUNT_EMAILS}
                      exact
                      render={() => (
                        <TabContent key="tab-email" label="email" active={true}>
                          <EmailsAndNotifications
                            handlePaginationClick={
                              this.handleEmailsPaginationClick
                            }
                            onPerPageOptionChanged={
                              this.onEmailsPerPageOptionChanged
                            }
                            changeListOrderTrigger={
                              this.changeEmailsListOrderTrigger
                            }
                            onChangeKeywordSearch={
                              this.onEmailsChangeKeywordSearch
                            }
                            searchQuery={this.state.searchQuery}
                            emails={this.props.emails}
                            isFetchingEmails={this.props.isFetchingEmails}
                            emailRoute={this.emailRoute}
                          />
                        </TabContent>
                      )}
                    />
                    <Route
                      path={ROUTE_MY_ACCOUNT_APPS}
                      exact
                      render={() => (
                        <TabContent key="tab-apps" label="apps" active={true}>
                          {this.props.customerPersonalInformation && (
                            <Apps
                              customer={this.props.customerPersonalInformation}
                            />
                          )}
                        </TabContent>
                      )}
                    />
                  </Switch>
                )}
              />
            </div>
            {this.state.showUpdateGeneralInfoModal && (
              <Modal
                title={translate("my-account.general-info.update-info.title")}
                onCloseModal={this.onHideUpdateGeneralInfoModal}
                body={() => {
                  return (
                    <div>
                      <UpdateGeneralInfo
                        contact={{
                          ...this.props.customerPersonalInformation,
                          contact_type:
                            this.props.customerPersonalInformation.company !==
                              ""
                              ? CONTACT_DETAILS_TYPE_COMPANY
                              : CONTACT_DETAILS_TYPE_INDIVIDUAL,
                          country: this.props.customerPersonalInformation
                            .country.id
                            ? {
                              label: this.props.customerPersonalInformation
                                .country.name,
                              value: this.props.customerPersonalInformation
                                .country.id
                            }
                            : this.props.customerPersonalInformation.country
                        }}
                        validate={this.validateUpdateGeneralInfoSubmit}
                        onSubmitting={this.onSubmittingUpdateGeneralInfo}
                        onSubmit={this.onUpdateGeneralInfo}
                        countries={this.props.countries}
                      />
                    </div>
                  );
                }}
                footer={() => [
                  <PrimaryButton
                    key="update-general-info"
                    disabled={
                      this.state.submittingUpdateGeneralInfo ||
                      this.state.invalidUpdateGeneralInfo ||
                      this.props.isUpdatingCustomerPersonalInformation
                    }
                    submitting={
                      this.state.submittingUpdateGeneralInfo ||
                      this.props.isUpdatingCustomerPersonalInformation
                    }
                    onClick={() => this.onTriggerUpdateGeneralInfoFormSubmit()}
                  >
                    {translate(
                      "my-account.general-info.update-info.save-changes"
                    )}
                  </PrimaryButton>,
                  <SecondaryButton
                    key="cancel-update-general-info"
                    onClick={e => this.onHideUpdateGeneralInfoModal()}
                  >
                    {translate("my-account.general-info.update-info.cancel")}
                  </SecondaryButton>
                ]}
              />
            )}

            {this.state.showPurchaseCreditConfirmationModal && (
              <ActionModal
                title={translate(
                  "my-account.general-info.purchase-credit.are-you-sure"
                )}
                onCloseActionModal={this.onHidePurchaseCreditConfirmationModal}
                body={() => (
                  <div>
                    {translate(
                      "my-account.general-info.purchase-credit.redirect-invoice-info"
                    )}
                  </div>
                )}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton
                      onClick={() =>
                        this.onHidePurchaseCreditConfirmationModal()
                      }
                    >
                      {translate(
                        "my-account.general-info.purchase-credit.cancel"
                      )}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={this.props.isPurchasingCredit}
                      submitting={this.props.isPurchasingCredit}
                      onClick={() => this.requestPurchaseCredit()}
                    >
                      {translate(
                        "my-account.general-info.purchase-credit.purchase"
                      )}
                    </PrimaryButton>
                  </React.Fragment>
                )}
              />
            )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    availableCredit: getAvailableCredit(state),
    customerPersonalInformation: getCustomerPersonalInformation(state),
    securityQuestions: getSecurityQuestions(state),
    isFetchingSecurityQuestions: isFetchingSecurityQuestions(state),
    isUpdatingSecurityQuestion: isUpdatingSecurityQuestion(state),
    isUpdatingCustomerPersonalInformation: isUpdatingCustomerPersonalInformation(
      state
    ),
    isPurchasingCredit: isPurchasingCredit(state),
    isChangingPassword: isChangingPassword(state),
    emails: getCustomerEmails(state),
    isFetchingEmails: isFetchingEmails(state),
    addFundsMinimum: getAddFundsMinimum(state),
    addFundsMaximum: getAddFundsMaximum(state),
    countries: getCountriesFormatted(state),
    isFetchingInvoicesSettings: isFetchingInvoicesSettings(state),
    invoicesSettings: getInvoicesSettings(state)
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCredit,
      fetchSecurityQuestions,
      updateSecurityQuestion,
      updateCustomerPersonalInformation,
      purchaseCredit,
      changeCustomerPassword,
      fetchCustomerEmails,
      fetchInvoicesSettings,
      updateInvoicesSettings,
      fetchSystemPreferences
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(MyAccount));
