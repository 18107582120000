import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobile } from "react-device-detect";
import { withLocalize } from "react-localize-redux";

import Container from "../../../../components/Containers/Container";
import Icon from "../../../../components/Icon";
import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";

const StyledService = styled.div`
  padding: ${props => (props.isMobile ? "30px 20px" : "50px")};
`;

const StyledRow = styled.div`
  margin-bottom: 30px;
`;

const StyledServiceName = styled.h4`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${theme.neutralBase};
`;

const StyledDeleteIcon = styled.span`
  position: absolute;
  right: 0;
  top: ${props => (props.isMobile ? "10px" : "-20px")};
  cursor: pointer;
`;

const ServiceWithoutAddons = ({ product, onRemoveProductFromCart, translate }) => (
    <StyledRow className="row">
        <div className="col-12 col-sm-12 col-md-12">
            <Container>
                <StyledService isMobile={isMobile}>
                    <StyledRow className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <StyledDeleteIcon
                                isMobile={isMobile}
                                onClick={e => onRemoveProductFromCart(product)}
                            >
                                <Icon size="l" icon="delete" />
                            </StyledDeleteIcon>
                            <StyledServiceName>{product.item.name}</StyledServiceName>
                        </div>
                    </StyledRow>
                    <StyledRow className="row">
                        <div className="col-4 col-sm-4 col-md-3">
                            <DiscountedAmountBadge
                                label={translate("cart.item-price")}
                                amount={{
                                    amount: product.total,
                                    currency:
                                        product.item.pricing.buy.billing_cycle.derivations[0].price.currency.code,
                                    discountedAmount:
                                        product.total < product.price ? product.price : null
                                }}
                            />
                        </div>
                    </StyledRow>
                </StyledService>
            </Container>
        </div>
    </StyledRow>
);

export default withLocalize(ServiceWithoutAddons);
