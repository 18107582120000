import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";

import Avatar from "../../../../../components/Avatar";
import BusinessAvatar from "../../../../../components/BusinessAvatar";
import UserWithEmail from "../../../../../components/UserWithEmail";
import DropdownButton from "../../../../../components/DropdownButton";
import DropdownItem from "../../../../../components/DropdownItem";
import Icon from "../../../../../components/Icon";

import { PAYMENT_STATUS_UNPAID } from "../../../../PaymentMethods/PaymentStatus";

// Function to convert phone number
const convertPhoneNumber = (phone) => {
  if (phone && phone.startsWith("+389.")) {
    return phone.replace("+389.", "+389");
  }
  return phone;
};

const StyledInvoiceToTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutral5};
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-left: 0;
`;

const StyledDashedWrapper = styled.div`
  border: 2px dashed ${theme.neutral3};
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;

  & .user-avatar {
    vertical-align: top;
  }
`;

const StyledAddressDetails = styled.div`
  padding: 30px;

  & p {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${theme.neutralBase};
  }
`;

const StyledContactDetails = styled.div`
  padding: 30px;
  padding-top: 0;

  & p {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${theme.neutralBase};
  }
`;

const InvoiceTo = ({
  contact,
  onEditPayer,
  onChangePayer,
  proInvoiceStatus
}) => {
  // Convert the phone number before rendering
  const formattedPhone = convertPhoneNumber(contact.phone);

  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <StyledInvoiceToTitle className="col-12 col-sm-12 col-md-12">
            {translate("pro-invoices.view.invoice-to")}
          </StyledInvoiceToTitle>
          <StyledDashedWrapper className="col-12 col-sm-12 col-md-12">
            {contact.company && contact.company !== "" ? (
              <BusinessAvatar />
            ) : (
              <Avatar />
            )}
            <UserWithEmail
              user={
                contact.company && contact.company !== ""
                  ? contact.company
                  : contact.first_name + " " + contact.last_name
              }
              email={contact.email}
              uppercaseUsername={contact.company && contact.company !== ""}
            />
            {proInvoiceStatus === PAYMENT_STATUS_UNPAID && (
              <DropdownButton
                className="float-right"
                alignMenu={isMobileOnly ? "right" : "left"}
                text={() => <Icon icon="edit" size="s" />}
              >
                <DropdownItem onClick={() => onChangePayer(contact)}>
                  {translate("pro-invoices.view.change-payer")}
                </DropdownItem>
                <DropdownItem onClick={() => onEditPayer(contact)}>
                  {translate("pro-invoices.view.update-payer-info")}
                </DropdownItem>
              </DropdownButton>
            )}
          </StyledDashedWrapper>
          <StyledDashedWrapper className="col-12 col-sm-12 col-md-12">
            <StyledAddressDetails>
              {(!contact.company || contact.company === "") &&
                contact.first_name &&
                contact.first_name !== "" && (
                  <p>
                    {contact.first_name} {contact.last_name}
                  </p>
                )}
              {contact.company &&
                contact.company !== "" && (
                  <p className="text-uppercase">{contact.company}</p>
                )}
              {contact.address1 &&
                contact.address !== "" && <p>{contact.address1}</p>}
              {contact.city &&
                contact.zip && (
                  <p>
                    {contact.zip} {contact.city}
                  </p>
                )}
              {contact.country &&
                contact.country !== "" && (
                  <p>{contact.country.name || contact.country.label}</p>
                )}
              {contact.company === "" && (
                <p className="text-uppercase">{contact.company}</p>
              )}
            </StyledAddressDetails>
            <StyledContactDetails>
              {contact.email && contact.email !== "" && <p>{contact.email}</p>}
              {formattedPhone && formattedPhone !== "" && <p>{formattedPhone}</p>}
            </StyledContactDetails>
          </StyledDashedWrapper>
        </div>
      )}
    </Translate>
  );
};

export default InvoiceTo;