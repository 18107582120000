import React from "react";

import Domain from "../Products/Domain";
import Hosting from "../Products/Hosting";
import Service from "../Products/Service";

import {
  PRODUCT_DOMAIN,
  PRODUCT_PRODUCT,
  HOSTING_SERVICE,
  PRODUCT_DEDICATED_SERVER,
  PRODUCT_CLOUD_SERVER,
  SERVER_SERVICE,
  DOMAIN_SERVICE,
  PRODUCT_DOMAIN_SERVICE,
} from "../Products/ProductTypes";

import DedicatedServer from "../Products/DedicatedServer";
import CloudServer from "../Products/CloudServer";
import ServiceWithoutAddons from "../Products/ServiceWithoutAddons";

const CartProductsList = ({
  products,
  onAttachServiceToProduct,
  onRemoveServiceFromProduct,
  onUpdateProductPeriod,
  onUpdateHostingPeriod,
  onRemoveProductFromCart,
  updatingDomain,
  onChangeDomainEPPCode,
  onUpdateDomainEPPCode,
  updatingEPPCode,
  deletingProductId,
  updatingHostingPeriod,
  updatingHostingPeriodId,
  onShowAssignHostingToDomain,
  onBlockCheckout
}) => (
  <div className="row">
    <div className="col-12 col-sm-12 col-md-12">
      {products
        .filter(product => product.type === PRODUCT_DOMAIN)
        .map(product => (
          <Domain
            product={product}
            key={`cart-domain-${product.id}`}
            onAttachServiceToProduct={onAttachServiceToProduct}
            onRemoveServiceFromProduct={onRemoveServiceFromProduct}
            onRemoveProductFromCart={onRemoveProductFromCart}
            onUpdateProductPeriod={onUpdateProductPeriod}
            updatingDomain={updatingDomain}
            onChangeDomainEPPCode={onChangeDomainEPPCode}
            onUpdateDomainEPPCode={onUpdateDomainEPPCode}
            updatingEPPCode={updatingEPPCode}
            deletingProductId={deletingProductId}
            onBlockCheckout={onBlockCheckout}
          />
        ))}
      {products
        .filter(product => product.type === PRODUCT_PRODUCT)
        .map(product => (
          <Hosting
            product={product}
            key={`cart-domain-${product.id}`}
            onAttachServiceToProduct={onAttachServiceToProduct}
            onRemoveServiceFromProduct={onRemoveServiceFromProduct}
            onRemoveProductFromCart={onRemoveProductFromCart}
            onUpdateHostingPeriod={onUpdateHostingPeriod}
            deletingProductId={deletingProductId}
            updatingHostingPeriod={updatingHostingPeriod}
            updatingHostingPeriodId={updatingHostingPeriodId}
          />
        ))}

      {products
        .filter(product => product.type === PRODUCT_DEDICATED_SERVER)
        .map(product => (
          <DedicatedServer
            product={product}
            key={`cart-dedicated-server-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            onUpdateHostingPeriod={onUpdateHostingPeriod}
            deletingProductId={deletingProductId}
            updatingHostingPeriod={updatingHostingPeriod}
            updatingHostingPeriodId={updatingHostingPeriodId}
            onAttachServiceToProduct={onAttachServiceToProduct}
            onRemoveServiceFromProduct={onRemoveServiceFromProduct}
          />
        ))}

      {products
        .filter(product => product.type === PRODUCT_CLOUD_SERVER)
        .map(product => (
          <CloudServer
            product={product}
            key={`cart-cloud-server-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            onUpdateHostingPeriod={onUpdateHostingPeriod}
            deletingProductId={deletingProductId}
            updatingHostingPeriod={updatingHostingPeriod}
            updatingHostingPeriodId={updatingHostingPeriodId}
            onAttachServiceToProduct={onAttachServiceToProduct}
            onRemoveServiceFromProduct={onRemoveServiceFromProduct}
          />
        ))}

      {products
        .filter(product => product.type === DOMAIN_SERVICE)
        .map(product => (
          <Service
            product={product}
            key={`cart-domain-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            deletingProductId={deletingProductId}
          />
        ))}

      {products
        .filter(product => product.type === HOSTING_SERVICE)
        .map(product => (
          <Service
            product={product}
            key={`cart-domain-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            deletingProductId={deletingProductId}
          />
        ))}

      {products
        .filter(product => product.type === SERVER_SERVICE)
        .map(product => (
          <Service
            product={product}
            key={`cart-domain-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            deletingProductId={deletingProductId}
          />
        ))}
      {products
        .filter(product => product.type === PRODUCT_DOMAIN_SERVICE)
        .map(product => (
          <ServiceWithoutAddons
            product={product}
            key={`cart-service-${product.id}`}
            onRemoveProductFromCart={onRemoveProductFromCart}
            deletingProductId={deletingProductId}
          />
        ))}
    </div>
  </div>
);

export default CartProductsList;
