import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FORM_ERROR } from "final-form";
import {
  BrowserView,
  MobileView,
  isMobile,
  isMobileOnly,
  isIE,
} from "react-device-detect";
import { withLocalize } from "react-localize-redux";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"; // Add this import
import TwoFactorAuthentication from "../TwoFactorAuthentication";

import styled from "styled-components";
import { theme } from "../../../design/Themes";

import { login } from "../actions/authActions";
import { fetchCart } from "../../Cart/actions/cartActions";
import { fetchCustomerPersonalInformation } from "../../Customer/actions/customerActions";

import {
  validateEmail,
  validateLoginPassword,
} from "../../../common/validationRules";

import whiteLogo from "../../../assets/images/white-logo.svg";
import {
  setApplicationLocale,
  getApplicationLocale,
} from "../../../services/localizationService";
import { ROUTE_WEBSITE, ROUTE_LOGIN } from "../../../routes/routes";
import CookieBanner from "../../../components/CookieBanner";
import LoginForm from "./LoginForm";

// Styled components remain the same
const StyledLogoContainer = styled.div`
  background-color: ${theme.neutralBase};
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledMobileLogoContainer = styled(StyledLogoContainer)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1031;
  border-radius: 0;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const StyledLoginFormContainer = styled.div`
  margin-top: ${props => (props.isMobile ? "150px" : "80px")};
  margin-bottom: 30px;
`;

const StyledLoginFormWrapper = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledLogo = styled.img`
  position: ${props => (props.isIE ? "absolute" : "relative")};
  top: 45%;
  left: ${props => props.isIE && "25%"};
  width: ${props => props.isMobile && "55%"};
`;

const validateLogin = values => {
  return {
    email: validateEmail(values.email),
    password: validateLoginPassword(values.password),
  };
};

const Login = ({ history, location, setActiveLanguage }) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha(); // Use the hook
  const {
    isAuthenticated,
    isPhoneNumberRequired,
    isTwoFactorEnabled,
    twoFactorPhoneNumber,
  } = useSelector(state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isPhoneNumberRequired: state.auth.isPhoneNumberRequired,
    isTwoFactorEnabled: state.auth.isTwoFactorEnabled,
    twoFactorPhoneNumber: state.auth.twoFactorPhoneNumber,
  }));

  const [state, setState] = useState({
    currentLocale: getApplicationLocale(),
    showTwoStepVerification: false,
    twoStepVerificationCompleted: false,
    twoFactorEnabled: false,
    authToken: null,
    setup2FA: false,
  });

  const onLogin = useCallback(
    async values => {
      let redirectBack = null;

      try {
        redirectBack = sessionStorage.getItem("redirect_intended")
          ? sessionStorage.getItem("redirect_intended") !== ROUTE_LOGIN
            ? sessionStorage.getItem("redirect_intended")
            : null
          : null;
      } catch (e) {
        redirectBack = null;
      }

      if (location.search && location.search !== "") {
        let params = location.search.split("=");
        const redirectParam = params.shift();
        if (redirectParam === "?redirect") {
          redirectBack = params.join("=").replace("&", "?");
        }
      }

      if (!executeRecaptcha) {
        return { [FORM_ERROR]: "reCAPTCHA is unavailable. Please enable third-party cookies or try again later." };
      }

      try {
        const token = await executeRecaptcha("login"); // Generate token on form submission
        await dispatch(
          login(
            values.email,
            values.password,
            history.push,
            redirectBack,
            token,
            null
          )
        );

        if (isAuthenticated) {
          dispatch(fetchCart());
          dispatch(fetchCustomerPersonalInformation());
        }
      } catch (error) {
        if (error && error.response && error.response.data) {
          const { messages } = error.response.data.data;
          return { [FORM_ERROR]: messages[0] };
        }
        return { [FORM_ERROR]: "An unexpected error occurred. Please ensure third-party cookies are enabled." };
      }
    },
    [executeRecaptcha, dispatch, history, location, isAuthenticated]
  );

  const changeLocale = locale => {
    setActiveLanguage(locale);
    setApplicationLocale(locale);
    setState(prev => ({ ...prev, currentLocale: getApplicationLocale() }));
  };

  const urlParams = new URLSearchParams(window.location.search);

  if (isPhoneNumberRequired && !isTwoFactorEnabled) {
    return (
      <TwoFactorAuthentication
        onVerifyOTP={() => { }}
        authToken={state.authToken}
        setup2FA={isTwoFactorEnabled}
        twoFactorPhoneNumber={twoFactorPhoneNumber}
      />
    );
  }

  if (isTwoFactorEnabled) {
    return (
      <TwoFactorAuthentication
        onVerifyOTP={() => { }}
        authToken={urlParams.get("code")}
        setup2FA={isTwoFactorEnabled}
        twoFactorPhoneNumber={twoFactorPhoneNumber}
      />
    );
  }

  return (
    <div className="row">
      <BrowserView viewClassName="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3">
        <StyledLoginFormContainer className="row no-gutters">
          <StyledLogoContainer className="col-6 col-sm-6 col-md-6">
            <a href={`${ROUTE_WEBSITE}/${state.currentLocale}`}>
              <StyledLogo src={whiteLogo} alt="MKHost" isIE={isIE} />
            </a>
          </StyledLogoContainer>
          <StyledLoginFormWrapper className="col-6 col-sm-6 col-md-6">
            <LoginForm
              onSubmit={onLogin}
              validate={validateLogin}
              isMobile={isMobile}
              changeLocale={changeLocale}
              currentLocale={state.currentLocale}
            />
          </StyledLoginFormWrapper>
        </StyledLoginFormContainer>
      </BrowserView>
      <MobileView viewClassName="col-12 col-sm-12 col-md-12">
        <StyledLoginFormContainer isMobile={isMobileOnly} className="row">
          <StyledMobileLogoContainer className="col-12 col-sm-12 col-md-12">
            <a href={`${ROUTE_WEBSITE}/${state.currentLocale}`}>
              <StyledLogo
                isMobile={isMobileOnly}
                isIE={isIE}
                src={whiteLogo}
                alt="MKHost"
              />
            </a>
          </StyledMobileLogoContainer>
          <StyledLoginFormWrapper className="col-12 col-sm-12 col-md-12">
            <LoginForm
              onSubmit={onLogin}
              validate={validateLogin}
              isMobile={isMobile}
              changeLocale={changeLocale}
              currentLocale={state.currentLocale}
            />
          </StyledLoginFormWrapper>
        </StyledLoginFormContainer>
      </MobileView>
      <CookieBanner />
    </div>
  );
};

export default withLocalize(Login);