import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Form, Field } from "react-final-form";

import InputField from "../../../components/InputField";
import BlockRadiobox from "../../../components/BlockRadiobox";
import Checkbox from "../../../components/Checkbox";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

import graphicIndividualDefault from "../../../assets/images/graphic_individual.svg";
import graphicIndividualActive from "../../../assets/images/graphic_individual_active.svg";
import graphicCompanyDefault from "../../../assets/images/graphic_company.svg";
import graphicCompanyActive from "../../../assets/images/graphic_company_active.svg";

import { CONTACT_DETAILS_TYPE_COMPANY } from "../../Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";
import PhoneNumberInput from "../../../components/PhoneNumberInput/PhoneNumberInput";
import { Translate } from "react-localize-redux";

const StyledInvitationRegisterForm = styled.form`
  padding: ${props => (props.isMobile ? "20px" : "50px")};
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-left: ${props =>
    props.isMobile ? `1px solid ${theme.neutral3}` : "0"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: ${props => (props.isMobile ? `5px` : "0")}
  border-bottom-left-radius: ${props => (props.isMobile ? `5px` : "0")}

  & h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
  }

  & .login-button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
  }

  & .forgot-password-link {
    display: block;
    color: ${theme.blueBase};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
  }
`;

const StyledTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  margin-bottom: 5px;
`;
const StyledSubtitle = styled.span`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.neutral6};
`;

const StyledHeader = styled.div`
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid ${theme.neutral3};
  margin-bottom: 30px;
  margin-left: ${props => (props.isMobile ? "-20px" : "-50px")};
  margin-right: ${props => (props.isMobile ? "-20px" : "-50px")};
`;

const StyledTerms = styled.div`
  margin-top: 30px;
  margin-left: ${props => (props.isMobile ? "-20px" : "-30px")};
  margin-right: ${props => (props.isMobile ? "-20px" : "-30px")};
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${theme.neutral3};
`;

const StyledFooter = styled.div`
  padding-top: 30px;
`;

const InvitationRegisterForm = ({
  title,
  onSubmit,
  validate,
  isMobile,
  disabledFields,
  onSetReCaptchaToken
}) => (
  <Translate>
    {({ translate }) => (

      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values, submitting, invalid }) => {
          return (
            <StyledInvitationRegisterForm
              id="domain-contact-details-form"
              onSubmit={handleSubmit}
            >
              <StyledHeader className="row" isMobile={isMobile}>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledTitle>Account details</StyledTitle>
                  <StyledSubtitle>
                    Please choose your account type and fill in your details
                  </StyledSubtitle>
                </div>
              </StyledHeader>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        component={BlockRadiobox}
                        activeIcon={graphicIndividualActive}
                        defaultIcon={graphicIndividualDefault}
                        name="contact_type"
                        value="individual"
                        type="radio"
                      >
                        Individual
                      </Field>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        component={BlockRadiobox}
                        activeIcon={graphicCompanyActive}
                        defaultIcon={graphicCompanyDefault}
                        name="contact_type"
                        value="company"
                        type="radio"
                      >
                        Company
                      </Field>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label="First name"
                        name="first_name"
                        placeholder="Enter your first name"
                        readOnly={disabledFields && !!disabledFields.first_name}
                      />
                    </div>
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label="Last name"
                        name="last_name"
                        placeholder="Enter your last name"
                        readOnly={disabledFields && !!disabledFields.last_name}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        name="password"
                        placeholder="Enter your password"
                        label="Password"
                        type="password"
                        component={InputField}
                      />
                    </div>
                    <div className="col-6 col-sm-6 col-md-6">
                      {" "}
                      <PhoneNumberInput

                        name="phone"
                        label={translate("domain.domain-contacts.phone-number")}
                        defaultCountry={"MK"}
                        translate={translate}
                      />
                    </div>
                  </div>
                  {values && values.contact_type === CONTACT_DETAILS_TYPE_COMPANY && (
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        <Field
                          component={InputField}
                          label="Company"
                          name="company"
                          placeholder="Enter your company"
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {" "}
                      <Field
                        component={InputField}
                        label="Address"
                        name="address1"
                        placeholder="Enter your address"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6">
                      {" "}
                      <Field
                        component={InputField}
                        label="City"
                        name="city"
                        placeholder="Enter your city"
                      />
                    </div>
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label="Zip code"
                        name="zip"
                        placeholder="Enter your zip code"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label="State/Region"
                        name="state_region"
                        placeholder="Enter your state/region"
                      />
                    </div>
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        component={InputField}
                        label="Country"
                        name="country"
                        placeholder="Enter your country"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledTerms className="row" isMobile={isMobile}>
                    <div className="col-12 col-sm-12 col-md-12">
                      <Field
                        component={Checkbox}
                        label="I agree to the Terms and Conditions"
                        name="terms"
                      />
                    </div>
                  </StyledTerms>
                </div>
                <div className="col-12 col-sm-12 col-md-12"></div>
              </div>
              <StyledFooter className="row">
                <div className="col-12 col-sm-12 col-md-12 text-right">
                  <PrimaryButton type="submit" disabled={submitting || invalid}>
                    Create an account
                  </PrimaryButton>
                </div>
              </StyledFooter>
            </StyledInvitationRegisterForm>
          );
        }}
      />
    )}
  </Translate>

);

InvitationRegisterForm.defaultProps = {
  title: "Create an account"
};

export default InvitationRegisterForm;
