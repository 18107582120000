import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import Icon from "../../components/Icon";
import FileUploadButton from "../../components/FileUploadButton/FileUploadButton";
import InputField from "../../components/InputField";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Radiobox from "../../components/Radiobox";
import Modal from "../../components/Modal";

// Styled Components
const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledSmallText = styled.div`
  font-size: 14px;
  color: ${theme.yellowBase};
  line-height: 1.2;
  display: flex;
  font-weight: 400;
  align-items: center;
`;

const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${theme.neutralBase};
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledUnderTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.neutralBase};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const StyledBorder = styled.div`
  border: 1px solid ${theme.neutral3};
`;

const StyledFooter = styled.div`
  gap: 20px;
  padding-bottom: 30px;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
`;

const TabContent = styled.div`
  font-size: 14px;
  color: ${theme.neutral6};
`;

const MissingTekovnaModal = ({
    isOpen,
    onClose,
    onSave,
    translate,
    isSubmitting,
    domain,
    templateName,
    uploadDocumentSignedRequest,
    taxNumber
}) => {
    const [currentStatusOption, setCurrentStatusOption] = useState("uploadNow");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [registrationDate, setRegistrationDate] = useState("");
    const [fileError, setFileError] = useState("");
    const [dateError, setDateError] = useState("");

    const handleFileUpload = (file) => {
        if (!file) {
            setFileError(translate("cart.wizard.account-info-modal.validation.no-file-selected"));
            return;
        }
        setFileError("");
        setUploadedFile(file);
    };

    const validateRegistrationDate = (date) => {
        if (!date) {
            setDateError(translate("cart.wizard.account-info-modal.validation.date-required"));
            return false;
        }
        const selectedDate = new Date(date);
        const today = new Date();
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(today.getMonth() - 6);

        if (selectedDate < sixMonthsAgo) {
            setDateError(translate("cart.wizard.account-info-modal.validation.date-cannot-be-in-past"));
            return false;
        }
        if (selectedDate > today) {
            setDateError(translate("cart.wizard.account-info-modal.validation.date-cannot-be-in-future"));
            return false;
        }
        setDateError("");
        return true;
    };

    const formatDateToDDMMYYYY = (date) => {
        if (!date) return "";
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
    };

    const handleSubmit = async () => {
        console.log(taxNumber)
        if (currentStatusOption === "uploadNow") {
            if (!validateRegistrationDate(registrationDate)) return;
            if (!uploadedFile) {
                setFileError(translate("cart.wizard.account-info-modal.validation.no-file-selected"));
                return;
            }

            try {
                const uploadSuccess = await uploadDocumentSignedRequest(domain, uploadedFile, {
                    certificate_date: formatDateToDDMMYYYY(registrationDate),
                    tax_number: taxNumber, // Use the passed taxNumber
                    fromMissingTekovna: true,
                });

                if (uploadSuccess) {
                    console.log("Document uploaded successfully for domain:", domain);
                    onSave();
                } else {
                    setFileError(translate("cart.wizard.account-info-modal.validation.error-while-uploading-document"));
                }
            } catch (error) {
                console.error("Error uploading document:", error);
                setFileError(translate("cart.wizard.account-info-modal.validation.error-while-uploading-document"));
            }
        } else if (currentStatusOption === "mkHostFetch") {
            console.log("Requesting MKHost to fetch tekovna for:", {
                domain,
                templateName,
                order_registration_certificate: true,
            });
            onSave();
        }
    };

    const isSubmitDisabled = currentStatusOption === "uploadNow" && (!registrationDate || isSubmitting);
    return (
        <Modal
            isOpen={isOpen}
            onCloseModal={onClose}
            title={translate("cart.wizard.account-info-modal.missing-tekovna.title")}
            size="lg"
            body={() => (
                <TabContent>
                    <StyledRow>
                        <Icon size="large" icon="warning" color="#fe9b34" />
                        <StyledSmallText>
                            {translate("cart.wizard.account-info-modal.missing-tekovna.warning")}
                        </StyledSmallText>
                    </StyledRow>

                    <StyledTitle>
                        {translate("cart.wizard.account-info-modal.missing-tekovna.upload-tekovna")}
                    </StyledTitle>
                    <StyledUnderTitle>
                        {translate("cart.wizard.account-info-modal.missing-tekovna.description")}
                    </StyledUnderTitle>
                    <StyledBorder className="mt-4 mb-4" />

                    <StyledUnderTitle>
                        {translate("cart.wizard.account-info-modal.missing-tekovna.choose-method")}
                    </StyledUnderTitle>

                    <Radiobox
                        name="tekovnaStatusOption"
                        value="uploadNow"
                        onChange={() => setCurrentStatusOption("uploadNow")}
                        checked={currentStatusOption === "uploadNow"}
                    >
                        {translate("cart.wizard.account-info-modal.missing-tekovna.upload-now")}
                    </Radiobox>

                    {currentStatusOption === "uploadNow" && (
                        <div className="mb-3">
                            <StyledUnderTitle>
                                {translate("cart.wizard.account-info-modal.missing-tekovna.upload-label")}:
                            </StyledUnderTitle>
                            <FileUploadButton
                                acceptFileExtensions=".pdf"
                                onFileSelect={handleFileUpload}
                                label="cart.wizard.account-info-modal.missing-tekovna.upload-button"
                            />
                            {uploadedFile && (
                                <StyledUnderTitle>
                                    {translate("cart.wizard.account-info-modal.missing-tekovna.selected-file")}:{" "}
                                    {uploadedFile.name}
                                </StyledUnderTitle>
                            )}
                            {fileError && (
                                <StyledSmallText style={{ color: "red", marginTop: "5px" }}>
                                    {fileError}
                                </StyledSmallText>
                            )}
                            <div className="mt-3">
                                <InputField
                                    label={translate("cart.wizard.account-info-modal.missing-tekovna.date-label")}
                                    type="date"
                                    name="registration_certificate_date"
                                    value={registrationDate}
                                    onChange={(e) => {
                                        const selectedDate = e.target.value;
                                        setRegistrationDate(selectedDate);
                                        validateRegistrationDate(selectedDate);
                                    }}
                                    required
                                />
                                {dateError && (
                                    <StyledSmallText style={{ color: "red", marginTop: "5px" }}>
                                        {dateError}
                                    </StyledSmallText>
                                )}
                            </div>
                        </div>
                    )}

                    <Radiobox
                        name="tekovnaStatusOption"
                        value="mkHostFetch"
                        onChange={() => setCurrentStatusOption("mkHostFetch")}
                        checked={currentStatusOption === "mkHostFetch"}
                    >
                        {translate("cart.wizard.account-info-modal.missing-tekovna.let-mkhost-fetch")}
                    </Radiobox>

                    <StyledFooter className="d-flex justify-content-end w-100">
                        <SecondaryButton onClick={onClose}>
                            {translate("cart.wizard.account-info-modal.go-back")}
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={handleSubmit}
                            disabled={isSubmitDisabled}
                            submitting={isSubmitting}
                        >
                            {isSubmitting
                                ? translate("cart.wizard.account-info-modal.saving")
                                : translate("cart.wizard.account-info-modal.save-and-continue")}
                        </PrimaryButton>
                    </StyledFooter>
                </TabContent>
            )}
        />
    );
};

export default MissingTekovnaModal;
