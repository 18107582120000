import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Form, Field, FormSpy } from "react-final-form";

import {
  domainWhoIs,
  isDomainAvailable
} from "../../../services/domainsService";

import { transferInDomain } from "../../Customer/actions/customerActions";

import { calculateDomainReducedPrice } from "../../../services/pricingService";

import { addProductToCart, fetchCart } from "../../Cart/actions/cartActions";

import {
  validateDomain,
  validateRequired
} from "../../../common/validationRules";

import {
  DOMAIN_STATUS_AVAILABLE,
  DOMAIN_STATUS_UNAVAILABLE
} from "../DomainStatuses";

import {
  ROUTE_CART,
  ROUTE_BILLING_VIEW_PROINVOICE,
  compileRoute
} from "../../../routes/routes";

import getStatusTextForStatus from "../../../utils/getStatusTextForStatus";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import InputField from "../../../components/InputField";
import DiscountedAmountBadge from "../../../components/DiscountedAmountBadge";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Icon from "../../../components/Icon";
import CustomTooltip from "../../../components/CustomTooltip";

const StyledPrice = styled.div`
  display: inline-block;
  color: ${props => props.theme.neutral6};
  font-size: 14px;
  font-weight: 600;

  & .discounted-amount-badge {
    display: inline-block;
    vertical-align: ${props => (props.hasDiscountPrice ? "sub" : "initial")};
  }
`;

const StyledAddToCartButton = styled(PrimaryButton)`
  display: inline-block;
  float: right;
  top: 5px;
`;

const StyledExplanationWrapper = styled.div``;
const StyledExplanation = styled.p`
  font-size: 14px;
  line-height: 1.43;
  margin-bottom: 5px;
`;

const StyledFooter = styled.div`
  margin-top: 40px;
  margin-left: -30px;
  margin-right: -30px;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid ${theme.neutral3};
`;

const StyledAddedToCart = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutral4};
  margin-right: 30px;
  text-transform: uppercase;
`;

class TransferInDomain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: "",
      lastSearchedDomain: "",
      type: "transfer",
      status: "",
      whois: null,
      isMkHostRegistrant: false,
      statusText: "",
      searching: false,
      submittingDomainTransfer: false
    };

    this.formRef = null;
  }

  setFormRef = ref => (this.formRef = ref);

  validate = values => {
    return {
      domain: validateDomain(values.domain)
    };
  };

  validateWithEppCode = values => {
    return {
      domain: validateDomain(values.domain),
      epp_code: validateRequired(values.epp_code)
    };
  };

  onSearchDomain = values => {
    this.setState({
      searching: true
    });
    return isDomainAvailable(values.domain).then(result => {
      const { domain } = result.data.data;
      if (domain.status === DOMAIN_STATUS_AVAILABLE) {
        this.setState({
          domain,
          type: "register",
          status: domain.status,
          statusText: this.props.translate(
            "shop.domains.domain_search.available_to_purchase"
          ),
          searching: false,
          lastSearchedDomain: domain.name
        });
      } else {
        return domainWhoIs(domain.name)
          .then(result => {
            let isMkhost = false;
            let statusText = "";
            if (
              result.data.data.results.info &&
              result.data.data.results.info.registrar === "MKHOST-REG"
            ) {
              isMkhost = true;
              statusText = this.props.translate(
                "shop.domains.domain_search.domain_already_in_mkhost"
              );
            } else if (
              result.data.data.results.info &&
              result.data.data.results.info.registrar !== "MKHOST-REG"
            ) {
              statusText = this.props.translate(
                "shop.domains.domain_search.we_found_your_domain"
              );
            }

            this.setState({
              domain: domain,
              type: "transfer",
              isMkHostRegistrant: isMkhost,
              status: isMkhost
                ? DOMAIN_STATUS_UNAVAILABLE
                : DOMAIN_STATUS_AVAILABLE,
              statusText: statusText,
              whois: result.data.data.results,
              searching: false,
              lastSearchedDomain: domain.name
            });
          })
          .catch(err => {
            if (err.response.data.error) {
              this.setState({
                domain: domain,
                type: "info",
                isMkHostRegistrant: false,
                status: DOMAIN_STATUS_UNAVAILABLE,
                statusText: this.props.translate(
                  "shop.domains.domain_search.domain_unsupported_or_invalid_transfer"
                ),
                whois: null,
                searching: false,
                lastSearchedDomain: domain.name
              });
            }
          });
      }
    });
  };

  onEnter = e => {
    if (e.keyCode === 13) {
      this.setState({
        domain: "",
        type: "transfer",
        status: "",
        whois: null,
        isMkHostRegistrant: false,
        statusText: "",
        addedToCart: false
      });
    }
  };

  onFormChange = fields => {
    if (
      (fields.active === "domain" && fields.values["domain"] === "") ||
      (fields.active === "domain" &&
        this.state.lastSearchedDomain &&
        !this.state.lastSearchedDomain.startsWith(fields.values["domain"]))
    ) {
      this.setState({
        domain: "",
        type: "transfer",
        status: "",
        whois: null,
        isMkHostRegistrant: false,
        statusText: "",
        addedToCart: false,
        lastSearchedDomain: ""
      });
      if (this.formRef) {
        this.formRef.form.reset();
      }
    }
  };

  onTriggerSearch = () => {
    document
      .getElementById("transfer-in-domain-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  onDomainTransferAddToCart = (domain, type, eppcode = "") => {
    this.setState({ submittingDomainTransfer: true, addedToCart: false });
    return this.props.transferInDomain(domain.name, eppcode).then(
      result => {
        const { error, messages, invoice } = result;
        if (!error) {
          displayToastMessageForResponse(
            this.props.translate("domains.title"),
            {
              error,
              messages
            },
          );
          this.props.fetchCart();
          this.setState({ submittingDomainTransfer: false });
          this.props.onHideTransferDomain();
          if (invoice && parseFloat(invoice.total).toFixed(0) > 0) {
            const proinvoicePath = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE);
            this.props.history.push(proinvoicePath({ id: invoice.id }));
          }
        } else {
          displayToastMessageForResponse(
            this.props.translate("domains.title"),
            { error, messages }
          );
          this.props.onHideTransferDomain();
        }
      },
      err => {
        const { error, messages } = err;
        displayToastMessageForResponse(this.props.translate("domains.title"), {
          error,
          messages
        });
        this.props.onHideTransferDomain();
      }
    );
  };

  render() {
    const DomainStatusComponent = getStatusTextForStatus(this.state.status);
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <Form
            onSubmit={this.onSearchDomain}
            validate={
              this.state.domain && this.state.type === "transfer"
                ? this.validateWithEppCode
                : this.validate
            }
            ref={this.setFormRef}
            render={({ handleSubmit, submitting, invalid, values, form }) => (
              <React.Fragment>
                <form onSubmit={handleSubmit} id="transfer-in-domain-form">
                  <Field
                    component={InputField}
                    label={this.props.translate(
                      "domain.transfer-in-domain.domain-to-transfer"
                    )}
                    name="domain"
                    onKeyDown={this.onEnter}
                    normalize
                    parse={value =>
                      value.toLowerCase() &&
                      value
                        .replace(/[^0-9a-z.\u0400-\u04FF-]/gi, "")
                        .replace(/^(https?:|)\/\/|www\./, "")
                        .toLowerCase()
                    }
                    format={value =>
                      value &&
                      value
                        .replace(/[^0-9a-z.\u0400-\u04FF-]/gi, "")
                        .replace(/^(https?:|)\/\/|www\./, "")
                    }
                  />
                  {this.state.status && (
                    <DomainStatusComponent className="d-block w-100">
                      {this.state.statusText}
                    </DomainStatusComponent>
                  )}

                  {this.state.domain &&
                    this.state.type === "register" &&
                    !this.state.addedToCart &&
                    this.state.domain.pricing &&
                    this.state.domain.pricing["register"] &&
                    this.state.status === DOMAIN_STATUS_AVAILABLE && (
                      <React.Fragment>
                        <StyledPrice
                          hasDiscountPrice={this.state.type === "register"}
                        >
                          <DiscountedAmountBadge
                            amount={{
                              amount:
                                this.state.type === "register"
                                  ? calculateDomainReducedPrice(
                                    this.state.domain.pricing[this.state.type]
                                      .billing_cycle.derivations[0].price
                                      .value,
                                    {
                                      type: "register",
                                      discountType: "percentage",
                                      value: "25"
                                    }
                                  )
                                  : this.state.domain.pricing[this.state.type]
                                    .billing_cycle.derivations[0].price.value,
                              discountedAmount:
                                this.state.type === "register"
                                  ? this.state.domain.pricing[this.state.type]
                                    .billing_cycle.derivations[0].price.value
                                  : null,
                              currency: this.state.domain.pricing[
                                this.state.type
                              ].billing_cycle.derivations[0].price.currency
                                .prefix,
                              period: this.state.domain.pricing[this.state.type]
                                .billing_cycle.derivations[0].name
                            }}
                          />
                        </StyledPrice>
                        {!this.state.addedToCart && (
                          <StyledAddToCartButton
                            disabled={
                              this.state.addedToCart ||
                              this.state.submittingDomainTransfer
                            }
                            submitting={this.state.submittingDomainTransfer}
                            onClick={() =>
                              this.onDomainTransferAddToCart(
                                this.state.domain,
                                "register"
                              )
                            }
                          >
                            <Icon icon="cart" />{" "}
                            {this.props.translate("cart.add-to-cart")}
                          </StyledAddToCartButton>
                        )}
                      </React.Fragment>
                    )}

                  {this.state.addedToCart && this.state.type === "register" && (
                    <React.Fragment>
                      <StyledAddedToCart>
                        {this.props.translate("cart.added-to-cart")}
                      </StyledAddedToCart>
                      <Link to={ROUTE_CART}>
                        <SecondaryButton>
                          {this.props.translate("cart.view-cart")}
                        </SecondaryButton>
                      </Link>
                    </React.Fragment>
                  )}

                  {this.state.domain &&
                    this.state.type === "transfer" &&
                    !this.state.isMkHostRegistrant &&
                    this.state.whois !== null && (
                      <React.Fragment>
                        <Field
                          component={InputField}
                          name="epp_code"
                          customLabel={() => (
                            <React.Fragment>
                              {this.props.translate(
                                "domain.transfer-in-domain.epp-code"
                              )}
                              <CustomTooltip
                                inline={true}
                                content={this.props.translate(
                                  "cart.item-epp-code-tooltip"
                                )}
                              >
                                <Icon icon="info" />
                              </CustomTooltip>
                            </React.Fragment>
                          )}
                        />
                        {!this.state.domain.name.endsWith(".mk") &&
                          !this.state.domain.name.endsWith(".мкд") && (
                            <StyledExplanationWrapper>
                              <StyledExplanation>
                                {this.props.translate(
                                  "domain.transfer-in-domain.explanation-1"
                                )}
                              </StyledExplanation>
                              <StyledExplanation>
                                {this.props.translate(
                                  "domain.transfer-in-domain.explanation-2"
                                )}
                              </StyledExplanation>
                              <StyledExplanation>
                                {this.props.translate(
                                  "domain.transfer-in-domain.explanation-3"
                                )}
                              </StyledExplanation>
                              <StyledExplanation>
                                {this.props.translate(
                                  "domain.transfer-in-domain.explanation-4"
                                )}
                              </StyledExplanation>
                            </StyledExplanationWrapper>
                          )}
                      </React.Fragment>
                    )}
                </form>
                <StyledFooter className="row">
                  <div className="col-12 col-sm-12 col-md-12 text-right">
                    <SecondaryButton
                      onClick={() => this.props.onHideTransferDomain()}
                    >
                      {this.props.translate("domain.general-info.cancel")}
                    </SecondaryButton>
                    {this.state.domain !== "" && (
                      <PrimaryButton
                        disabled={
                          submitting ||
                          invalid ||
                          this.state.submittingDomainTransfer ||
                          this.state.type === "register" ||
                          this.state.type === "info" ||
                          this.state.domain === ""
                        }
                        submitting={
                          submitting || this.state.submittingDomainTransfer
                        }
                        onClick={() =>
                          this.onDomainTransferAddToCart(
                            this.state.domain,
                            "transfer",
                            values.epp_code
                          )
                        }
                      >
                        {this.props.translate(
                          "domain.transfer-in-domain.transfer-in-domain"
                        )}
                      </PrimaryButton>
                    )}
                    {this.state.domain === "" && (
                      <PrimaryButton
                        disabled={submitting || invalid || this.state.search}
                        submitting={submitting || this.state.searching}
                        onClick={() => this.onTriggerSearch()}
                      >
                        {this.props.translate(
                          "domain.transfer-in-domain.check-domain"
                        )}
                      </PrimaryButton>
                    )}
                  </div>
                </StyledFooter>

                <FormSpy
                  subscription={{ active: true, values: true }}
                  onChange={this.onFormChange}
                />
              </React.Fragment>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addProductToCart,
      transferInDomain,
      fetchCart
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withLocalize(TransferInDomain)));
