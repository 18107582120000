import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Translate } from "react-localize-redux";

const BreadcrumbsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
`;

const StepTitleContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) =>
    props.steps.map((_, index) => (index === props.active ? "7fr" : "1fr")).join(" ")};
  margin-bottom: 12px;
`;

const StepTitleWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.active ? "flex-start" : "center")}; 
  align-items: center;
  width: 100%;
`;

const StepTitle = styled.span`
  font-size: 16px;
  font-weight: 800;
  color: ${(props) => (props.active ? theme.blueBase : theme.neutral5)};
  text-align: left;
  visibility: ${(props) => (props.active ? "visible" : "hidden")};
`;

const StepProgressBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const ProgressSegment = styled.div`
  height: 14px;
  flex-grow: ${(props) => (props.active ? 8 : 1)};
  background-color: ${(props) => (props.active ? theme.blueBase : theme.neutral3)};
  margin-right: 6px;
  &:last-child {
    margin-right: 0;
  }
`;

const WizardBreadcrumbsCollapsable = ({ steps, active }) => {
  return (
    <Translate>
      {({ translate }) => (
        <BreadcrumbsContainer>
          <StepContainer>
            <StepTitleContainer steps={steps} active={active}>
              {steps.map((_, stepIndex) => (
                <StepTitleWrapper key={stepIndex} active={stepIndex === active}>
                  <StepTitle active={stepIndex === active}>
                    {stepIndex === active ? translate(steps[active].title) : ""}
                  </StepTitle>
                </StepTitleWrapper>
              ))}
            </StepTitleContainer>
            <StepProgressBar>
              {steps.map((_, stepIndex) => (
                <ProgressSegment key={stepIndex} active={stepIndex === active} />
              ))}
            </StepProgressBar>
          </StepContainer>
        </BreadcrumbsContainer>
      )}
    </Translate>
  );
};

export default WizardBreadcrumbsCollapsable;