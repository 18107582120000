import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import ActionButton from "../Buttons/ActionButton";
import DropdownMenu from "../DropdowMenu";

const StyledDropdownButton = styled.div`
  position: relative;
  display: inline-block;

  & > .btn.dropdown-toggle {
    padding-left: 10px;
    padding-right: 10px;
  }

  & > .btn.dropdown-toggle:after {
    border-top: ${({ hasText }) => (hasText ? "6px solid" : "0")};
    border-right: ${({ hasText }) => (hasText ? "6px solid transparent" : "0")};
    border-bottom: 0;
    border-left: ${({ hasText }) => (hasText ? "6px solid transparent" : "0")};
    vertical-align: middle;
  }

  & .icon {
    left: 2px;
  }
`;

const DropdownButton = ({ text, children, alignMenu, size, className }) => {
  const [opened, setOpened] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMenu = (e) => {
    e.stopPropagation(); // Prevents event bubbling issues
    setOpened((prevOpened) => !prevOpened);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setOpened(false);
      }
    };

    if (opened) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [opened]);

  return (
    <StyledDropdownButton
      ref={dropdownRef}
      hasText={typeof text !== "function"}
      className={`dropdown ${className}`}
    >
      <ActionButton className="btn dropdown-toggle" size={size} onClick={toggleMenu}>
        {typeof text === "function" ? text() : text}
      </ActionButton>

      <DropdownMenu opened={opened} alignMenu={alignMenu}>
        {children}
      </DropdownMenu>
    </StyledDropdownButton>
  );
};

export default DropdownButton;
