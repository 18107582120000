import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Icon from "../../../components/Icon/Icon";

import AmountBadge from "../../../components/AmountBadge";

import { withLocalize } from "react-localize-redux";

const StyledCartTotal = styled.div`
  padding: 30px;
  border-bottom: 1px solid ${theme.neutral3};
`;

const FeeContainer = styled.div`
  display:flex;
`;

const StyledLabel = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;
const StyledValue = styled(StyledLabel)`
  font-size: 16px;
`;
const StyledTotalLabel = styled(StyledLabel)`
  font-weight: 600;
`;

const StyledTotalRow = styled.div`
  margin-bottom: 7px;
`;

const TAX_VALUE = 18;

const CartTotal = ({
  products,
  customerCurrencyCode,
  totalDiscount,
  promotionCode,
  total,
  activeLanguage,
  translate
}) => {
  const subtotal = products.reduce((accumulator, product) => {
    // @todo: bring this back when addons are added in api
    const addonsSubtotal = product.item.services.reduce(
      (accumulator, service) => {
        if (service.activated) {
          return (
            accumulator +
            service.pricing.buy.billing_cycle.derivations[0].price.value
          );
        }
        return accumulator;
      },
      0
    );
    return accumulator + product.price + addonsSubtotal;
  }, 0);

  function calculateAdditionalFees(total) {
    const fixedFee = 2;                // Fixed fee of 2 EUR
    const percentageFee = 0.055;      // 5.5% expressed as a decimal

    const additionalFees = fixedFee + (total * percentageFee);
    return additionalFees;
  }

  const tax = Math.round((total / 100) * TAX_VALUE);

  const totalWithTaxAndDiscount = subtotal + tax - totalDiscount;
  const totalWithoutTax = subtotal - totalDiscount;

  let additionalFees = 0; // Declare additionalFees variable and initialize it to 0

  if (customerCurrencyCode === "eur") {
    additionalFees = calculateAdditionalFees(total); // Calculate additionalFees only for "eur" currency code
  }


  return (


    <StyledCartTotal>
      <StyledTotalRow className="row">
        <div
          className={
            isMobileOnly
              ? "col-12 col-sm-12 col-md-12"
              : "col-6 col-sm-6 col-md-6"
          }
        >
          <StyledLabel>{translate("cart.subtotal")}</StyledLabel>
        </div>
        <div
          className={
            isMobileOnly
              ? "col-12 col-sm-12 col-md-12"
              : "col-6 col-sm-6 col-md-6 text-right"
          }
        >
          <StyledValue>
            {subtotal.toFixed(customerCurrencyCode === "мкд" ? 0 : 2)}
          </StyledValue>
        </div>
      </StyledTotalRow>
      <StyledTotalRow className="row">
        <div
          className={
            isMobileOnly
              ? "col-12 col-sm-12 col-md-12"
              : "col-6 col-sm-6 col-md-6"
          }
        >
          <StyledLabel>{translate("cart.discount")}</StyledLabel>
        </div>
        <div
          className={
            isMobileOnly
              ? "col-12 col-sm-12 col-md-12"
              : "col-6 col-sm-6 col-md-6 text-right"
          }
        >
          <StyledValue>
            {(totalDiscount * -1).toFixed(
              customerCurrencyCode === "мкд" ? 0 : 2
            )}
          </StyledValue>
        </div>
      </StyledTotalRow>
      {customerCurrencyCode === "мкд" && (
        <StyledTotalRow className="row">
          <div
            className={
              isMobileOnly
                ? "col-12 col-sm-12 col-md-12"
                : "col-6 col-sm-6 col-md-6"
            }
          >
            <StyledLabel>
              {translate("cart.tax")} ({TAX_VALUE}%)
            </StyledLabel>
          </div>
          <div
            className={
              isMobileOnly
                ? "col-12 col-sm-12 col-md-12"
                : "col-6 col-sm-6 col-md-6 text-right"
            }
          >
            <StyledValue>
              {tax.toFixed(customerCurrencyCode === "мкд" ? 0 : 2)}
            </StyledValue>
          </div>
        </StyledTotalRow>
      )}
      {customerCurrencyCode === "eur" && ( // Display the fee field only for "eur" currency code
        <StyledTotalRow className="row">
          <div
            className={
              isMobileOnly
                ? "col-12 col-sm-12 col-md-12"
                : "col-6 col-sm-6 col-md-6"
            }
          >
            <FeeContainer>
              <StyledLabel>{translate("cart.2checkout-fee")}</StyledLabel> {/* Fee label */}
              <CustomTooltip
                content={translate(
                  "cart.fee"
                )}
              >
                <Icon size="m" icon="info" />
              </CustomTooltip>
            </FeeContainer>

          </div>
          <div
            className={
              isMobileOnly
                ? "col-12 col-sm-12 col-md-12"
                : "col-6 col-sm-6 col-md-6 text-right"
            }
          >
            <StyledValue>
              {calculateAdditionalFees(total).toFixed(customerCurrencyCode === "мкд" ? 0 : 2)}
            </StyledValue>
          </div>
        </StyledTotalRow>
      )}
      <StyledTotalRow className="row">
        <div
          className={
            isMobileOnly
              ? "col-12 col-sm-12 col-md-12"
              : "col-6 col-sm-6 col-md-6"
          }
        >
          <StyledTotalLabel>{translate("cart.total")}</StyledTotalLabel>
        </div>
        <div
          className={
            isMobileOnly
              ? "col-12 col-sm-12 col-md-12"
              : "col-6 col-sm-6 col-md-6 text-right"
          }
        >
          <AmountBadge
            currency={customerCurrencyCode}
            amount={
              customerCurrencyCode === "мкд"
                ? totalWithTaxAndDiscount.toFixed(0)
                : (totalWithoutTax + additionalFees).toFixed(2)
            }
          />
        </div>
      </StyledTotalRow>
    </StyledCartTotal>
  );
};

export default withLocalize(CartTotal);
