import React from "react";
import styled from "styled-components";
import { connect } from "react-redux"; // <-- Import connect from react-redux
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";

import Icon from "../../Icon";
import NotificationsNavItem from "../NotificationsNavItem";

import { ROUTE_CART } from "../../../routes/routes";
import { getItemsCount } from "../../../features/Cart/reducers/cartReducer";

const StyledMobileSubNavigation = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  top: 74px;
  background-color: ${theme.neutralBase};
  text-align: right;
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  color: ${theme.white};
  padding: 30px 0;
  position: relative; /* Ensure absolute positioning works inside */
`;

const StyledNumber = styled.div`
  position: absolute;
  right: -8px; /* Adjust position */
  top: 12px;
  background-color: ${theme.redBase};
  color: ${theme.white};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
`;

const MobileSubNavigation = ({ isAuthenticated, cartItemsCount }) => (
  <StyledMobileSubNavigation className="row">
    <div className="col-12 col-sm-12 col-md-12">
      {isAuthenticated && <NotificationsNavItem />}
      <Link to={ROUTE_CART} className="mr-2" style={{ position: "relative" }}>
        <StyledIcon icon="cart" />
        {cartItemsCount > 0 && <StyledNumber>{cartItemsCount}</StyledNumber>}
      </Link>
    </div>
  </StyledMobileSubNavigation>
);

// Connect Redux store to props
const mapStateToProps = (state) => ({
  cartItemsCount: getItemsCount(state),
});

export default connect(mapStateToProps)(MobileSubNavigation);