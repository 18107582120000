import React from 'react';
import CartProductsList from '../../CartProductsList';
import CartAddHostingToDomain from '../../CartAddHostingToDomain';
import CartAddHostingForMultipleDomains from '../../CartAddHostingForMultipleDomains';
import CartAddMoreDomains from '../../CartAddMoreDomains';

class CartDetails extends React.Component {
    render() {
        let cartDomains = this.props.items &&
            this.props.items.filter(item => item.type === "domain").map(item => {
                return item.domain;
            });

        let usedDomains = this.props.items
            .filter(item => item.type === "hosting")
            .map(item => item.domain);

        let availableDomains = cartDomains.filter((value, index, self) => {
            return usedDomains.indexOf(value) === -1;
        });

        return (
            <div className="col-12 col-sm-12 col-md-12">
                <CartProductsList
                    products={this.props.items}
                    onAttachServiceToProduct={this.props.onAttachServiceToProduct}
                    onRemoveServiceFromProduct={this.props.onRemoveServiceFromProduct}
                    onRemoveProductFromCart={this.props.onRemoveProductFromCart}
                    onUpdateProductPeriod={this.props.onUpdateProductPeriod}
                    onUpdateHostingPeriod={this.props.onUpdateHostingPeriod}
                    updatingHostingPeriod={this.props.updatingHostingPeriod}
                    updatingHostingPeriodId={this.props.updatingHostingPeriodId}
                    updatingDomain={this.props.updatingDomain}
                    onChangeDomainEPPCode={this.props.onChangeDomainEPPCode}
                    onUpdateDomainEPPCode={this.props.onUpdateDomainEPPCode}
                    updatingEPPCode={this.props.updatingEPPCode}
                    deletingProductId={this.props.deletingProductId}
                    onShowAssignHostingToDomain={this.props.onShowAssignHostingToDomain}
                    onBlockCheckout={this.props.onBlockCheckout}
                />
                {this.props.items.length === 1 &&
                    this.props.items[0].type === "domain" && (
                        <CartAddHostingToDomain
                            domain={this.props.items[0].domain}
                            onShowAssignHostingToDomain={this.props.onShowAssignHostingToDomain}
                        />
                    )}
                {this.props.items.length >= 2 &&
                    (!usedDomains || usedDomains.length === 0) &&
                    availableDomains &&
                    availableDomains.length >= 1 && (
                        <CartAddHostingForMultipleDomains
                            onShowAssignHostingForMultipleDomains={
                                this.props.onShowAssignHostingForMultipleDomains
                            }
                        />
                    )}

                {this.props.domainPrices && (
                    <CartAddMoreDomains
                        onSubmit={this.props.onSearchDomain}
                        extensions={this.props.domainPrices}
                    />
                )}
            </div>
        );
    }
}

export default CartDetails;