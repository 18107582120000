import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FORM_ERROR } from "final-form";
import qs from "query-string";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { BrowserView, MobileView, isMobileOnly } from "react-device-detect";

import styled from "styled-components";
import { theme } from "../../../design/Themes";

import { resetPassword, validateResetToken } from "../actions/authActions";
import { isAuthenticated } from "../reducers/authReducer";

import ResetPasswordForm from "./ResetPasswordForm";

import ContentLoader from "../../../components/Loaders/ContentLoader";
import withLoading from "../../../components/Loaders/WithLoading";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

import {
  validatePassword,
  validatePasswordWithConfirm
} from "../../../common/validationRules";

import { ROUTE_LOGIN, ROUTE_FORGOT_PASSWORD } from "../../../routes/routes";

import whiteLogo from "../../../assets/images/white-logo.svg";

const StyledLogoContainer = styled.div`
  background-color: ${theme.neutralBase};
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledMobileLogoContainer = styled(StyledLogoContainer)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1031;
  border-radius: 0;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const StyledResetPasswordFormContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 30px;
`;

const StyledResetPasswordFormWrapper = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledLogo = styled.img`
  position: relative;
  top: 45%;
`;

const validateResetPassword = values => {
  return {
    password: validatePassword(values.password),
    confirm_password: validatePasswordWithConfirm(
      values.password,
      values.confirm_password
    )
  };
};

const StyledResetTokenContainer = styled.div`
  padding: 50px;
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-left: ${props => !props.isMobile && "0"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledResetTokenTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  margin-bottom: 15px;
`;

const StyledResetTokenDescription = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.neutral6};
  margin-bottom: 50px;
`;

const StyledResetPasswordFormContainerWithLoading = withLoading(
  StyledResetPasswordFormContainer,
  ContentLoader
);

const ResetPassword = ({ location, history, validateResetToken, resetPassword, isAuthenticated }) => {
  const { executeRecaptcha } = useGoogleReCaptcha(); // Add the hook
  const [validToken, setValidToken] = useState(false);
  const [validatingToken, setValidatingToken] = useState(false);

  useEffect(() => {
    const params = qs.parse(location.search);
    if (!params.token || params.token.length === 0) {
      history.push(ROUTE_LOGIN);
      return;
    }

    setValidatingToken(true);
    validateResetToken(params.token)
      .then(valid => {
        setValidToken(valid);
        setValidatingToken(false);
      })
      .catch(error => {
        setValidToken(false);
        setValidatingToken(true);
      });
  }, [location, history, validateResetToken]);

  const onResetPassword = async values => {
    const params = qs.parse(location.search);
    if (!params.token || params.token.length === 0) {
      return false;
    }

    if (!executeRecaptcha) {
      return {
        [FORM_ERROR]: "reCAPTCHA is unavailable. Please enable third-party cookies or try again later."
      };
    }

    // Generate reCAPTCHA token
    let recaptchaToken;
    try {
      recaptchaToken = await executeRecaptcha("reset_password");
    } catch (error) {
      console.error("Error generating reCAPTCHA token:", error);
      return {
        [FORM_ERROR]: "reCAPTCHA verification failed. Please try again."
      };
    }

    try {
      await resetPassword(
        values.password,
        values.confirm_password,
        params.token,
        recaptchaToken
      );
      history.push(ROUTE_LOGIN);
    } catch (error) {
      const { messages } = error.response.data.data;
      return {
        [FORM_ERROR]: messages[0]
      };
    }
  };

  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <BrowserView viewClassName="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3">
            <StyledResetPasswordFormContainerWithLoading
              isLoading={validatingToken}
              className="row no-gutters"
            >
              <StyledLogoContainer className="col-6 col-sm-6 col-md-6">
                <Link to={ROUTE_LOGIN}>
                  <StyledLogo src={whiteLogo} alt="MKHost" />
                </Link>
              </StyledLogoContainer>
              <StyledResetPasswordFormWrapper className="col-6 col-sm-6 col-md-6">
                {validToken && (
                  <ResetPasswordForm
                    onSubmit={onResetPassword}
                    validate={validateResetPassword}
                  />
                )}
                {!validToken && (
                  <StyledResetTokenContainer>
                    <StyledResetTokenTitle>
                      {translate("validation.password-reset-token-expired")}
                    </StyledResetTokenTitle>
                    <StyledResetTokenDescription>
                      {translate(
                        "validation.password-reset-token-expired-description"
                      )}
                    </StyledResetTokenDescription>
                    <div className="text-center">
                      <Link
                        to={ROUTE_FORGOT_PASSWORD}
                        className="d-block w-100"
                      >
                        <PrimaryButton className="d-block w-100">
                          {translate("general.try-again")}
                        </PrimaryButton>
                      </Link>
                    </div>
                  </StyledResetTokenContainer>
                )}
              </StyledResetPasswordFormWrapper>
            </StyledResetPasswordFormContainerWithLoading>
          </BrowserView>
          <MobileView viewClassName="col-12 col-sm-12 col-md-12">
            <StyledResetPasswordFormContainerWithLoading
              isLoading={validatingToken}
              isMobile={isMobileOnly}
              className="row"
            >
              <StyledMobileLogoContainer className="col-12 col-sm-12 col-md-12">
                <Link to={ROUTE_LOGIN}>
                  <StyledLogo src={whiteLogo} alt="MKHost" />
                </Link>
              </StyledMobileLogoContainer>
              <StyledResetPasswordFormWrapper className="col-12 col-sm-12 col-md-12">
                {validToken && (
                  <ResetPasswordForm
                    onSubmit={onResetPassword}
                    validate={validateResetPassword}
                  />
                )}
                {!validToken && (
                  <StyledResetTokenContainer>
                    <StyledResetTokenTitle>
                      {translate("validation.password-reset-token-expired")}
                    </StyledResetTokenTitle>
                    <StyledResetTokenDescription>
                      {translate(
                        "validation.password-reset-token-expired-description"
                      )}
                    </StyledResetTokenDescription>
                    <div className="text-center">
                      <Link
                        to={ROUTE_FORGOT_PASSWORD}
                        className="d-block w-100"
                      >
                        <PrimaryButton className="d-block w-100">
                          {translate("general.try-again")}
                        </PrimaryButton>
                      </Link>
                    </div>
                  </StyledResetTokenContainer>
                )}
              </StyledResetPasswordFormWrapper>
            </StyledResetPasswordFormContainerWithLoading>
          </MobileView>
        </div>
      )}
    </Translate>
  );
};

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resetPassword,
      validateResetToken
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);